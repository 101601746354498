import {
  isValidEmail,
  isValidPassword,
  isValidRepeatedPassword,
  isValidPhone,
} from './validators';

const formDataErrors = (
  { password, email, repeatedPassword, phoneNumber, birthdate },
  msg,
  phoneRequest,
  birthdateRequest,
) => {
  const validEmail = isValidEmail(email);
  const validPassword = isValidPassword(password);
  const validRepeatedPassword = isValidRepeatedPassword(
    password,
    repeatedPassword,
  );
  const validPhone = phoneRequest ? isValidPhone(phoneNumber) : true;
  const validBirthdate = birthdateRequest ? birthdate : true;

  const errors = {
    phoneNumber: {
      help: !validPhone && msg('phone_help'),
      status: validPhone ? 'success' : 'error',
    },
    birthdate: {
      help: !validBirthdate && msg('birthdate_help'),
      status: validBirthdate ? 'success' : 'error',
    },
    email: {
      help: !validEmail && msg('email_help'),
      status: validEmail ? 'success' : 'error',
    },
    password: {
      help: !validPassword && msg('password_help'),
      status: validPassword ? 'success' : 'error',
    },
    repeatedPassword: {
      help: !validRepeatedPassword && msg('repeated_password_help'),
      status: validRepeatedPassword ? 'success' : 'error',
    },
    any:
      !validPassword ||
      !validEmail ||
      !validRepeatedPassword ||
      !validPhone ||
      !validBirthdate,
  };

  return errors;
};
export default formDataErrors;
