import AppLayout from 'components/layout/AppLayout';
import AppRouting from 'components/routing/AppRouting';
import IntlProvider from 'providers/Intl';
import React, { useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import UserProvider from 'providers/user/User';
import { ApiProvider } from 'services/providers/api/Api';
import { AppStateProvider as GlobalState } from 'providers/AppState';
import { loadVWOCookies } from 'helpers/seo';
import { ConfigProvider } from 'antd';
import { getPartner } from './helpers/partner';
import partnersThemes from 'config/partnersThemes';

const partner = getPartner();

function App() {
  const customTheme = {
    token: {
      colorPrimary: '#3fc69c',
      fontFamily: 'Montserrat, sans-serif',
    },
  };

  const theme = partnersThemes.hasOwnProperty(partner)
    ? partnersThemes[partner]
    : customTheme;

  useEffect(() => {
    loadVWOCookies();
  }, []);

  return (
    <ConfigProvider theme={theme}>
      <Router>
        <GlobalState>
          <IntlProvider>
            <ApiProvider>
              <UserProvider>
                <AppLayout>
                  <AppRouting />
                </AppLayout>
              </UserProvider>
            </ApiProvider>
          </IntlProvider>
        </GlobalState>
      </Router>
    </ConfigProvider>
  );
}

export default App;
