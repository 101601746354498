import { Card, Col, Row, Typography, Skeleton } from 'antd'
import { SendOutlined, ShoppingOutlined } from '@ant-design/icons'
import imgSrc from 'public/img/case_request/case_request_1'
import React from 'react'
import styled from 'styled-components'

const { Title } = Typography

export default function PhoneCaseModalContent({ msg }) {
  return (
    <Card bordered={false} style={{ maxWidth: '920px' }}>
      <Row gutter={[16, 32]}>
        <Col sm={24} md={12} align="middle">
          <Img src={imgSrc} />
        </Col>
        <Col sm={24} md={12}>
          <Title level={3} style={{ marginBottom: '1.5rem' }}>
            {msg('title')}
          </Title>
          <Row gutter={[16, 24]}>
            <Col sm={24} md={12}>
              <SendOutlined style={cssIcon} />
              {msg('shop')}
            </Col>
            <Col sm={24} md={12}>
              <ShoppingOutlined style={cssIcon} />
              {msg('action')}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}
const cssIcon = {
  fontSize: '24px',
  display: 'block',
  textAlign: 'left',
  marginBottom: '.75rem',
  color: 'var(--primary)',
}
const Img = styled('img')`
  width: 100%;
  //height: 300px;
  object-fit: cover;
  object-position: center;
`
