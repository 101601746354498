import React from 'react';
import { Row } from 'antd';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import appConfig from '../../../src/app.config';
import { getPartner } from 'helpers/partner';
import { renderGenericSectionElement } from 'components/utils/GenericRender';

const { privacy } = appConfig[getPartner()] || [];

const PrivacyPolicy = () => {
  const msg = useFormatMsg('app.privacy');

  return privacy.map((element, index) => (
    <Row key={`privacyPage${index}`} style={element.style}>
      {renderGenericSectionElement(element, msg)}
    </Row>
  ));
};

export default PrivacyPolicy;
