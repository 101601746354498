export default (user) => {
  return {
    contactEmail: user.email,
    contactPhone: user.phone || '',
    salutation: 'MR',
    firstName: user.firstName,
    lastName: user.lastName,
    streetAddress: user.address,
    postalCode: user.postalCode,
    addressLocality: user.addressLocality,
    addressCountry: user.addressCountry,
    repairPreferences: 'Send In',
  };
};
