import { Col, Typography, Row, Divider } from 'antd';
import { listOfInsuredItemDetails } from 'data/claimDetails';
import { processInsuredItem } from 'helpers/statuses/claim/claimContext';
import { useCallback } from 'react';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';

const { Text, Title } = Typography;
export default function InsuredItemDetails({ insuredItem }) {
  const msg = useFormatMsg('claims.claim_view.insureditem_details');
  const insuredItemDetails = useCallback(processInsuredItem(insuredItem), [
    insuredItem,
  ]);
  function Details({ field }) {
    if (!insuredItemDetails[field]) {
      return null;
    }
    return (
      <Row>
        <Text strong style={{ marginRight: '4px' }}>
          {msg(`question.${field}`)}
        </Text>
        <Text>{insuredItemDetails[field]}</Text>
      </Row>
    );
  }
  return (
    <Row>
      <Col>
        <Title level={5}>{msg('title')}</Title>
        <Divider style={{ margin: '8px 0px 12px 0px' }} />
        {listOfInsuredItemDetails.map(d => (
          <Details field={d} key={d} />
        ))}
      </Col>
    </Row>
  );
}
