import { useEffect, useState } from 'react';

const useInitialCall = (query, map, callback) => {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      query()
        .then((resp) => {
          const mappedData = map?.(resp) || resp;
          setData(mappedData);
          callback?.(mappedData);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    setIsLoading(true);
    fetchData();
  }, []);

  return {
    data,
    setData,
    error,
    isLoading,
    setIsLoading,
  };
};
export default useInitialCall;
