export class ApiService {
  constructor(url, Request) {
    this.auth = new Request(url);
    this.query = new Request(url);
  }

  //**NOTE: Auth - public api **//

  getUserToken = credentials => this.auth.postData('user-token', credentials);

  customerRegistration = payload => this.auth.postData('create-user', payload);

  resetPasswordRequest = payload =>
    this.auth.postData('request-change-user-password', {
      ...payload,
    });

  resetPassword = payload =>
    this.auth.putData('change-user-password ', payload);

  resendVerificationMail = payload =>
    this.auth.postData(`request-verification-email`, { ...payload });

  //**NOTE: Query - private api - Authorization Bearer Token **//

  putEditCustomer = id => payload =>
    this.query.putData(`customers/${id}`, payload);

  getCustomerOverview = customerId => () =>
    this.query.getData(`customers/${customerId}?include[]=Address`);

  //**NOTE - POLICIES */

  getPoliciesByCustomerId = id => () =>
    this.query.getData(
      `policies?customer.id=${id}&include[]=InsuredItem&include[]=item&include[]=Product`,
    );

  getPoliciesByPolicyId = id => () => this.query.getData(`policies/${id}`);

  uploadPolicyFile = (policyId, type) => payload =>
    this.query.postData(`policies/${policyId}/${type}`, payload);

  getInsuredItems = policyId => () =>
    this.query.getData(
      `policies/${policyId}?include[]=InsuredItem&include[]=item`,
    );

  putInsuredItem = (insuredItemId, policyId) => payload =>
    this.query.putData(
      `policies/${policyId}/insured_items/${insuredItemId}`,
      payload,
    );

  createInsuredItem = (policyId, data) =>
    this.query.postData(`policies/${policyId}/insured_items`, data);

  downloadPolicyPdf = policyId => () =>
    this.query.getBlobData(`policies/pdf-download/${policyId}`);

  checkForPolicyDocuments = policyId => () =>
    this.query.getData(`policies-documents/${policyId}`);
  //**NOTE - ADDONS */

  postAddOn = policyId => payload =>
    this.query.postData(`v2/add-on/${policyId}`, payload);

  putAddOnBankAccount = addOnId => payload =>
    this.query.putData(`v2/add-on/${addOnId}/add-bank-details`, payload);

  checkForAddOns = policyId => () =>
    this.query.getData(`v2/add-on?policyId=${policyId}`);

  checkForAvailableAddOns = policyId => () =>
    this.query.getData(`v2/add-on/${policyId}/availability`);

  checkForAddOnsByCustomerId = customerId => () =>
    this.query.getData(`v2/add-on?customerId=${customerId}`);

  policyPassportPdf = policyId => payload =>
    this.query.postBlobData(`v2/battery-replacement-pdf/${policyId}`, payload);

  //**NOTE - CLAIMS */

  uploadClaimFile = (claimId, type) => payload =>
    this.query.postData(`claims/${claimId}/${type}`, payload);

  getClaimsByCustomerId = id => () =>
    this.query.getData(
      `claims?customer.id=${id}&include[]=InsuredItem&include[]=item&include[]=ClaimFile&include[]=ClaimContext&include[]=Damage&pagination=false`,
    );

  getClaimsByCustomerIdWOIncludes = id => () =>
    this.query.getData(`claims?customer.id=${id}&pagination=false`);

  declareClaim = payload => this.query.postData('claims', payload);

  getProductCoverage = productId => params =>
    this.query.getData(`v2/product/${productId}/coverages`, params);
  getProductExclusionPeriod = () => params =>
    this.query.getData(`v2/claim-coverage-exclusion-periods`, params);

  getCustomerCare = customerName => () =>
    this.query.getData(`configuration/partner/${customerName}`);

  //**NOTE - WORKORDERS */

  getClaimWorkorder = claimId => () =>
    this.query.getData(
      `workorders?claim.id=${claimId}&include[]=Claim&include[]=WorkOrderInsuredItem&include[]=WorkOrderReplaceItem&include[]=RepairCenter`,
    );

  getClaimVouchers = claimId => () =>
    this.query.getData(`v2/voucher-order?claimId=${claimId}&pagination=false`);

  getPdfWorkOrder = workOrderId =>
    this.query.getBlobData(`workorders/pdfFile/${workOrderId}`);

  getPostLabel = workOrderId =>
    this.query.getBlobData(`/workorders/label/${workOrderId}`);

  getPaymentsByClaim = claimId =>
    this.query.getData(`/payments/claim/${claimId}/payment-link`);

  //**NOTE - TRACKING */

  getWOTrackingInfo = workOrderId =>
    this.query.getData(`/work_orders/tracking/${workOrderId}`);
}
