import { Modal } from 'antd'
import React from 'react'

export default (error, commonMsg, msg, onOk, supportEmail) => {
  const modal = Modal[error ? 'error' : 'success']
  const email = <a style={{ textDecoration: 'underline' }}>{supportEmail}</a>

  const modalMsg = {
    title: error
      ? commonMsg(`onError.${error.status}.title`)
      : commonMsg('onSuccess.title'),
    description: error
      ? commonMsg(`onError.${error.status}.description`, {
          supportEmail: email,
        })
      : commonMsg('onSuccess.description'),
    okText: error
      ? msg(`onError.${error.status}.btn.label`)
      : msg(`onSuccess.btn.label`),
  }

  return modal({
    title: modalMsg.title,
    content: modalMsg.description,
    okText: modalMsg.okText,
    onOk,
  })
}
