import { returnDayUTC } from 'helpers/dates/dates';

const damageDetails = {
  DAMAGE_DATE_KNOW: true,
  incidentCategory: '',
  incidentType: [],
  ALL_DAMAGES: true,
  WATER_EXPOSED: false,
  HAVE_DEVICE: true,
  ALREADY_REPAIRED: false,
  DEVICE_REPAIRED_AT: '',
  USABLE: true,
  VISIBLE: false,
  BACKSIDE: false,
  CASHLESS_REPAIR: false,
  BATTERY_LEAKAGE: false,
  INCIDENT_TIME: '',
  OTHER_CLAIM: false,
};
const theftDetails = {
  whereHappen: '',
  incidentLocation: '',
  THEFT_TIME: '',
  DEVICE_LOCATION: '',
  THEFT_RECOGNISED: true,
  POLICE_REPORT_FILED: true,
  POLICE_REPORT_DATE: '',
  POLICE_REPORT_STATION: '',
  POLICE_REPORT_REFERENCE: '',
  POLICE_REPORT_IMEI: true,
  ANOTHER_EXISTING_INSURANCE: false,
  THEFT_ANOTHER_INSURANCE_NAME: '',
  THEFT_ANOTHER_INSURANCE_CLAIMED: false,
  LOSS_TYPE: '', // THEFT_TYPE ???
  SIM_CARD_BLOCKED: false,
};
const lossDetails = {
  whereHappen: '',
  incidentLocation: '',
  THEFT_TIME: '', //LOSS_TIME ??
  ANOTHER_EXISTING_INSURANCE: false,
  THEFT_ANOTHER_INSURANCE_NAME: '',
  THEFT_ANOTHER_INSURANCE_CLAIMED: false,
};

const warrantyExtensionDetails = {
  DAMAGE_DATE_KNOW: true,
  incidentLocation: '',
  ALL_DAMAGES: true,
  SWITCH_ON: true,
  USED: true,
  WATER_EXPOSED: false,
  TOUCHSCREEN_REACT: true,
  OTHER_CLAIM: false,
};
const commonFields = {
  incidentDate: returnDayUTC(),
  responsibility: 'INSURED',
  incidentDescription: '',
  THIRD_PARTY: '',
  OWNERRELATIONSHIP: '',
  THIRD_CLAIM_RAISED: false,
  REQUEST_REIMBURSEMENT: '',
};

const getDetails = () => {
  return {
    ...damageDetails,
    ...theftDetails,
    ...lossDetails,
    ...warrantyExtensionDetails,
    ...commonFields,
  };
};

export default getDetails;
