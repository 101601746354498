import {
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  Typography,
} from 'antd';
import FormHelp from 'components/shared/FormHelp';
import React, { Fragment, useState } from 'react';
import { useClaimContext } from 'providers/ClaimDeclaration';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import Uploader from '../../../uploadDocuments/Uploader';
import { useAppState } from 'services/providers/AppState';
import MoreTheftDetails from './partners/MoreTheftDetails';
import { dateIsAfterUTC, dateToDayJs } from 'helpers/dates/dates';

const { Title } = Typography;

function TheftDetails({ isPolicyHolder, setIsPolicyHolder }) {
  const { messages } = useAppState();
  const [isPolicyReported, setIsPolicyReported] = useState(true);
  const [isTheftCoveredYet, setIsTheftCoveredYet] = useState(false);
  const detailsMsg = useFormatMsg('claims.declaration.details');
  const theftTypes =
    messages.claims.declaration.details.THEFT.theft_type.options;
  const msg = useFormatMsg('generics');
  const { deviceCategory, setClaimFiles, claimFiles } = useClaimContext();

  const onChange =
    key =>
    ({ fileList }) => {
      setClaimFiles({ ...claimFiles, [key]: fileList });
    };

  const required = [{ required: true, message: detailsMsg('required') }];

  return (
    <Fragment>
      <Title level={5}>{detailsMsg('claim_details')}</Title>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="incidentDate"
            label={detailsMsg('THEFT.incident_date.label')}
            rules={required}
            labelCol={{ span: 24 }}
          >
            <DatePicker
              placeholder={detailsMsg('THEFT.incident_date.placeholder')}
              format="DD/MM/YYYY"
              disabledDate={date => dateIsAfterUTC(date)}
            />
          </Form.Item>
          <Form.Item
            name="DEVICE_LOCATION"
            label={detailsMsg('THEFT.device_location.label')}
            rules={required}
            labelCol={{ span: 24 }}
          >
            <Input
              placeholder={detailsMsg('THEFT.device_location.placeholder')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="THEFT_TIME"
            label={detailsMsg('THEFT.theft_time.label')}
            rules={required}
            labelCol={{ span: 24 }}
          >
            <TimePicker
              format="HH:mm"
              placeholder={detailsMsg('THEFT.theft_time.placeholder')}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="incidentLocation"
            label={detailsMsg('THEFT.incident_location.label')}
            rules={required}
            labelCol={{ span: 24 }}
          >
            <Input
              placeholder={detailsMsg('THEFT.incident_location.placeholder')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Form.Item
            name="incidentDescription"
            label={detailsMsg('THEFT.incident_description.label')}
            help={
              <FormHelp>
                ({detailsMsg('THEFT.incident_description.help')})
              </FormHelp>
            }
            rules={required}
            labelCol={{ span: 24 }}
          >
            <Input.TextArea
              placeholder={detailsMsg('THEFT.incident_description.placeholder')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row
        justify="space-between"
        style={{ marginTop: '1rem' }}
        gutter={[16, 16]}
      >
        <Col xs={24} md={12}>
          <Form.Item
            name="THEFT_RECOGNISED"
            label={detailsMsg('THEFT.theft_recognized.label')}
            rules={required}
            labelCol={{ span: 24 }}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="incidentCategory"
            label={detailsMsg('THEFT.theft_type.label')}
            rules={required}
            labelCol={{ span: 24 }}
          >
            <Select
              className="flex-col"
              placeholder={detailsMsg('THEFT.theft_type.label')}
            >
              {React.Children.toArray(
                theftTypes.map(type => (
                  <Select.Option value={type.value}>{type.label}</Select.Option>
                )),
              )}
            </Select>
          </Form.Item>
          <Row align="middle">
            <Form.Item
              name="POLICE_REPORT_FILED"
              label={detailsMsg('THEFT.police_report_filed.label')}
              rules={required}
              labelCol={{ span: 24 }}
              hidden
            >
              <Radio.Group
                onChange={({ target }) => {
                  setIsPolicyReported(target.value);
                }}
                className="flex-col"
              >
                <Radio value={true}>{msg('yes')}</Radio>
                <Radio value={false}>{msg('no')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
        </Col>
      </Row>

      {isPolicyReported && (
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Row>
              <Col sm={12}>
                <Form.Item
                  name="POLICE_REPORT_DATE"
                  label={detailsMsg('THEFT.police_report_date.label')}
                  labelCol={{ span: 24 }}
                  rules={required}
                >
                  <DatePicker
                    placeholder={detailsMsg(
                      'THEFT.police_report_date.placeholder',
                    )}
                    disabledDate={date => date > dateToDayJs()}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="POLICE_REPORT_STATION"
              label={detailsMsg('THEFT.police_report_station.label')}
              labelCol={{ span: 24 }}
              rules={[{ required: false }]}
            >
              <Input
                placeholder={detailsMsg(
                  'THEFT.police_report_station.placeholder',
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="POLICE_REPORT_REFERENCE"
              label={detailsMsg('THEFT.police_report_reference.label')}
              labelCol={{ span: 24 }}
              rules={required}
            >
              <Input
                placeholder={detailsMsg(
                  'THEFT.police_report_reference.placeholder',
                )}
              />
            </Form.Item>
            {deviceCategory === 'MOBILE' && (
              <Form.Item
                name="POLICE_REPORT_IMEI"
                label={detailsMsg('THEFT.police_report_imei.label')}
                labelCol={{ span: 24 }}
                rules={required}
              >
                <Radio.Group className="flex-col">
                  <Radio value={true}>{msg('yes')}</Radio>
                  <Radio value={false}>{msg('no')}</Radio>
                </Radio.Group>
              </Form.Item>
            )}
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="police-report"
              getValueFromEvent={e => {
                if (Array.isArray(e)) {
                  return e;
                }
                return e && e.fileList;
              }}
              rules={required}
              valuePropName="fileList"
            >
              <Uploader
                name="police-report"
                maxFiles={1}
                beforeUpload={() => false}
                listType="text"
                msg={detailsMsg('THEFT.upload_police_report')}
                onChange={onChange('police-report')}
                accept="application/pdf,image/png,image/jpg,image/jpeg"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="ANOTHER_EXISTING_INSURANCE"
            label={detailsMsg('THEFT.another_existing_insurance.label')}
            labelCol={{ span: 24 }}
            rules={required}
          >
            <Radio.Group
              onChange={({ target }) => {
                setIsTheftCoveredYet(target.value);
              }}
              className="flex-col"
            >
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="responsible"
            label={detailsMsg('responsibility.label')}
            rules={required}
            labelCol={{ span: 24 }}
            onChange={({ target }) => {
              setIsPolicyHolder(target.value === 'INSURED');
            }}
          >
            <Radio.Group className="flex-col">
              <Radio value="INSURED">
                {detailsMsg('responsibility.policy_holder')}
              </Radio>
              <Radio value="FAMILY">
                {detailsMsg('responsibility.family_member')}
              </Radio>
              <Radio value="OTHER">{detailsMsg('responsibility.other')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          {isTheftCoveredYet && (
            <Form.Item
              name="THEFT_ANOTHER_INSURANCE_NAME"
              label={detailsMsg('THEFT.another_insurance_name.label')}
              labelCol={{ span: 24 }}
              rules={required}
            >
              <Input
                placeholder={detailsMsg(
                  'THEFT.another_insurance_name.placeholder',
                )}
              />
            </Form.Item>
          )}
          {isTheftCoveredYet && (
            <Form.Item
              name="THEFT_ANOTHER_INSURANCE_CLAIMED"
              label={detailsMsg('THEFT.another_insurance_claimed.label')}
              labelCol={{ span: 24 }}
              rules={required}
            >
              <Radio.Group className="flex-col">
                <Radio value={true}>{msg('yes')}</Radio>
                <Radio value={false}>{msg('no')}</Radio>
              </Radio.Group>
            </Form.Item>
          )}
        </Col>
        {!isPolicyHolder && (
          <Row justify="space-between" gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="THIRD_PARTY"
                label={detailsMsg('third_party.label')}
                rules={required}
                labelCol={{ span: 24 }}
              >
                <Input.TextArea
                  placeholder={detailsMsg('third_party.placeholder')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="THIRD_CLAIM_RAISED"
                label={detailsMsg('third_claim_raised.label')}
                rules={required}
                labelCol={{ span: 24 }}
              >
                <Radio.Group className="flex-col">
                  <Radio value={true}>{msg('yes')}</Radio>
                  <Radio value={false}>{msg('no')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Row>
      <MoreTheftDetails />
    </Fragment>
  );
}
export default TheftDetails;
