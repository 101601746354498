import { Typography, Col, Button, Row, Space, Divider } from 'antd';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import WorkOrderInfo from './WorkOrderInfo';
import { DownloadOutlined } from '@ant-design/icons';
import { useApi } from 'services/providers/api/Api';
import downloadFile from 'helpers/downloadFile';
import PaymentLink from './PaymentLink';
import { paymentsStatusToPayExcessFee } from 'data/statuses/payments';
import { woStatusesToHideDownloadButtons } from 'data/statuses/workorders';

const { Title } = Typography;

export default function WorkOrderDetails({ workOrders, claimId }) {
  if (!workOrders.length) return null;
  const msg = useFormatMsg('claims.claim_view.workorder_details');
  const { getPdfWorkOrder, getPostLabel } = useApi();

  const getPdf = woId => {
    getPdfWorkOrder(woId).then(res => downloadFile(res.data, woId));
  };

  const getPL = woId => {
    getPostLabel(woId).then(res => downloadFile(res.data, `PostLabel_${woId}`));
  };

  return (
    <>
      <Title level={5}>{msg('title')}</Title>
      {workOrders.map((wo, i) => (
        <Space key={wo.id} direction="vertical" size="middle">
          <WorkOrderInfo workOrder={wo} />
          {!woStatusesToHideDownloadButtons.includes(wo.status) && (
            <Row>
              <Space>
                <Button
                  icon={<DownloadOutlined />}
                  onClick={() => getPdf(wo.id)}
                >
                  {msg('download_workorder')}
                </Button>
                <Button
                  icon={<DownloadOutlined />}
                  onClick={() => getPL(wo.id)}
                >
                  {msg('download_post_label')}
                </Button>
              </Space>
            </Row>
          )}

          {paymentsStatusToPayExcessFee.includes(wo.status) && (
            <>
              <PaymentLink claimId={claimId} />
            </>
          )}
          {i !== workOrders.length - 1 && <Divider />}
        </Space>
      ))}
    </>
  );
}
