import appConfig from 'app.config';

export const getGAKey = partner => {
  if (
    location.hostname.startsWith('my-customer-space-preproduction') ||
    location.hostname.includes('localhost')
  ) {
    return {
      ga: 'G-X2EXLNLS48',
      gtm: 'GTM-PLH6T49F',
    };
  } else if (location.hostname.startsWith('my-customer-space-staging')) {
    return {
      ga: 'G-GY4YCE7PHP',
      gtm: 'GTM-KZXDLK9Z',
    };
  } else {
    const TRACKING_ID_GA = appConfig[partner].ga;
    const TRACKING_ID_GTM = appConfig[partner].gtm;
    return {
      ga: TRACKING_ID_GA,
      gtm: TRACKING_ID_GTM,
    };
  }
};
