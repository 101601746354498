import { useState, useEffect } from 'react';
import { useApi } from 'services/providers/api/Api';

export default function usePolicyCoverages(policy) {
  const [coverages, setCoverages] = useState([]);
  const [exclusionPeriod, setExclusionPeriod] = useState();

  const { getProductCoverage, getProductExclusionPeriod } = useApi();
  const queryCoverages = getProductCoverage(policy.productId);
  const queryExclusionPeriod = getProductExclusionPeriod();

  const fetchPolicyCoverages = async () => {
    const { data } = await queryCoverages({ name: 'coverage' });
    setCoverages(data.map(c => c.value));
  };

  const fetchExclusionPeriod = async () => {
    const { data } = await queryExclusionPeriod({
      productId: policy.productId,
    });
    setExclusionPeriod(data?.[0]);
  };

  useEffect(() => {
    policy.id && fetchPolicyCoverages();
    policy.productId && fetchExclusionPeriod();
  }, [policy]);

  return {
    coverages,
    exclusionPeriod,
  };
}
