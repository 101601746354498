import { Col, Form, Input, Row, Select, Tooltip } from 'antd'
import useFormatMsg from 'hooks/translations/useFormatMsg'
import { nanoid } from 'nanoid'
import { useClaimContext } from 'providers/ClaimDeclaration'
import React, { Fragment } from 'react'

export default function ElectronicDevice({
  onChangeDevice,
  insuredItems,
  selectedDevice,
  onModifyDevice,
  children,
}) {
  const msg = useFormatMsg('claims.declaration.device')
  const { claimType } = useClaimContext()
  return (
    <Fragment>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="device"
            label={msg(`${claimType}.device.label`)}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Select placeholder="Select" onChange={onChangeDevice}>
              {insuredItems.map((device, i) => (
                <Select.Option key={nanoid()} value={i}>
                  {device.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Tooltip title={<small>{msg(`serialNumber.tooltip`)}</small>}>
            <Form.Item
              name="serialNumber"
              label={msg('serialnumber')}
              rules={[{ required: true, message: msg('required') }]}
              labelCol={{ span: 24 }}
            >
              <Input
                name="serialNumber"
                onChange={onModifyDevice}
                value={selectedDevice.serialNumber}
              />
            </Form.Item>
          </Tooltip>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="color"
            label={msg('colour')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input value={selectedDevice.color} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="model"
            label={msg('model')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input name="model" onChange={onModifyDevice} />
          </Form.Item>
        </Col>
        {children}
      </Row>
    </Fragment>
  )
}
