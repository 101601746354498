import { Button, Col, Row, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import { useClaimContext } from 'services/providers/ClaimDeclaration';

const { Title, Paragraph } = Typography;

export default function Confirmation() {
  const { claimId } = useClaimContext();
  const msg = useFormatMsg('claims.declaration.confirmation');
  const history = useHistory();

  return (
    <Row justify="center" gutter={[16, 16]}>
      <Col xs={24} md={12}>
        <Title level={5}>
          {msg('title')} - {claimId}
        </Title>
        <Paragraph>{msg('paragraph')}</Paragraph>
        <Button
          type="primary"
          data-cy="confirmation_success_button"
          onClick={() => history.push('/dashboard')}
        >
          {msg('link_to_portal')}
        </Button>
      </Col>
    </Row>
  );
}
