import { useState } from 'react';

const useModal = onOk => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    onOk?.();
  };

  return {
    showModal,
    handleOk,
    isModalVisible,
  };
};

export default useModal;
