import Notify from 'helpers/messages/Notify';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import { useAppState } from 'services/providers/AppState';
import { useUser } from 'services/providers/user/User';
import apiFactory from './apiFactory';
import { reqTokenCheck, respCustomError } from './interceptors';

export const ApiServiceContext = createContext();

export const useApiQuery = () => {
  const { api } = useContext(ApiServiceContext);
  return api.query;
};
export const useApiAuth = () => {
  const { api } = useContext(ApiServiceContext);
  return api.auth;
};
export const useApi = () => {
  const { api } = useContext(ApiServiceContext);
  return api;
};
export const useApiService = () => useContext(ApiServiceContext);

const api = apiFactory();

export const useApiInterceptors = () => {
  const { api, source, resetSource } = useApiService();
  // const { rollbar } = useAppState()
  const { user, logout } = useUser();
  const { jwtStore } = useAppState();
  const history = useHistory();
  const msg = useFormatMsg('auth.login.invalid_token');

  const onInvalidToken = () => {
    source.cancel();
    logout(() => history.push('/'));
    Notify.warning({ msg });
    resetSource();
    // rollbar.info('Error 401 - Invalid Token')
  };
  useEffect(() => {
    api.query.requestInterceptor(reqTokenCheck(jwtStore, source));
    api.query.responseErrorInterceptor(respCustomError(onInvalidToken, user));
  }, [user]);
};

export const ApiProvider = ({ children }) => {
  const [source, setSource] = useState(api.query.createCancelToken());

  const resetSource = useCallback(() => {
    setSource(api.query.createCancelToken());
  }, []);

  useEffect(() => {
    api.query.setConfig({ cancelToken: source.token });
  }, [source]);

  return (
    <ApiServiceContext.Provider value={{ api, source, resetSource }}>
      {children}
    </ApiServiceContext.Provider>
  );
};
