import { Button, Col, Row, Space, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useModalContext } from './ModalContext';
import Uploader from './Uploader';
import useHandleSubmit from './useHandleSubmit';

const { Title, Paragraph } = Typography;

export default function UploadModalContent() {
  const { msg, modal } = useModalContext();
  const { onSubmit, setFiles, hasFilesToUpload, isLoading } = useHandleSubmit({
    modal,
  });

  const beforeUpload = () => {
    return false;
  };
  const onChange =
    key =>
    ({ fileList }) => {
      setFiles(state => ({
        ...state,
        [key]: fileList.map(file => file.originFileObj),
      }));
    };

  return (
    <Card>
      <Title level={4}>{msg('title')}</Title>
      <Paragraph style={{ marginBottom: '2rem' }} type="secondary">
        {msg('help_msg_1')}
        <br />
        {msg('help_msg_2')}
      </Paragraph>
      <Row gutter={[28, 28]}>
        <Col xs={24} md={4}>
          <Paragraph>{msg('damage.label')}</Paragraph>
          <Uploader
            id="damage"
            multiple
            name="damage"
            beforeUpload={beforeUpload}
            onChange={onChange('damages')}
            msg={msg('upload_btn')}
            accept="application/pdf,image/png,image/jpg,image/jpeg"
          />
        </Col>
        <Col xs={24} md={4}>
          <Paragraph>{msg('policy_report.label')}</Paragraph>
          <Uploader
            id="policy_report"
            multiple
            name="policy_report"
            beforeUpload={beforeUpload}
            onChange={onChange('report')}
            msg={msg('upload_btn')}
            accept="application/pdf,image/png,image/jpg,image/jpeg"
          />
        </Col>
        <Col xs={24} md={4}>
          <Paragraph>{msg('purchase_proof.label')}</Paragraph>
          <Uploader
            id="purchase_proof"
            multiple
            name="purchase_proof"
            beforeUpload={beforeUpload}
            onChange={onChange('proof')}
            msg={msg('upload_btn')}
            accept="application/pdf,image/png,image/jpg,image/jpeg"
          />
        </Col>
        <Col xs={24} md={4}>
          <Paragraph>{msg('proof-of-family.label')}</Paragraph>
          <Uploader
            id="proof-of-family"
            multiple
            name="proof-of-family"
            beforeUpload={beforeUpload}
            onChange={onChange('family')}
            msg={msg('upload_btn')}
            accept="application/pdf,image/png,image/jpg,image/jpeg"
          />
        </Col>
        <Col xs={24} md={4}>
          <Paragraph>{msg('repair-invoice.label')}</Paragraph>
          <Uploader
            id="repair-invoice"
            multiple
            name="repair-invoice"
            beforeUpload={beforeUpload}
            onChange={onChange('repair')}
            msg={msg('upload_btn')}
            accept="application/pdf,image/png,image/jpg,image/jpeg"
          />
        </Col>
      </Row>
      <Row justify="start">
        <Button
          loading={isLoading}
          data-cy="btn_upload_documents"
          style={{ marginLeft: '1rem' }}
          type="primary"
          disabled={!hasFilesToUpload}
          onClick={onSubmit}
        >
          {msg('submit_btn.label')}
        </Button>
      </Row>
    </Card>
  );
}
const Card = styled('div')``;
