import { useIntl } from 'react-intl';

const useIntlTranslations = (path, toArray = false) => {
  const { messages } = useIntl();
  let msgs = {};
  Object.keys(messages).forEach((msgKey) => {
    if (msgKey.includes(path)) {
      const key = msgKey.replace(path, '').slice(1);
      msgs = { ...msgs, [key]: messages[msgKey] };
    }
  });
  return toArray ? Object.values(msgs) : msgs;
};

export default useIntlTranslations;
