export function reloadOTBanner() {
  var otConsentSdk = document.getElementById('onetrust-consent-sdk');
  if (otConsentSdk) {
    otConsentSdk.remove();
  }
  if (window.OneTrust != null) {
    OneTrust.Init();
    setTimeout(function () {
      OneTrust.LoadBanner();
      var toggleDisplay = document.getElementsByClassName(
        'ot-sdk-show-settings',
      );
      for (var i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = function (event) {
          event.stopImmediatePropagation();
          window.OneTrust.ToggleInfoDisplay();
        };
      }
    }, 1000);
  }
}

export const loadOTBanner = partner => {
  if (partner?.otScript?.src) {
    const OTscript = document.createElement('script');
    const blockScript = document.createElement('script');
    blockScript.setAttribute('src', partner?.otScript?.autoBlockSrc);
    OTscript.setAttribute('src', partner?.otScript?.src);
    OTscript.setAttribute(
      'data-domain-script',
      partner?.otScript?.domainScript,
    );
    OTscript.setAttribute('charset', 'UTF-8');
    OTscript.setAttribute('type', 'text/javascript');
    document.head.appendChild(blockScript);
    document.head.appendChild(OTscript);
  }
};

export const loadVWOCookies = () => {
  const script = document.createElement('script');
  script.setAttribute('id', 'vwoCode');
  script.setAttribute('class', 'optanon-category-C0002-C0003-C0004');
  script.setAttribute('defer', true);
  script.setAttribute('type', 'text/javascript');
  script.innerHTML = `
  window._vwo_code=window._vwo_code || (function() {
    var account_id=606899,
    version=1.4,
    settings_tolerance=2000,
    library_tolerance=2500,
    use_existing_jquery=false,
    is_spa=1,
    hide_element='body',
    /* DO NOT EDIT BELOW THIS LINE */
    f=false,d=document,vwoCodeEl=document.querySelector('#vwoCode'),code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.innerText;t.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},getVersion:function(){return version},getMatchedCookies:function(e){var t=[];if(document.cookie){t=document.cookie.match(e)||[]}return t},getCombinationCookie:function(){var e=code.getMatchedCookies(/(?:^|;)\s?(_vis_opt_exp_\d+_combi=[^;$]*)/gi);e=e.map(function(e){try{var t=decodeURIComponent(e);if(!/_vis_opt_exp_\d+_combi=(?:\d+,?)+\s*$/.test(t)){return''}return t}catch(e){return''}});var i=[];e.forEach(function(e){var t=e.match(/([\d,]+)/g);t&&i.push(t.join('-'))});return i.join('|')},init:function(){window.settings_timer=setTimeout(function(){_vwo_code.finish()},settings_tolerance);var e=d.createElement('style'),t=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',i=d.getElementsByTagName('head')[0];e.setAttribute('id','_vis_opt_path_hides');vwoCodeEl&&e.setAttribute('nonce',vwoCodeEl.nonce);e.setAttribute('type','text/css');if(e.styleSheet)e.styleSheet.cssText=t;else e.appendChild(d.createTextNode(t));i.appendChild(e);var n=this.getCombinationCookie();this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version+(n?'&c='+n:''));return settings_timer}};window._vwo_settings_timer = code.init();return code;}());
  `;
  document.body.appendChild(script);
};
