import axios from 'axios'

export const respCustomError = (callback, user) => (error) => {
  if (axios.isCancel(error)) {
    return error
  }
  if (error.status === 401) {
    user.isLogged && callback()
  }
  throw error
}

export const reqTokenCheck = (jwtStore, source) => (req) => {
  const token = jwtStore.getToken()
  req.headers.Authorization = `Bearer ${token}`
  return req
}
