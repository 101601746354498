import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import { useAppState } from 'services/providers/AppState';
import { Col, DatePicker, Form, Radio, Row } from 'antd';
import { useState } from 'react';
import { dateIsAfterUTC } from 'helpers/dates/dates';

const { Item } = Form;

// FNAC DIGITECH_CH

export default function MoreTheftDetails() {
  const { partner } = useAppState();
  const detailsMsg = useFormatMsg('claims.declaration.details');
  const msg = useFormatMsg('generics');
  const [simCardBlocked, setSimCardBlocked] = useState(false);

  if (!['FN', 'DICH', 'SA', 'MO', 'YA', 'MI'].includes(partner)) return null;
  const required = [{ required: true, message: detailsMsg('required') }];

  return (
    <>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Item
            name="SIM_CARD_BLOCKED"
            label={detailsMsg('locked_sim.label')}
            rules={required}
            labelCol={{ span: 24 }}
          >
            <Radio.Group
              className="flex-col"
              onChange={e => setSimCardBlocked(e.target.value)}
            >
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Item>
        </Col>
        {simCardBlocked && (
          <Col xs={24} md={12}>
            <Item
              name="MENTION_DATE_SIM_BLOCKED"
              label={detailsMsg('date_sim_card_blocked.label')}
              rules={required}
              labelCol={{ span: 24 }}
            >
              <DatePicker
                placeholder={detailsMsg('THEFT.date_sim_blocked.placeholder')}
                format="DD/MM/YYYY"
                disabledDate={date => dateIsAfterUTC(date)}
              />
            </Item>
          </Col>
        )}
        {['FN', 'DICH', 'MI'].includes(partner) && (
          <Col xs={24} md={12}>
            <Item
              name="FRAUDULENT_USE"
              label={detailsMsg('fraudulent_use.label')}
              rules={required}
              labelCol={{ span: 24 }}
            >
              <Radio.Group className="flex-col">
                <Radio value={true}>{msg('yes')}</Radio>
                <Radio value={false}>{msg('no')}</Radio>
              </Radio.Group>
            </Item>
          </Col>
        )}
      </Row>
    </>
  );
}
