import React, { Fragment } from 'react';
import { Row, Col, Select, Typography, Form, Input, Radio } from 'antd';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import FormHelp from 'components/shared/FormHelp';
import countries from 'data/countries';

const { Title } = Typography;

export default function FreeCase({ device }) {
  const { manufacturer, model } = device;
  const msg = useFormatMsg('policies.free_case_request');
  return (
    <Fragment>
      <Title level={5}>{msg('information.title')}</Title>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            label={msg('information.manufacturer.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input value={manufacturer} disabled />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={msg('information.model.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input value={model} disabled />
          </Form.Item>
        </Col>
      </Row>
      <Title level={5}>{msg('details.title')}</Title>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="contactEmail"
            label={msg('details.contact_email.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
            help={<FormHelp>{msg('details.contact_email.help')}</FormHelp>}
          >
            <Input placeholder={msg('details.contact_email.placeholder')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="contactPhone"
            label={msg('details.contact_phone.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('details.contact_phone.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
      <Title style={{ marginTop: '2rem' }} level={5}>
        {msg('address.title')}
      </Title>
      <Form.Item
        name="salutation"
        label={msg('address.salutation.label')}
        rules={[{ required: true, message: msg('required') }]}
        labelCol={{ span: 24 }}
      >
        <Radio.Group className="flex-col">
          <Radio value="MR">{msg('address.salutation.mr')}</Radio>
          <Radio value="MRS">{msg('address.salutation.mrs')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Row
        justify="space-between"
        style={{ marginTop: '1rem' }}
        gutter={[16, 16]}
      >
        <Col xs={24} md={12}>
          <Form.Item
            name="firstName"
            label={msg('address.firstname.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('address.firstname.placeholder')} />
          </Form.Item>
          <Form.Item
            name="streetAddress"
            label={msg('address.street_address.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('address.street_address.placeholder')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="lastName"
            label={msg('address.lastname.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('address.lastname.placeholder')} />
          </Form.Item>

          <Form.Item
            name="postCode"
            label={msg('address.postal_code.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('address.postal_code.placeholder')} />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="city"
            label={msg('address.address_locality.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('address.address_locality.placeholder')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="country"
            label={msg('address.country.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Select data-cy="country">
              {countries.map(country => (
                <Select.Option key={country} value={country}>
                  {msg(`countries.${country}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  );
}
