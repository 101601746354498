import useFetch from 'hooks/fetch/useFetch'
import { useUser } from 'providers/user/User'
import React from 'react'
import { Redirect } from 'react-router-dom'
import LoginForm from './LoginForm'

function Login() {
  const { login, user } = useUser()
  const { isLoading, request } = useFetch(login)

  if (user.isLogged) {
    return <Redirect to="/dashboard" />
  }

  return <LoginForm onSubmit={request} loading={isLoading} />
}

export default Login
