import { Col, DatePicker, Form, Row, TimePicker, Typography } from 'antd';
import { dateIsAfterUTC } from 'helpers/dates/dates';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import React, { useState } from 'react';
import { useAppState } from 'services/providers/AppState';

const { Title } = Typography;
const { Item } = Form;

export default function DataRecoveryDetails({}) {
  const { messages } = useAppState();
  const [responsible, setResponsible] = useState('INSURED');

  const detailsMsg = useFormatMsg('claims.declaration.details');
  const msg = useFormatMsg('generics');

  const requiredRules = [
    {
      required: true,
      message: detailsMsg('required'),
    },
  ];
  const { incidentCategories, incidentTypes, responsibleTypes } =
    messages.claims.declaration.details.DAMAGE;
  return (
    <>
      <Title level={5}>{detailsMsg('claim_details')}</Title>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          {/* <Item
            name="incidentLocation"
            label={detailsMsg('DAMAGE.incidentLocation.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Input />
          </Item> */}
          <Item
            name="incidentDate"
            label={detailsMsg('incident_date.label.1')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <DatePicker
              placeholder={detailsMsg('incident_date.placeholder')}
              format="DD/MM/YYYY"
              disabledDate={date => dateIsAfterUTC(date)}
            />
          </Item>

          {/* <Item
            name="incidentDescription"
            label={detailsMsg('DAMAGE.incident_description.label')}
            help={
              <FormHelp>
                ({detailsMsg('DAMAGE.incident_description.help')})
              </FormHelp>
            }
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input.TextArea
              placeholder={detailsMsg(
                'DAMAGE.incident_description.placeholder',
              )}
            />
          </Item> */}
        </Col>
        <Col xs={24} md={12}>
          <Item
            name="INCIDENT_TIME"
            label={detailsMsg('DAMAGE.incident_time.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <TimePicker
              format="HH:mm"
              placeholder={detailsMsg('DAMAGE.incident_time.placeholder')}
            />
          </Item>
          {/* <Item
            name="incidentCategory"
            label={detailsMsg('DAMAGE.incident_category.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <TreeSelect
              placeholder={detailsMsg('DAMAGE.incident_category.placeholder')}
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={incidentCategories}
              treeDefaultExpandAll
            />
          </Item>
          <Item
            name="incidentType"
            label={detailsMsg('incident_type.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <TreeSelect
              placeholder={detailsMsg('incident_type.placeholder')}
              treeCheckable
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={incidentTypes}
              showArrow
            />
          </Item> */}
        </Col>
      </Row>
      {/* <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Item
            name="responsible"
            label={detailsMsg('DAMAGE.responsible.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <TreeSelect
              placeholder={detailsMsg('DAMAGE.responsible.placeholder')}
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={responsibleTypes}
              onChange={setResponsible}
            />
          </Item>

          <Form.Item
            name="THIRD_CLAIM_RAISED"
            label={detailsMsg('third_claim_raised.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          {responsible !== 'INSURED' && (
            <Form.Item
              name="THIRD_PARTY"
              label={detailsMsg('third_party.label')}
              rules={requiredRules}
              labelCol={{ span: 24 }}
            >
              <Input.TextArea
                placeholder={detailsMsg('third_party.placeholder')}
              />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="HAVE_DEVICE"
            label={detailsMsg('DAMAGE.have_device.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
         <Col xs={24} md={12}>
          <Form.Item
            name="CASHLESS_REPAIR"
            label={detailsMsg('DAMAGE.cashless_repair.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col> 
      </Row> */}
    </>
  );
}
