import { Card, Col, Divider, Row } from 'antd';
import { Skeleton, Space, Typography } from 'antd';
import { formateDateDDMMYYYY } from 'helpers/dates/formatDate';
import React from 'react';
import { useUser } from 'providers/user/User';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import {
  UserOutlined,
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import CustomerEditModal from './CustomerEditModal';

const { Text, Paragraph, Title } = Typography;

function CustomerOverview({ data }) {
  const { user } = useUser();
  const msg = useFormatMsg('app.overview');
  const supportEmail = user.supportEmail;

  return (
    <Card
      title={
        <>
          <Title level={4}>{msg('caption')}</Title>
          <Title level={4} style={{ marginTop: '4px' }}>
            {msg('customerName', {
              firstName: data.firstName,
              lastName: data.lastName,
            })}
          </Title>
        </>
      }
      style={{
        marginBottom: '2rem',
        backgroundColor: '#E8E8E8',
        borderRadius: '16px',
      }}
      bordered={false}
      headStyle={{ border: 'none', paddingLeft: '40px' }}
    >
      <Skeleton loading={!data}>
        <Col style={{ padding: '0px 16px 32px 16px' }}>
          <Row justify="space-between">
            <Paragraph strong>{msg('title')}</Paragraph>
            <CustomerEditModal />
          </Row>
          <Row align="middle">
            <UserOutlined style={{ marginRight: '4px' }} />
            <Text>{msg('birthdate')}</Text>
          </Row>
          <Row>
            <Text style={{ marginLeft: '18px' }}>
              {formateDateDDMMYYYY(data.birthDate)}
            </Text>
          </Row>
          <Row align="middle" style={{ marginTop: '8px' }}>
            <HomeOutlined style={{ marginRight: '4px' }} />
            <Text>{msg('address')}</Text>
          </Row>
          <Row>
            <Text style={{ marginLeft: '18px' }}>{data.address}</Text>
          </Row>
          <Row>
            <Text style={{ marginLeft: '18px' }}>
              {data.postalCode}, {data.addressLocality}
            </Text>
          </Row>
          <Divider style={{ backgroundColor: '#8C8C8C' }} />
          <Row>
            <Paragraph strong>{msg('contact.details')}</Paragraph>
          </Row>
          <Row align="middle">
            <MailOutlined style={{ marginRight: '4px' }} />
            <Text>{msg('email')}</Text>
          </Row>
          <Row>
            <Text style={{ marginLeft: '18px' }}>{data.email}</Text>
          </Row>
          <Row align="middle" style={{ marginTop: '8px' }}>
            <PhoneOutlined style={{ marginRight: '4px' }} />
            <Text>{msg('phone')}</Text>
          </Row>
          <Row>
            <Text style={{ marginLeft: '18px' }}>{data.phone}</Text>
          </Row>
        </Col>
        <Row
          style={{
            borderRadius: '16px',
            padding: '16px',
          }}
          className="advantageListBackground"
        >
          <Col>
            <Paragraph strong style={{ color: 'white' }}>
              {msg('contact.title')}
            </Paragraph>
            <Space direction="vertical" style={{ marginBottom: '1rem' }}>
              <Text style={{ color: 'white' }}>{msg('contact.text')}</Text>
              <Text style={{ color: 'white' }}>{supportEmail}</Text>
            </Space>
          </Col>
        </Row>
      </Skeleton>
    </Card>
  );
}

export default CustomerOverview;
