import IdleTimer from 'react-idle-timer';
import { useUser } from './user/User';
import React, { useRef } from 'react';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import Notify from 'helpers/messages/Notify';
import { useAppState } from 'services/providers/AppState';

export default Comp => {
  const TIMEOUT = 1000 * 60 * 45;
  return () => {
    // const { rollbar } = useAppState()
    const { logout } = useUser();
    const idleTimer = useRef();
    const msg = useFormatMsg('auth.login.idle_timer');

    const handleOnIdle = () => {
      // rollbar.info('Idle Time too long')
      logout();
      Notify.warning({ msg });
    };

    return (
      <IdleTimer
        ref={idleTimer}
        timeout={TIMEOUT}
        onIdle={handleOnIdle}
        debounce={1000}
        crossTab={{
          emitOnAllTabs: true,
        }}
      >
        <Comp />
      </IdleTimer>
    );
  };
};
