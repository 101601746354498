import { openNotification } from 'helpers/Notifications';
import { useEffect } from 'react';

const useNotificationError = (error) => {
  useEffect(() => {
    error &&
      openNotification({
        message: error.message,
        description: error.description,
        type: 'error',
      });
  }, [error]);
};

export default useNotificationError;
