import { useEffect, useState } from 'react';
import { useApi } from 'services/providers/api/Api';

export default function usePaymentLink({ claimId }) {
  const [link, setLink] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { getPaymentsByClaim } = useApi();

  useEffect(() => {
    getPaymentsByClaim(claimId)
      .then(res => setLink(res.data))
      .finally(() => setIsLoading(false));
  }, []);

  return {
    link,
    isLoading,
  };
}
