import { notification } from 'antd';

const Notify = ({ msg, text, type = 'success' }) => {
  notification[type]({
    message: msg,
    description: text,
    placement: 'topLeft',
    className: `${type}-alertbox-test`,
  });
};
export const notifyError = ({ error, msg }, withStatus = true) => {
  const status = error.status;
  const msgErr = withStatus
    ? msg(`onError.${status}.title`)
    : msg(`onError.title`);
  const detailErr = withStatus
    ? msg(`onError.${status}.description`)
    : msg(`onError.description`);

  const errorMsg = msgErr === '__ERROR' ? error.message : msgErr;
  const errorDetail = detailErr === '__ERROR' ? error.detail : detailErr;

  notification.error({
    message: errorMsg,
    description: errorDetail,
    placement: 'topLeft',
    className: 'error-alertbox-test',
  });
};
export const notifySuccess = ({ msg }) => {
  notification.success({
    message: msg('onSuccess.title'),
    description: msg('onSuccess.description'),
    placement: 'topLeft',
    className: 'error-alertbox-test',
  });
};
export const notifyWarning = ({ msg }) => {
  notification.warning({
    message: msg('title'),
    description: msg('description'),
    placement: 'topLeft',
  });
};
Notify.error = notifyError;
Notify.success = notifySuccess;
Notify.warning = notifyWarning;

export { Notify };
export default Notify;
