import { Modal } from 'antd';

function modalMsg({ error, msg, onOk }, supportEmail) {
  const modalMsgPath = error ? `onError.${error.status}` : `onSuccess`;
  const modal = Modal[error ? 'error' : 'success'];

  const errorMsg =
    error &&
    error.status !== 400 &&
    error.status !== 403 &&
    error.status !== 404 &&
    error.status !== 500
      ? { title: error.message, content: error.title, okText: 'Ok' }
      : {};

  modal({
    title: errorMsg.title || msg(`${modalMsgPath}.title`),
    content:
      errorMsg.content || msg(`${modalMsgPath}.description`, { supportEmail }),
    okText: errorMsg.okText || msg(`${modalMsgPath}.btn.label`),
    onOk,
  });
}
export default modalMsg;
