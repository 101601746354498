import { useState, useEffect } from 'react';
import { useApi } from 'services/providers/api/Api';
import { workOrderMapper } from 'services/providers/api/mappers';

const useClaimWorkorders = claimId => {
  const [workOrders, setWorkOrders] = useState([]);
  const [loadingWO, setLoadingWO] = useState(true);

  const { getClaimWorkorder } = useApi();

  const queryWorkOrders = getClaimWorkorder(claimId);

  const fetchWorkorders = () => {
    queryWorkOrders()
      .then(res => setWorkOrders(workOrderMapper(res)))
      .finally(() => setLoadingWO(false));
  };

  useEffect(() => {
    claimId && fetchWorkorders();
  }, [claimId]);

  return {
    workOrders,
    loadingWO,
  };
};

export default useClaimWorkorders;
