import consent from './consent'
import getDetailsValues from './details'
import getDeviceValues from './device'
import getRegulationValues from './regulation'

const getInitialValues = (user, insuredItem, { claimType }) => {
  return {
    ...consent,
    ...getDeviceValues(insuredItem),
    ...getDetailsValues(claimType),
    ...getRegulationValues(user),
  }
}
export default getInitialValues
