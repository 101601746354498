export default (status) => {
  if (typeof status !== 'string') return;

  switch (status.toLowerCase()) {
    case 'active':
      return 'green';
    case 'pending':
      return 'gold';
    case 'closed':
    case 'cancelled':
      return '#ff4d4f';
    case 'invalidated':
      return '#C3CBCD';
  }
};
