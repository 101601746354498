import { Card, Row, Col, Form, Button, Typography, Input } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useQuery from 'services/hooks/fetch/useQuery';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import styled from 'styled-components';
import imgSrc from 'public/img/case_request/case_request_3';
import { isValidIBAN } from 'helpers/formValidations/validators';
import Notify from 'helpers/messages/Notify';
import Uploader from 'components/containers/claims/useCases/uploadDocuments/Uploader';
import { useApi } from 'services/providers/api/Api';

const { Title, Paragraph, Text } = Typography;

const tagCaseInvoice = 'case-and-screen-protector-invoice';

const NotAvailableOrderPage = ({ policyId }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const params = useParams();
  const msg = useFormatMsg('policies.free_case_request.not_available');
  const goBack = () => {
    history.push('/dashboard');
  };
  const { query } = useQuery('putAddOnBankAccount', {
    action: params.addOnId,
  });
  const { uploadPolicyFile } = useApi();

  const onSubmitAccountDetails = async payload => {
    const { [tagCaseInvoice]: policyFiles, ...formData } = payload;
    const { error } = await query(formData);
    if (!error) {
      policyFiles &&
        sendFiles(uploadPolicyFile(policyId, tagCaseInvoice), policyFiles);
    } else {
      Notify.error({ msg, error }, false);
    }
  };

  const validateIBAN = (e, value) => {
    if (value === '') return Promise.reject(msg('required'));
    return !isValidIBAN(value)
      ? Promise.reject(msg('not_valid_iban'))
      : Promise.resolve();
  };

  const onChange = ({ fileList }) => {
    return fileList;
  };

  const sendFiles = async (query, uploads) => {
    const formData = new FormData();
    uploads.forEach(file => {
      formData.append('files[]', file.originFileObj);
    });
    try {
      const resp = await query(formData);
      if (resp.status === 200) {
        Notify.success({ msg });
        history.push('/dashboard');
      }
    } catch (error) {
      history.push('/dashboard');
    }
  };

  return (
    <Row style={{ height: '100%' }} align="middle" gutter={[32, 32]}>
      <Col xs={24} md={12}>
        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          <Title level={5}>{msg('title')}</Title>
          <Paragraph>{msg('text_1')}</Paragraph>
          <Paragraph>{msg('text_2')}</Paragraph>
          <Form form={form} onFinish={onSubmitAccountDetails}>
            <Form.Item
              name="accountHolder"
              label={msg('accountHolder')}
              rules={[{ required: true, message: msg('required') }]}
              labelCol={{ span: 24 }}
            >
              <Input placeholder={msg('accountHolder')} />
            </Form.Item>
            <Form.Item
              name="iban"
              label={msg('iban')}
              rules={[
                {
                  required: true,
                  validator: validateIBAN,
                },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input placeholder={msg('iban')} />
            </Form.Item>
            <Form.Item
              name={tagCaseInvoice}
              rules={[{ required: true, message: msg('not_empty') }]}
              valuePropName="fileList"
              getValueFromEvent={onChange}
              labelCol={{ span: 24 }}
            >
              <Uploader
                maxFiles={1}
                beforeUpload={() => false}
                listType="text"
                msg={msg(tagCaseInvoice)}
                accept="application/pdf,image/png,image/jpg,image/jpeg"
              />
            </Form.Item>
            <Row justify="space-between" style={{ marginTop: '10px' }}>
              <Button onClick={goBack}>{msg('back_to_portal.btn')}</Button>
              <Button
                onClick={() => form.submit()}
                type="primary"
                data-cy="sendBankAccDetails"
              >
                {msg('submit')}
              </Button>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col xs={24} md={12}>
        <Img src={imgSrc} />
      </Col>
    </Row>
  );
};
const Img = styled('img')`
  width: 100%;
  //height: 300px;
  object-fit: cover;
  object-position: center;
`;

export default NotAvailableOrderPage;
