import { useState, useEffect } from 'react';
import { useApi } from 'services/providers/api/Api';

const usePolicyAddons = policy => {
  const [availableAddOns, setAvailableAddOns] = useState([]);
  const { checkForAvailableAddOns } = useApi();

  const queryAvailableAddOns = checkForAvailableAddOns(policy.id);

  const fetchPolicyData = async () => {
    try {
      const { data: newAvailableAddOns } = await queryAvailableAddOns();
      newAvailableAddOns.length && setAvailableAddOns(newAvailableAddOns);
    } catch (err) {}
  };

  useEffect(() => {
    policy.productId && fetchPolicyData();
  }, [policy.productId]);

  return {
    availableAddOns,
  };
};

export default usePolicyAddons;
