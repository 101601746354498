import { useIntl } from 'react-intl';
import { defaultTranslations } from './defaultTranslations';

const useFormatMsg = path => {
  const intl = useIntl();
  const message = (id, values) => {
    const msgId = path ? `${path}.${id}` : id;
    return intl.formatMessage(
      {
        id: msgId,
        defaultMessage: defaultTranslations[msgId] || `_ERROR_${msgId}`,
      },
      values,
    );
  };

  return message;
};

export default useFormatMsg;
