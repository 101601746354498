import { Col, Form, Row, Tooltip } from 'antd';
import React from 'react';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import { useAppState } from 'services/providers/AppState';
import { useClaimContext } from 'services/providers/ClaimDeclaration';
import ElectronicDevice from './ElectronicDevice';
import MobileDevice from './MobileDevice';
import Uploader from './Uploader';
import { InfoCircleOutlined } from '@ant-design/icons';

function Device() {
  const {
    deviceCategory,
    setSelectedDevice,
    selectedDevice,
    insuredItems,
    hasDeviceModified,
    setHasDeviceModified,
    ...context
  } = useClaimContext();
  const msg = useFormatMsg('claims.declaration.device');
  const checkUpload = () => {
    return context.policyFiles.length > 0
      ? Promise.resolve()
      : Promise.reject(msg('required'));
  };
  const { partner } = useAppState();
  const onModifyDevice = ({ target }) => {
    const field = target.name;
    const selection = selectedDevice[field] || '';
    if (selection !== target.value) {
      setHasDeviceModified(true);
      return;
    }
    setHasDeviceModified(false);
  };
  React.useEffect(() => {
    setSelectedDevice(insuredItems[0]);
  });
  const onChangeDevice = index => {
    const device = insuredItems[index];
    setSelectedDevice(device);
  };

  let uploaderJsx = null;

  if (hasDeviceModified) {
    uploaderJsx = (
      <Row gutter={(16, 16)}>
        <Col xs={24}>
          <Uploader
            name="policyFiles"
            listType="text"
            accept="application/pdf,image/png,image/jpg,image/jpeg"
          />
        </Col>
      </Row>
    );
  }
  if (['RF', 'BMJP'].includes(partner)) {
    uploaderJsx = (
      <Col>
        <Row style={{ marginBottom: '16px' }}>
          <Form.Item
            name="policyFiles"
            style={{ margin: '0px' }}
            rules={[
              {
                required: true,
                validator: checkUpload,
              },
            ]}
          >
            <Uploader
              name="policyFiles"
              listType="text"
              accept="application/pdf,image/png,image/jpg,image/jpeg"
            />
          </Form.Item>
          <InfoCircleOutlined
            style={{
              marginLeft: '32px',
              marginRight: '8px',
              alignItems: 'center',
            }}
          />
          <div
            style={{
              alignContent: 'center',
            }}
          >
            {msg('tooltip_proof_of_purchase')}
          </div>
        </Row>
      </Col>
    );
  }

  const props = {
    children: uploaderJsx,
    onModifyDevice,
    insuredItems,
    onChangeDevice,
    selectedDevice,
    ...context,
  };

  switch (deviceCategory) {
    case 'MOBILE':
      return <MobileDevice {...props} />;
    case 'ELECTRONICS':
      return <ElectronicDevice {...props} />;
    default:
      return null;
  }
}
export default Device;
