import { useModalContext } from './ModalContext';
import React, { useState } from 'react';
import Notify from 'helpers/messages/Notify';
import { Modal } from 'antd';

const useHandleSubmit = () => {
  const { api, context, msg } = useModalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState({ damages: [], report: [], proof: [] });
  const [hasFilesToUpload, setHasFilesToUpload] = useState(false);
  const { uploadPolicyFile, uploadClaimFile } = api;
  const { policyId, claimId } = context;

  React.useEffect(() => {
    const hasToUpload =
      files.damages?.length > 0 ||
      files.proof?.length > 0 ||
      files.family?.length > 0 ||
      files.repair?.length > 0 ||
      files.report?.length > 0;
    setHasFilesToUpload(hasToUpload);
  }, [files]);

  const onSubmit = () => {
    const query = {
      proof: uploadClaimFile(claimId, 'proof-of-purchase'),
      damages: uploadClaimFile(claimId, 'damages'),
      report: uploadClaimFile(claimId, 'police-report'),
      family: uploadClaimFile(claimId, 'proof-of-family'),
      repair: uploadClaimFile(claimId, 'repair-invoice'),
    };

    Object.keys(files).forEach(key => {
      const uploads = files[key];
      if (uploads.length < 1) return;
      sendFiles(query[key], uploads, msg, setIsLoading);
    });
  };
  return { onSubmit, setFiles, files, hasFilesToUpload, isLoading };
};
const sendFiles = async (query, uploads, msg, setIsLoading) => {
  const formData = new FormData();
  uploads.forEach(file => {
    formData.append('files[]', file);
  });
  setIsLoading(true);
  try {
    await query(formData);
    Notify.success({ msg }); // try to fix multiple upload errors
    Modal.destroyAll();
  } catch (error) {
    Notify.error({ error, msg });
  }
  setIsLoading(false);
};
export default useHandleSubmit;
