import { useState, useEffect } from 'react';
import { useApi } from 'services/providers/api/Api';

const usePolicyDocuments = policy => {
  const [policyDocuments, setPolicyDocuments] = useState([]);
  const { checkForPolicyDocuments } = useApi();
  const queryAvailableDocuments = checkForPolicyDocuments(policy.id);

  const fetchPolicyDocuments = async () => {
    const { data: policyDocuments } = await queryAvailableDocuments();
    policyDocuments?.length && setPolicyDocuments(policyDocuments);
  };

  useEffect(() => {
    policy.id && fetchPolicyDocuments();
  }, [policy]);

  return {
    policyDocuments,
  };
};

export default usePolicyDocuments;
