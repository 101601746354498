import React from 'react';
import PolicyRequestsList from 'components/containers/policies/repositories/policyOrderList/PolicyRequestsList';
import PolicyList from 'components/containers/policies/repositories/policyList/PolicyList';
import ClaimList from 'components/containers/claims/repositories/ClaimList';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import { FileOutlined, ToolOutlined, MobileOutlined } from '@ant-design/icons';
import { useAppState } from 'services/providers/AppState';

export default () => {
  const msg = useFormatMsg('app.main_content');
  const { customerPortalConfig } = useAppState();
  const tabItems = [
    {
      Comp: <PolicyList />,
      key: 'policies',
      msg: msg('policies'),
      Icon: FileOutlined,
      id: 'button_overview_policies',
    },
    {
      Comp: <ClaimList />,
      key: 'claims',
      msg: msg('claims'),
      Icon: ToolOutlined,
      id: 'button_overview_claims',
    },
    ...(!customerPortalConfig?.configuration?.main?.hide_extra_request
      ? [
          {
            Comp: <PolicyRequestsList />,
            key: 'policy_extra_requests',
            msg: msg('policy_extra_requests'),
            Icon: MobileOutlined,
            id: 'button_overview_extra_request',
          },
        ]
      : []),
  ];
  const keys = tabItems.map(item => item.key);

  return { tabItems, keys };
};
