export const BMCountries = ['IT', 'ES', 'DE', 'FR', 'NL', 'BE'];

export default [
  'ES',
  'DE',
  'IT',
  'BE',
  'NL',
  'UK',
  'AT',
  'FI',
  'PL',
  'SE',
  'PT',
  'GR',
  'IE',
  'FR',
];
