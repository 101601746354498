import { Empty, List } from 'antd';
import PolicyOrder from 'components/containers/policies/entity/order/PolicyOrder';
import React from 'react';
import useInitialQuery from 'services/hooks/fetch/useInitialQuery';
import { useUser } from 'services/providers/user/User';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import Spinner from 'components/shared/Spinner';
import { policyListMapper } from 'services/providers/api/mappers';

const PolicyRequestsList = () => {
  const { user } = useUser();
  const msg = useFormatMsg('policies.policy_requests');
  const { data, isLoading, error } = useInitialQuery(
    'checkForAddOnsByCustomerId',
    {
      payload: user.customerId,
    },
  );
  const {
    data: dataP,
    isLoading: isLoadingP,
    error: errorP,
  } = useInitialQuery('getPoliciesByCustomerId', {
    map: policyListMapper,
    payload: user.customerId,
  });

  const addons = data?.data || [];
  const policiesData = dataP?.data || [];
  const policiesWithAddons = policiesData.map(p => ({
    ...p,
    addons: addons.find(a => a.policyId === p.id),
  }));

  const emptyText = <Empty description={msg('empty_data')} />;

  if (isLoading || isLoadingP) return <Spinner />;
  if (error || errorP) return null;

  const render = item => (
    <List.Item key={`pid${item.id}`} style={{ borderBottom: 0 }}>
      <PolicyOrder isLoading={isLoading} data={item} />
    </List.Item>
  );

  return (
    <List
      dataSource={policiesWithAddons}
      renderItem={render}
      locale={{ emptyText }}
    />
  );
};
export default PolicyRequestsList;
