import React from 'react';
import { Typography, Table } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph, Text } = Typography;

export const renderGenericSectionElement = (element, msg) => {
  const {
    centered,
    key,
    listItems,
    level,
    listType,
    style,
    text,
    to,
    type,
    dataSource,
    columns,
  } = element;

  switch (type) {
    case 'title':
      return (
        <Title
          level={level}
          style={{ textAlign: centered ? 'center' : 'left', width: '100%' }}
        >
          {msg(text)}
        </Title>
      );
    case 'link':
      return <Link to={to}>{msg(text)}</Link>;
    case 'list':
      return (
        <ul style={style}>
          {listItems.map(listItemText => (
            <li key={listItemText}>
              <Paragraph style={{ margin: 0 }}>{msg(listItemText)}</Paragraph>
            </li>
          ))}
        </ul>
      );
    case 'orderedList':
      return (
        <ol style={style} type={listType}>
          {listItems.map(listItemText => (
            <li key={listItemText}>
              <Paragraph style={{ margin: 0 }}>{msg(listItemText)}</Paragraph>
            </li>
          ))}
        </ol>
      );
    case 'complexList':
      return (
        <ol type={listType}>
          {listItems.map(({ elements }, i) => (
            <li key={`${key}Item${i}`}>
              {elements.map((el, j) => (
                <div key={`${key}SubItem${j}`}>
                  {renderGenericSectionElement(el, msg)}
                </div>
              ))}
            </li>
          ))}
        </ol>
      );
    case 'bold':
      return <Paragraph strong>{msg(text)}</Paragraph>;
    case 'text':
      return <Text>{msg(text)}</Text>;
    case 'dangerous':
      return (
        <Paragraph style={style}>
          <div
            dangerouslySetInnerHTML={{
              __html: msg(text),
            }}
          />
        </Paragraph>
      );
    case 'verticalSpace':
      return <div style={{ width: '100%', ...style }} />;
    case 'table':
      return (
        <Paragraph>
          <div
            dangerouslySetInnerHTML={{
              __html: msg(text),
            }}
          />
        </Paragraph>
      );
    case 'paragraph':
    default:
      return <Paragraph>{msg(text)}</Paragraph>;
  }
};
