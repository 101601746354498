import React from 'react';
import { Tag } from 'antd';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { statusAddOns } from 'helpers/statuses/addOn/status';

export default function StatusViewer({ status }) {
  const msg = useFormatMsg('policies.policy_requests');

  return (
    <Tag color="#B7EB8F" style={{ width: '100%' }}>
      {msg(`statuses.${statusAddOns[status]}`)}
    </Tag>
  );
}
