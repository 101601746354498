import cookiesBMJP from './cookies';
import imprintBMJP from './imprint';
import privacyBMJP from './privacy';
import otConfig from './otConfig';
import ga from './ga';

export default {
  ...ga,
  title: 'Back Market',
  landingImg:
    'https://i-con-public-static-content.s3.eu-central-1.amazonaws.com/my-customer-space/Landing_BM.png',
  favicon:
    'https://i-con-public-static-content.s3.eu-central-1.amazonaws.com/my-customer-space/BM_favicon.ico',
  keyLoginConfig: 'birthDate',
  langs: ['en', 'ja'],
  defaultLang: 'ja',
  faq: [
    { from: 1, to: 19, notShowed: [8, 13] },
    { from: 20, to: 28, notShowed: [21, 22, 23, 24, 25, 28] },
    { from: 29, to: 47 },
  ],
  cookies: cookiesBMJP,
  imprint: imprintBMJP,
  privacy: privacyBMJP,

  logo: {
    src: 'https://i-con-public-static-content.s3.eu-central-1.amazonaws.com/my-customer-space/New_Back_Market_Logo.svg',
    alt: 'Back Market',
    width: '194.56px',
  },
  footerlogo: {
    src: 'https://i-con-public-static-content.s3.eu-central-1.amazonaws.com/my-customer-space/New_Back_Market_Logo_White.svg',
    alt: 'Footer Back Market',
  },
  company: 'bolttech',
  otScript: otConfig,
  footer: [
    {
      label: 'terms_of_use',
      link: '/terms-of-use',
    },
    {
      label: 'faq',
      link: '/faq',
    },
  ],
};
