import {
  Col,
  Form,
  Input,
  Radio,
  Row,
  Typography,
  Select,
  TreeSelect,
} from 'antd';
import FormHelp from 'components/shared/FormHelp';
import { isValidPhone } from 'helpers/formValidations/validators';
import { useClaimContext } from 'providers/ClaimDeclaration';
import React, { Fragment, useState } from 'react';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import { useAppState } from 'services/providers/AppState';

const { Title } = Typography;
const { Item } = Form;

export default function BMJPRegulation() {
  const { partner, messages } = useAppState();
  const { claimType } = useClaimContext();
  const msg = useFormatMsg('claims.declaration.regulation');

  const msgCountries = useFormatMsg('policies.free_case_request');
  const [requestReimbursement, setRequestReimbursement] = useState(false);
  const [accountType, setAccountType] = useState('POST_OFFICE');
  const requiredRules = [
    {
      required: true,
      message: msg('required'),
    },
  ];

  const validateOnlyNumbers = (e, value) => {
    if (value === '') return Promise.reject(msg('requiredOnlyNumbers'));
    return !isValidPhone(value)
      ? Promise.reject(msg('requiredOnlyNumbers'))
      : Promise.resolve();
  };

  const { responsibleTypes, bankAccountDetails } =
    messages.claims.declaration.details.DAMAGE;
  return (
    <Fragment>
      <Title level={5}>{msg('details.title')}</Title>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Item
            name="contactEmail"
            label={msg('contact_email.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
            help={<FormHelp>{msg('contact_email.help')}</FormHelp>}
          >
            <Input placeholder={msg('contact_email.placeholder')} />
          </Item>
        </Col>
        <Col xs={24} md={12}>
          <Item
            name="contactPhone"
            label={msg('contact_phone.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('contact_phone.placeholder')} />
          </Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Item
            name="phone2"
            label={msg('contact_phone2.label')}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('contact_phone2.placeholder')} />
          </Item>
        </Col>
      </Row>
      <Title style={{ marginTop: '2rem' }} level={5}>
        {msg('address.title')}
      </Title>
      <Row
        justify="space-between"
        style={{ marginTop: '1rem' }}
        gutter={[16, 16]}
      >
        <Col xs={24} md={12}>
          <Item
            name="lastName"
            label={msg('lastname.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('lastname.placeholder')} />
          </Item>
          <Item
            name="phoneticLastName"
            label={msg('phoneticlastname.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('phoneticlastname.placeholder')} />
          </Item>
          <Item
            name="postalCode"
            label={msg('postal_code.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('postal_code.placeholder')} />
          </Item>
          <Item
            name="addressLocality"
            label={msg('address_locality.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('address_locality.placeholder')} />
          </Item>
          <Item
            name="addressCountry"
            label={msgCountries('address.country.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Select disabled={partner !== 'BM'}>
              {/* TODO RETRIVE COUNTIRES FROM ENDPOINT */}
              {['JP'].map(country => (
                <Select.Option key={country} value={country}>
                  {msgCountries(`countries.${country}`)}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xs={24} md={12}>
          <Item
            name="firstName"
            label={msg('firstname.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('firstname.placeholder')} />
          </Item>
          <Item
            name="phoneticFirstName"
            label={msg('phoneticfirstname.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('phoneticfirstname.placeholder')} />
          </Item>
          <Item
            name="addressRegion"
            label={msg('address_region.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('address_region.placeholder')} />
          </Item>

          <Item
            name="streetAddress"
            label={msg('street_address.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('street_address.placeholder')} />
          </Item>
        </Col>
      </Row>
      <Title level={5}>{msg('bankdetails.title')}</Title>
      {claimType === 'DATA_RECOVERY' && (
        <>
          <Row>
            <Item
              name="request_reimbursement"
              label={msg('reimbursment.label')}
              labelCol={{ span: 24 }}
              onChange={() => {
                setRequestReimbursement(!requestReimbursement);
              }}
            >
              <Radio.Group className="flex-col cap-all">
                <Radio value={true}>{msg('bankdetails.yes')}</Radio>
                <Radio value={false}>{msg('bankdetails.no')}</Radio>
              </Radio.Group>
            </Item>
          </Row>
          {requestReimbursement && (
            <Row justify="space-between" gutter={[16, 16]}>
              {msg('open_damage_claim')}
            </Row>
          )}
        </>
      )}
      {claimType === 'DAMAGE' && (
        <>
          <Row
            justify="space-between"
            style={{ marginTop: '1rem' }}
            gutter={[16, 16]}
          >
            <Col xs={24} md={12}>
              <Item
                name="relationship"
                label={msg('relationship.label')}
                rules={requiredRules}
                labelCol={{ span: 24 }}
              >
                <TreeSelect
                  placeholder={msg('relationship.placeholder')}
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={responsibleTypes}
                  // onChange={setResponsible}
                />
              </Item>
            </Col>
            <Col xs={24} md={12}>
              <Item
                name="accountType"
                label={msg('accountType.label')}
                rules={requiredRules}
                labelCol={{ span: 24 }}
                initialValue={'POST_OFFICE'}
              >
                <Select
                  placeholder={msg('accountType.placeholder')}
                  style={{ width: '100%' }}
                  // dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  options={[
                    {
                      value: 'POST_OFFICE',
                      label: msg('bank.details.post_office'),
                    },
                    {
                      value: 'OTHER',
                      label: msg('bank.details.other'),
                    },
                  ]}
                  onChange={setAccountType}
                />
              </Item>
            </Col>
          </Row>
          <Row
            justify="space-between"
            style={{ marginTop: '1rem' }}
            gutter={[16, 16]}
          >
            {(accountType === 'POST_OFFICE'
              ? [
                  'accountHolder',
                  'phoneticAccountHolder',
                  'branchCode',
                  'bankIban',
                ]
              : [
                  'accountHolder',
                  'phoneticAccountHolder',
                  'bankName',
                  'bankType',
                  'branchName',
                  'branchType',
                  'bankCode',
                  'branchCode',
                  'bankIban',
                  'accountSavingType',
                ]
            ).map(field => {
              if (
                ['bankType', 'branchType', 'accountSavingType'].includes(field)
              ) {
                return (
                  <Col xs={24} md={12} key={field}>
                    <Item
                      name={field}
                      label={msg(`${field}.label`)}
                      rules={requiredRules}
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        placeholder={msg(`${field}.placeholder`)}
                        options={bankAccountDetails[field]}
                      />
                    </Item>
                  </Col>
                );
              }
              return (
                <Col xs={24} md={12} key={field}>
                  <Item
                    name={field}
                    label={msg(`${field}.label`)}
                    rules={requiredRules}
                    labelCol={{ span: 24 }}
                  >
                    <Input placeholder={msg(`${field}.placeholder`)} />
                  </Item>
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </Fragment>
  );
}
