import { Layout, Spin } from 'antd';
import config from 'app.config';
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import CookieAcceptance from './CookieAcceptance';
import { useAppState } from 'services/providers/AppState';

export default function AppLayout({ children }) {
  const { partner, isLoadingLanguage } = useAppState();
  const { logo } = config[partner];
  return (
    <Layout className="app-container">
      {isLoadingLanguage ? (
        <Spin className="app-loader" size="large" />
      ) : (
        <>
          <Header className="app-header" logo={logo} />
          <Layout className="app-layout">
            <Layout.Content className="app-content">{children}</Layout.Content>
          </Layout>
          {[].includes(partner) && <CookieAcceptance />}
          <Layout.Footer className="app-footer">
            <Footer />
          </Layout.Footer>
        </>
      )}
    </Layout>
  );
}
