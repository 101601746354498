import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useQuery from 'hooks/fetch/useQuery';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import AuthForm from './AuthForm';
import modalMsg from './modalMsg';
import { useAppState } from 'services/providers/AppState';

const remove0left = str => str.replace(/^0+/, '');

export default function Register() {
  const history = useHistory();
  const location = useLocation();
  const email = location.pathname.split('/')[2];
  const msg = useFormatMsg('auth.register');
  const { isLoading, query } = useQuery('customerRegistration');
  const { customerPortalConfig } = useAppState();

  const onSubmitRegister = async ({
    email,
    password,
    partnerId,
    phoneNumber,
    birthdate,
  }) => {
    const payload = {
      email,
      password,
      partnerId,
      phoneNumber: phoneNumber ? remove0left(phoneNumber) : undefined,
      birthdate,
    };
    const { error } = await query(payload);

    const onOkModal = () => {
      history.push('/login');
    };
    modalMsg({ error, msg, onOk: onOkModal }, ' ' /* supportEmailJsx */);
  };

  return (
    <React.Fragment>
      <AuthForm
        msg={msg}
        redirectedUser={email}
        onSubmit={onSubmitRegister}
        isLoading={isLoading}
        emailDisabled={!!email}
        email={email}
        phoneRequest={
          customerPortalConfig?.configuration?.register?.phone_request
        }
        birthdateRequest={
          customerPortalConfig?.configuration?.register?.birthdate_request
        }
      />
    </React.Fragment>
  );
}
