import { Typography, Row, Tag, Space } from 'antd';
import { formateDateDDMMYYYY } from 'helpers/dates/formatDate';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import styled from 'styled-components';

const { Text } = Typography;
export default function WorkOrderInfo({ workOrder }) {
  const msg = useFormatMsg('claims.claim_view.workorder_details');
  const statusMsg = useFormatMsg('claims.claim_view.workorders.status');
  const pickColor = status => {
    switch (status) {
      case '50901':
      case '50903':
        return 'red';
      case '30910':
      case '30902':
      case '30905':
      case '30903':
      case '30911':
      case '30907':
        return 'yellow';
      case '40910':
      case '40912':
      case '20901':
      case '40913':
      case '40901':
      default:
        return 'green';
    }
  };
  return (
    <Space direction="vertical" size="small">
      <Row>
        <MarginText>{msg('number')}</MarginText>
        <Text>{workOrder.id}</Text>
      </Row>
      <Row>
        <MarginText>{msg('status')}</MarginText>
        <Tag color={pickColor(workOrder.status)}>
          {statusMsg(workOrder.status)}
        </Tag>
      </Row>
      <Row>
        <MarginText>{msg('last_update')}</MarginText>
        <Text>{formateDateDDMMYYYY(workOrder.updated)}</Text>
      </Row>
      {workOrder?.trackingNumber && (
        <Row>
          <MarginText>{msg('tracking_number')}</MarginText>
          <Text>{workOrder.trackingNumber}</Text>
        </Row>
      )}
    </Space>
  );
}

const MarginText = styled(Text)`
  margin-right: 4px;
`;
