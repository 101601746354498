import React from 'react';
import { render } from 'react-dom';
import { getPartner } from './helpers/partner';
import App from './App';
import appConfig from './app.config';
import { loadOTBanner } from 'helpers/seo';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { getGAKey } from 'helpers/googleAnalitics/GAandGTM';
import './styles/reset.css';
import './styles/layout.css';
import './styles/global.css';

import './modules/dayjs-setup';
import './Sentry';

const partner = getPartner();

// CONFIGURE GA AND GTM
const keys = getGAKey(partner);

if (keys) {
  ReactGA.initialize(keys.ga);
  TagManager.initialize({
    gtmId: keys.gtm,
  });
}

window.dataLayer.push({
  event: 'initialPageView',
});

// CONFIGURE GA AND GTM

loadOTBanner(appConfig[partner]);
document.title = appConfig[partner]?.title || 'Back Market';
let link = document.querySelector("link[rel~='icon']");
if (!link) {
  link = document.createElement('link');
  link.rel = 'icon';
  document.getElementsByTagName('head')[0].appendChild(link);
}
link.href =
  appConfig[partner]?.favicon ||
  'https://i-con-public-static-content.s3.eu-central-1.amazonaws.com/my-customer-space/BM_favicon.ico';

import(`styles/${partner}.css`)
  .then(() => {
    render(<App />, document.getElementById('root'));
  })
  .catch(() => {
    import('styles/BM.css').then(() => {
      render(<App />, document.getElementById('root'));
    });
  });
