import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React from 'react';

const Uploader = ({ maxFiles = 3, msg, ...props }) => {
  return (
    <Upload maxCount={maxFiles} multiple {...props}>
      <Button icon={<UploadOutlined />}>{msg}</Button>
    </Upload>
  );
};
export default Uploader;
