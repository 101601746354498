import { Col, Form, Input, Radio, Row, Typography, Select } from 'antd';
import FormHelp from 'components/shared/FormHelp';
import { useClaimContext } from 'providers/ClaimDeclaration';
import React, { Fragment } from 'react';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import countries, { BMCountries } from 'data/countries';
import { useAppState } from 'services/providers/AppState';
import BMJPRegulation from './partners/BMJPRegulation';

const { Title } = Typography;

export default function Regulation() {
  const { partner } = useAppState();
  const { claimType } = useClaimContext();
  const msg = useFormatMsg('claims.declaration.regulation');
  const msgCountries = useFormatMsg('policies.free_case_request');
  if (partner === 'BMJP') {
    return <BMJPRegulation />;
  }
  return (
    <Fragment>
      <Title level={5}>{msg('details.title')}</Title>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="contactEmail"
            label={msg('contact_email.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
            help={<FormHelp>{msg('contact_email.help')}</FormHelp>}
          >
            <Input placeholder={msg('contact_email.placeholder')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="contactPhone"
            label={msg('contact_phone.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('contact_phone.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
      <Title style={{ marginTop: '2rem' }} level={5}>
        {msg('address.title')}
      </Title>
      <Form.Item
        name="salutation"
        label={msg('salutation.label')}
        rules={[{ required: true, message: msg('required') }]}
        labelCol={{ span: 24 }}
      >
        <Radio.Group className="flex-col">
          <Radio value="MR">{msg('salutation.mr')}</Radio>
          <Radio value="MRS">{msg('salutation.mrs')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Row
        justify="space-between"
        style={{ marginTop: '1rem' }}
        gutter={[16, 16]}
      >
        <Col xs={24} md={12}>
          <Form.Item
            name="firstName"
            label={msg('firstname.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('firstname.placeholder')} />
          </Form.Item>
          <Form.Item
            name="streetAddress"
            label={msg('street_address.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('street_address.placeholder')} />
          </Form.Item>
          <Form.Item
            name="postalCode"
            label={msg('postal_code.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('postal_code.placeholder')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="lastName"
            label={msg('lastname.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('lastname.placeholder')} />
          </Form.Item>
          <Form.Item
            name="addressLocality"
            label={msg('address_locality.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={msg('address_locality.placeholder')} />
          </Form.Item>
          <Form.Item
            name="addressCountry"
            label={msgCountries('address.country.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Select disabled={partner !== 'BM'}>
              {/* TODO RETRIVE COUNTIRES FROM ENDPOINT */}
              {(partner === 'BM' ? BMCountries : countries).map(country => (
                <Select.Option key={country} value={country}>
                  {msgCountries(`countries.${country}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {claimType === 'DAMAGE' && (
        <Typography>{msg('repair_preferences.btn.local')}</Typography>
      )}
    </Fragment>
  );
}
