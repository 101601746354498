import submitFormData from './submitFormData';
import { returnUTC } from 'helpers/dates/dates';

const onFinishForm = async (
  data,
  currentStep,
  policy,
  { customerId },
  nextStep,
  setIsLoading,
  { setClaimId },
  declareClaim,
  uploadPolicyFile,
  uploadClaimFile,
) => {
  if (currentStep.label === 'regulation') {
    const payload = {
      ...data,
      policy,
      customer: customerId,
      incidentDate: `${data?.incidentDate?.format('YYYY-MM-DD')}T00:00:00.000Z`,
      reportedDate: returnUTC(),
    };
    const callback = resp => {
      setClaimId(resp.data.data.id);
      // openNotification({
      //   message: <FormattedMessage id="generics.claimDeclaredSucces" />,
      //   type: 'success',
      // });
      nextStep();
    };
    setIsLoading(true);
    await submitFormData(
      declareClaim,
      payload,
      callback,
      uploadPolicyFile,
      uploadClaimFile,
    );
    setIsLoading(false);
  } else {
    nextStep();
  }
};

export default onFinishForm;
