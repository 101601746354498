import appConfig from 'app.config';

export const getPartner = () => {
  const { hostname } = window.location;

  if (hostname === 'localhost' && appConfig[process.env.PARTNER])
    return process.env.PARTNER;
  if (hostname.includes('my-customer-space-')) {
    return getPartnerFromHostname(hostname);
  }
  switch (hostname) {
    case 'backmarket.i-surance.eu':
    case 'backmarket.bolttech.eu':
      return 'BM';
    case 'backmarket.i-surance.de':
    case 'backmarket-uk.bolttech.eu':
      return 'BMUK';
    case 'galaxus.bolttech.eu':
      return 'DI';
    case 'refurbed.bolttech.eu':
      return 'RF';
    case 'backmarket.bolttech.jp':
      return 'BMJP';
    case 'mobile.monacocare.mc':
    case 'www.mobile.monacocare.mc':
      return 'MO';
    case 'caresupport.ch':
    case 'www.caresupport.ch':
      return 'SA';
    case 'fnaccare.ch':
      return 'FN';
    case 'insurance.digitec.ch':
      return 'DICH';
    case 'yalloprotect360.ch':
      return 'YA';
    case 'microspot-careplus.ch':
      return 'MI';
    case 'refurbedguarantee.bolttech.eu':
      return 'RFWE';
    default:
      return 'BM';
  }
};

const getPartnerFromHostname = hostname => {
  const partner = hostname.split('-')?.[4]?.split('.')?.[0]?.toUpperCase();
  return appConfig[partner] ? partner : 'BM';
};
