import React, { useState } from 'react';
import AuthLayout from 'components/base/AuthLayout';
import modalMsg from 'helpers/messages/modalMsg';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { useHistory } from 'react-router-dom';
import { openNotification } from 'helpers/Notifications';
import { useApi } from 'services/providers/api/Api';
import { useUser } from 'services/providers/user/User';
import { useAppState } from 'providers/AppState';
import { partnersAvailable } from 'data/partners';

function Verify() {
  const { partner } = useAppState();
  const msg = useFormatMsg('auth.verify');
  const [isDisabled, setIsDisabled] = useState(false);
  const { resendVerificationMail } = useApi();
  const { user, logout } = useUser();
  const history = useHistory();

  const onSubmit = () => {
    setIsDisabled(true);
    logout();
    resendVerificationMail({
      email: user.email,
      partnerId: partnersAvailable[partner],
    })
      .then(resp => {
        if (resp.status === 200) {
          modalMsg({ onOk: () => history.push('/login'), msg });
        }
      })
      .catch(() => {
        setIsDisabled(false);
        openNotification({
          message: msg('error.title'),
          description: msg('error.description'),
        });
      });
  };

  return (
    <AuthLayout
      title={msg('title')}
      caption={msg('caption')}
      btn={{
        id: 'buttonSubmitVerification',
        label: msg('button'),
        onClick: () => onSubmit(),
        disabled: isDisabled,
      }}
    />
  );
}

export default Verify;
