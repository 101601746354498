import React from 'react';
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  ShopOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';
import { CardWrapper, cssIcon, Card, Space } from './styled';
import { Button, Row, Typography } from 'antd';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import { useParams } from 'react-router-dom';
import useQuery from 'services/hooks/fetch/useQuery';
import Notify from 'helpers/messages/Notify';
import downloadFile from 'helpers/downloadFile';
import useInitialQuery from 'hooks/fetch/useInitialQuery';
import { policyCountryMapper } from 'services/providers/api/mappers';
import { useAppState } from 'services/providers/AppState';

const { Title, Paragraph, Text } = Typography;

export default function BatteryExchange() {
  const { policyId } = useParams();
  const { configuration } = useAppState();
  const msg = useFormatMsg('policies.battery_exchange');
  const pdfMsg = useFormatMsg('policies.battery_exchange.how.download');
  const { data } = useInitialQuery('getPoliciesByPolicyId', {
    map: policyCountryMapper,
    payload: policyId,
  });

  const shops = configuration?.batteryReplacement?.filter(shop =>
    shop.country.includes(data.country?.toUpperCase()),
  );

  const { query, isLoading } = useQuery('policyPassportPdf', {
    action: policyId,
  });

  const downloadPassport = async () => {
    const payload = shops.map(shop => {
      const { country, id, ...item } = shop;
      return item;
    });
    const { error, data } = await query({ shops: payload });
    if (error) {
      Notify.error({ error, msg: pdfMsg });
    } else {
      Notify.success({ msg: pdfMsg });
      downloadFile(data.data, `passport.${policyId}`, 'pdf');
    }
  };
  const backToPortal = () => {
    history.back();
  };

  const customTitle = title => <Title level={4}>{title}</Title>;

  return (
    <div>
      <Row style={{ gap: '1rem', marginBottom: '2rem' }}>
        <Button
          style={{ marginRight: '2rem' }}
          type="primary"
          ghost
          onClick={backToPortal}
        >
          <ArrowLeftOutlined />
          {msg('back_to_portal_btn')}
        </Button>
        <Title level={3} style={{ justifySelf: 'center' }}>
          {msg('title')}
        </Title>
      </Row>
      <CardWrapper>
        {customTitle(msg('how.title'))}
        <Space>
          <Card>
            <Title level={5}>
              <DownloadOutlined style={cssIcon} />
              {msg('how.download.title')}
            </Title>
            <Paragraph>{msg('how.download.text')}</Paragraph>
            <Button
              id="downloadPassportButton"
              type="primary"
              onClick={downloadPassport}
              loading={isLoading}
            >
              {msg('how.download.btn')}
            </Button>
          </Card>
          <Card>
            <Title level={5}>
              <ShopOutlined style={cssIcon} />
              {msg('how.shop.title')}
            </Title>
            <Paragraph>{msg('how.shop.text')}</Paragraph>
          </Card>
          <Card>
            <Title level={5}>
              <CreditCardOutlined style={cssIcon} />
              {msg('how.pay.title')}
            </Title>
            <Paragraph>{msg('how.pay.text')}</Paragraph>
          </Card>
        </Space>
      </CardWrapper>
      <CardWrapper>
        {customTitle(msg('shops.title'))}
        <Space>
          {shops?.map(shop => (
            <Shop key={shop.id} msg={msg} {...shop} />
          ))}
        </Space>
      </CardWrapper>
    </div>
  );
}

const Shop = ({ msg, title, discount, link }) => {
  return (
    <div>
      <Title level={5}>{title}</Title>
      <Paragraph>
        <Text>
          {discount} {msg('shops.discount')}
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          {msg('shops.check')}
          <a href={link}>{msg('shops.here')}</a>
        </Text>
      </Paragraph>
    </div>
  );
};
