import { useState, useEffect } from 'react';
import { useApi } from 'services/providers/api/Api';
import { voucherMapper } from 'services/providers/api/mappers';

const UseClaimVouchers = claimId => {
  const [vouchers, setVouchers] = useState([]);
  const [loadingVouchers, setLoadingVouchers] = useState(true);

  const { getClaimVouchers } = useApi();

  const queryVouchers = getClaimVouchers(claimId);

  const fetchVouchers = () => {
    queryVouchers()
      .then(res => setVouchers(voucherMapper(res)))
      .finally(() => setLoadingVouchers(false));
  };

  useEffect(() => {
    claimId && fetchVouchers();
  }, [claimId]);

  return {
    vouchers,
    loadingVouchers,
  };
};

export default UseClaimVouchers;
