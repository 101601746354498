import { Alert, Space, Button, Row, Col } from 'antd';
import React, { useState } from 'react';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { Link, useHistory } from 'react-router-dom';

const CookieAcceptance = () => {
  const history = useHistory();
  const msg = useFormatMsg('app.acceptance_cookies');

  const [acceptedCookies, setAcceptedCookies] = useState(
    localStorage.getItem('cookies'),
  );

  const handleAcceptCookies = () => {
    localStorage.setItem('cookies', true);
    setAcceptedCookies(true);
  };

  if (acceptedCookies) {
    return null;
  }

  return (
    <div
      id="cookiesAcceptance"
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 1,
      }}
    >
      <Row justify="center" style={{ padding: '20px 0px' }}>
        <Col xs={22}>
          <Alert
            message={msg('title')}
            description={
              <Row justify="space-between">
                <Col xs={24} sm={16}>
                  {msg('text')}
                  <Link to="/cookie-policy">{msg('link')}</Link>
                </Col>
                <Col xs={24} sm={8}>
                  <Row justify="end" style={{ marginTop: '8px' }}>
                    <Space>
                      <Button
                        id="continue"
                        size="small"
                        type="primary"
                        onClick={handleAcceptCookies}
                      >
                        {msg('continue')}
                      </Button>
                      <Button id="more" size="small">
                        <Link to="/cookie-policy">{msg('more')}</Link>
                      </Button>
                    </Space>
                  </Row>
                </Col>
              </Row>
            }
            style={{
              backgroundColor: 'white',
              borderColor: 'black',
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CookieAcceptance;
