import { Button, Modal, Alert } from 'antd';
import React from 'react';
const DeliveryAddressModalAccept = ({
  handleCancel,
  isModalVisible,
  msg,
  handleAccept,
  address,
}) => {
  return (
    <Modal
      title={msg('modal_confirmation.title')}
      open={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <Button onClick={handleCancel} key="cancel">
          {msg('modal_confirmation.cancel')}
        </Button>,
        <Button
          id="button_modal_accept_claim"
          type="primary"
          onClick={handleAccept}
          key="accept"
          data-cy="Acc"
        >
          {msg('modal_confirmation.accept')}
        </Button>,
      ]}
    >
      <p>{msg('modal_confirmation.question')}</p>

      <Alert description={address} type="warning" showIcon />
    </Modal>
  );
};

export default DeliveryAddressModalAccept;
