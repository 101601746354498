import {
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  TimePicker,
  Typography,
} from 'antd';
import FormHelp from 'components/shared/FormHelp';
import React, { Fragment, useState } from 'react';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { dateIsAfterUTC } from 'helpers/dates/dates';

const { Title } = Typography;

function LossDetails({ isPolicyHolder, setIsPolicyHolder, deviceCategory }) {
  const [isLossCoveredYet, setIsLossCoveredYet] = useState(false);
  const detailsMsg = useFormatMsg('claims.declaration.details');
  const isMobile = deviceCategory === 'MOBILE';
  const msg = useFormatMsg('generics');

  return (
    <Fragment>
      <Title level={5}>{detailsMsg('claim_details')}</Title>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="incidentDate"
            label={detailsMsg('LOSS.incident_date.label')}
            rules={[{ required: true, message: detailsMsg('required') }]}
            labelCol={{ span: 24 }}
          >
            <DatePicker
              placeholder={detailsMsg('LOSS.incident_date.placeholder')}
              format="DD/MM/YYYY"
              disabledDate={date => dateIsAfterUTC(date)}
            />
          </Form.Item>
          <Form.Item
            name="DEVICE_LOCATION"
            label={detailsMsg('LOSS.device_location.label')}
            rules={[{ required: true, message: detailsMsg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input
              placeholder={detailsMsg('LOSS.device_location.placeholder')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="THEFT_TIME"
            label={detailsMsg('LOSS.theft_time.label')}
            rules={[{ required: true, message: detailsMsg('required') }]}
            labelCol={{ span: 24 }}
          >
            <TimePicker
              format="HH:mm"
              placeholder={detailsMsg('LOSS.theft_time.placeholder')}
            />
          </Form.Item>
          {isMobile ? (
            <Form.Item
              name="incidentLocation"
              label={detailsMsg('LOSS.incident_location.mobile.label')}
              rules={[{ required: true, message: detailsMsg('required') }]}
              labelCol={{ span: 24 }}
            >
              <Input
                placeholder={detailsMsg(
                  'LOSS.incident_location.mobile.placeholder',
                )}
              />
            </Form.Item>
          ) : (
            <Form.Item
              name="incidentLocation"
              label={detailsMsg('LOSS.incident_location.electronics.label')}
              rules={[{ required: true, message: detailsMsg('required') }]}
              labelCol={{ span: 24 }}
            >
              <Input
                placeholder={detailsMsg(
                  'LOSS.incident_location.electronics.placeholder',
                )}
              />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Form.Item
            name="incidentDescription"
            label={detailsMsg('LOSS.incident_description.label')}
            help={
              <FormHelp>
                ({detailsMsg('LOSS.incident_description.help')})
              </FormHelp>
            }
            rules={[{ required: true, message: detailsMsg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="ANOTHER_EXISTING_INSURANCE"
            label={detailsMsg('LOSS.another_existing_insurance.label')}
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: detailsMsg('required') }]}
          >
            <Radio.Group
              onChange={({ target }) => {
                setIsLossCoveredYet(target.value);
              }}
              className="flex-col"
            >
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="responsible"
            label={detailsMsg('responsibility.label')}
            rules={[{ required: true, message: detailsMsg('required') }]}
            labelCol={{ span: 24 }}
            onChange={({ target }) => {
              setIsPolicyHolder(target.value === 'INSURED');
            }}
          >
            <Radio.Group className="flex-col">
              <Radio value="INSURED">
                {detailsMsg('responsibility.policy_holder')}
              </Radio>
              <Radio value="FAMILY">
                {detailsMsg('responsibility.family_member')}
              </Radio>
              <Radio value="OTHER">{detailsMsg('responsibility.other')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          {isLossCoveredYet && (
            <Form.Item
              name="THEFT_ANOTHER_INSURANCE_NAME"
              label={detailsMsg('LOSS.another_insurance_name.label')}
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: detailsMsg('required') }]}
            >
              <Input
                placeholder={detailsMsg(
                  'LOSS.another_insurance_name.placeholder',
                )}
              />
            </Form.Item>
          )}
          {isLossCoveredYet && (
            <Form.Item
              name="THEFT_ANOTHER_INSURANCE_CLAIMED"
              label={detailsMsg('LOSS.another_insurance_claimed.label')}
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: detailsMsg('required') }]}
            >
              <Radio.Group className="flex-col">
                <Radio value={true}>{msg('yes')}</Radio>
                <Radio value={false}>{msg('no')}</Radio>
              </Radio.Group>
            </Form.Item>
          )}
        </Col>
        {!isPolicyHolder && (
          <Col xs={24} md={12}>
            <Form.Item
              name="THIRD_PARTY"
              label={detailsMsg('third_party.label')}
              rules={[{ required: true, message: detailsMsg('required') }]}
              labelCol={{ span: 24 }}
            >
              <Input.TextArea
                placeholder={detailsMsg('third_party.placeholder')}
              />
            </Form.Item>
            <Form.Item
              name="THIRD_CLAIM_RAISED"
              label={detailsMsg('third_claim_raised.label')}
              rules={[{ required: true, message: detailsMsg('required') }]}
              labelCol={{ span: 24 }}
            >
              <Radio.Group className="flex-col">
                <Radio value={true}>{msg('yes')}</Radio>
                <Radio value={false}>{msg('no')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Fragment>
  );
}
export default LossDetails;
