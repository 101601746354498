import React from 'react';
import { Row, Tag, Tooltip, Typography } from 'antd';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import useUploadModal from 'components/containers/claims/useCases/uploadDocuments/useUploadModal';
import { compareDelay } from 'helpers/dates/dates';

const { Text } = Typography;

export default function StatusViewer({ data }) {
  const context = { policyId: data.policy?.data.id, claimId: data.id };
  const { status, files } = data;
  const msg = useFormatMsg('claims.claim_view');
  const uploadModal = useUploadModal(context);

  const onClickUploadFiles = () => {
    uploadModal();
  };

  const showUploadFiles = () => {
    return (
      status === 30101 &&
      (!files ||
        files?.some(t => t?.attributes?.category !== 'MISSING_INFORMATION'))
    );
  };

  const uploadFiles = () => (
    <Text
      underline
      onClick={onClickUploadFiles}
      style={{ cursor: 'pointer' }}
      data-cy="upload_documents"
      key="uploadfiles"
    >
      {msg('upload_btn')}
    </Text>
  );

  const viewerByStatus = status => {
    const delay = compareDelay(data.updated);

    switch (status) {
      case 20101:
        return [{ color: 'green', status }];
      case 20102:
        return [
          { color: 'green', status: 20101 },
          {
            color: delay ? 'red' : 'green',
            status,
            ...(delay ? { tooltip: msg(`tooltip.${status}`) } : {}),
          },
        ];
      case 20103:
        return [
          { color: 'green', status: 20101 },
          { color: 'green', status: 20102 },
          { color: 'green', status },
        ];
      case 30101:
        return [
          { color: 'green', status: 20101 },
          {
            color: delay ? 'red' : 'yellow',
            status,
            tooltip: msg(`tooltip.${status}`),
          },
          showUploadFiles() && {
            component: uploadFiles(),
          },
        ];
      case 30102:
      case 30103:
      case 30104:
        return [
          { color: 'green', status: 20101 },
          {
            color: delay ? 'red' : 'yellow',
            status,
            tooltip: msg(`tooltip.${status}`),
          },
        ];
      case 50101:
      case 50104:
        return [
          { color: 'green', status: 20101 },
          { color: 'green', status: 20102 },
          {
            color: 'red',
            status,
            tooltip: msg(`rejections.${data.rejection}`),
          },
        ];
      case 50102:
        return [
          { color: 'green', status: 20101 },
          { color: 'green', status: 20102 },
          { color: 'red', status },
        ];
      case 50103:
      case 60101:
        return [
          { color: 'green', status: 20101 },
          { color: 'green', status: 20102 },
          { color: 'default', status },
        ];
      default:
        return [{ color: 'green', status }];
    }
  };

  return (
    <Row gutter={[8, 8]}>
      {viewerByStatus(status).map(s => {
        if (s.tooltip) {
          return (
            <Tooltip title={s.tooltip} key={`${s.status}_${s.color}`}>
              <Tag color={s.color} style={{ cursor: 'pointer' }}>
                {msg(s.status)}
              </Tag>
            </Tooltip>
          );
        }
        if (s.color) {
          return (
            <Tag color={s.color} key={`${s.status}_${s.color}`}>
              {msg(s.status)}
            </Tag>
          );
        }
        if (s.component) {
          return s.component;
        }
        return null;
      })}
    </Row>
  );
}
