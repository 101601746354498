import React from 'react';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { useAppState } from 'services/providers/AppState';
import { useHistory } from 'react-router-dom';

const Cancellation = () => {
  const msg = useFormatMsg('app.header');
  const { partner } = useAppState();
  const history = useHistory();

  if (partner !== 'SA') {
    history.push('/');
  }
  return (
    <a href="https://salt-cancellation.i-con.app/#/salt_cancellation">
      {msg('salt_cancel')}
    </a>
  );
};

export default Cancellation;
