const objToArray = (obj) => {
  let arr = [];
  let currItem = {};
  let index = 0;
  for (let key in obj) {
    const split = key.split('.');
    const prefix = Number(split[0]);
    const currKey = split.slice(1);
    const anotherItem = prefix !== index;
    anotherItem
      ? arr.push({ [currKey]: obj[key] })
      : (arr[index] = {
          ...currItem,
          [currKey]: obj[key],
        });

    currItem = { [currKey]: obj[key] };
    anotherItem && (index += 1);
  }
  return arr;
};

export default objToArray;
