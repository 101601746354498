import React, { useState } from 'react';
import { useClaimContext } from 'services/providers/ClaimDeclaration';
import DamageDetails from './DamageDetails';
import DataRecoveryDetails from './DataRecoveryDetails';
import LossDetails from './LossDetails';
import TheftDetails from './TheftDetails';
import { useAppState } from 'providers/AppState';
import BMJPDamageDetails from './partners/BMJPDamageDetails';
import WarrantyExtensionDetails from './WarrantyExtensionDetails';

function Details() {
  const [alreadyRepaired, setAlreadyRepaired] = useState(false);
  const [isPolicyHolder, setIsPolicyHolder] = useState(true);
  const { claimType, deviceCategory } = useClaimContext();
  const { partner } = useAppState();

  const props = {
    isPolicyHolder,
    setIsPolicyHolder,
    alreadyRepaired,
    setAlreadyRepaired,
    deviceCategory,
  };

  switch (claimType) {
    case 'DAMAGE':
      if (partner === 'BMJP') {
        return <BMJPDamageDetails {...props} />;
      }
      return <DamageDetails {...props} />;
    case 'THEFT':
      return <TheftDetails {...props} />;
    case 'LOSS':
      return <LossDetails {...props} />;
    case 'DATA_RECOVERY':
      return <DataRecoveryDetails {...props} />;
    case 'WARRANTY_EXTENSION':
      return <WarrantyExtensionDetails {...props} />;
    default:
      return null;
  }
}
export default Details;
