import { Input, Select, Divider, Modal, Row } from 'antd';
import { Form, DatePicker, Typography, Button } from 'antd';
import React from 'react';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { useUser } from 'services/providers/user/User';
import countries, { BMCountries } from 'data/countries';
import { useAppState } from 'services/providers/AppState';
import { EditOutlined } from '@ant-design/icons';
import useModal from 'services/hooks/useModal';
import { useApi } from 'services/providers/api/Api';
import { editCustomerMapper } from 'services/providers/api/mappers';
import { date18yearsBefore, dateToDayJs } from 'helpers/dates/dates';

const { Text, Paragraph, Title } = Typography;

const getUserInfo = user => ({
  addressId: user.addressId,
  email: user.email,
  address: user.address,
  birthDate: dateToDayJs(user.birthDate),
  postalCode: user.postalCode,
  addressLocality: user.addressLocality,
  addressCountry: user.addressCountry,
  phone: user.phone,
});

export default function CustomerEditModal() {
  const { putEditCustomer } = useApi();
  const { user, addUserData } = useUser();
  const queryEditCustomer = putEditCustomer(user.customerId);
  const [form] = Form.useForm();
  const { isModalVisible, showModal } = useModal();
  const { partner } = useAppState();
  const msgCountries = useFormatMsg('policies.free_case_request');
  const msg = useFormatMsg('app.overview.modal');
  const required = [{ required: true, message: msg('required') }];

  const editCustomer = payload => {
    queryEditCustomer(editCustomerMapper(payload))
      .then(() => addUserData(payload))
      .finally(showModal);
  };

  return (
    <>
      <Button
        icon={<EditOutlined />}
        onClick={showModal}
        id="button_edit_customer"
      >
        {msg('edit')}
      </Button>
      <Modal
        title={msg('title')}
        open={isModalVisible}
        footer={null}
        onCancel={showModal}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={getUserInfo(user)}
          onFinish={editCustomer}
        >
          <Form.Item name="addressId" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="birthDate" label={msg('birthdate')} rules={required}>
            <DatePicker
              placeholder={'DD/MM/YYYY'}
              autoFocus
              className="fullWidth"
              showToday={false}
              disabledDate={date => date > date18yearsBefore()}
              format="DD/MM/YYYY"
            />
          </Form.Item>

          <Form.Item label={msg('address')} name="address" rules={required}>
            <Input />
          </Form.Item>
          <Form.Item
            label={msg('city')}
            name="addressLocality"
            rules={required}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={msg('postalCode')}
            name="postalCode"
            rules={required}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={msg('country')}
            name="addressCountry"
            rules={required}
          >
            <Select disabled={partner !== 'BM'}>
              {/* TODO RETRIVE COUNTIRES FROM ENDPOINT */}
              {(partner === 'BM' ? BMCountries : countries).map(country => (
                <Select.Option key={country} value={country}>
                  {msgCountries(`countries.${country}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={msg('phone')} name="phone" rules={required}>
            <Input />
          </Form.Item>
          <Form.Item label={msg('email')} name="email" help={msg('email_help')}>
            <Input disabled={true} />
          </Form.Item>
          <Row justify="end">
            <Button style={{ marginRight: '8px' }} onClick={showModal}>
              {msg('cancel')}
            </Button>
            <Button
              type="primary"
              onClick={() => form.submit()}
              id="button_save_customer"
            >
              {msg('save')}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
