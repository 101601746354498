export const valuesToFormDataRD = values => {
  const data = new FormData();
  data.append('purchasePrice', values.purchasePrice);
  data.append('model', values.model);
  data.append('manufacturer', values.manufacturer);
  data.append('serialNumber', values.serialNumber);
  data.append('purchaseDate', values.purchaseDate);
  data.append('subcategory', values.subcategory);
  data.append('status', 30301);
  data.append('category', 'MOBILE');
  if (values.proof_of_purchase?.length) {
    data.append('files[]', values.proof_of_purchase[0].originFileObj);
  }
  return data;
};
