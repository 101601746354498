import { KeyOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Form, Input, Row, Typography, Col, DatePicker } from 'antd';
import AuthLayout from 'components/base/AuthLayout';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useAuthForm from 'hooks/forms/useAuthForm';
import useFormValidation from 'services/hooks/forms/useFormValidation';
import formDataErrors from 'helpers/formValidations/register';
import { isValidPassword } from 'helpers/formValidations/validators';
import { formateDateYYYYMMDD } from 'helpers/dates/formatDate';

const { Paragraph } = Typography;

export default function AuthForm({
  msg,
  onSubmit,
  isLoading,
  emailDisabled = false,
  email,
  phoneRequest = false,
  birthdateRequest = false,
}) {
  const { errors, handleSubmit, clean } = useFormValidation(onSubmit, {
    formDataErrors,
    langPath: 'auth.register',
    phoneRequest,
    birthdateRequest,
  });
  const {
    setEmail,
    setPassword,
    setRepeatedPassword,
    onFormSubmit,
    setPhoneNumber,
    setBirthdate,
  } = useAuthForm(handleSubmit, { email });

  const [form] = Form.useForm();

  useEffect(() => {
    clean();
    setEmail(email);
    form.resetFields();
  }, [email]);

  const actions = (
    <Paragraph type="secondary" style={{ textAlign: 'center' }}>
      {msg('redirect_to_login.text')}
      <Link to="/login"> {msg('redirect_to_login.label')}</Link>
    </Paragraph>
  );

  let registerMessage = msg('caption');
  if (email) {
    registerMessage = msg('caption_from_mail');
  }

  const validatePassword = (e, value) => {
    if (value === '') return Promise.reject(msg('mandatory'));
    return !isValidPassword(value)
      ? Promise.reject(msg('password_help'))
      : Promise.resolve();
  };

  return (
    <AuthLayout
      title={msg('title')}
      caption={registerMessage}
      actions={actions}
      btn={{
        label: msg('button'),
        onClick: onFormSubmit,
        loading: isLoading,
      }}
    >
      <Form initialValues={{ email }} form={form}>
        {phoneRequest && (
          <Row>
            <Col md={6}>
              <Form.Item
                name="prefix"
                validateStatus={errors.prefix?.status}
                help={errors.prefix?.help}
              >
                <Input
                  size="large"
                  placeholder={msg('prefix')}
                  prefix={<PhoneOutlined style={{ marginRight: '12px' }} />}
                  type="text"
                />
              </Form.Item>
            </Col>
            <Col md={18}>
              <Form.Item
                name="phoneNumber"
                validateStatus={errors.phoneNumber?.status}
                help={errors.phoneNumber?.help}
                required
              >
                <Input
                  size="large"
                  placeholder={msg('phoneNumber')}
                  prefix={<PhoneOutlined style={{ marginRight: '12px' }} />}
                  type="text"
                  onChange={event => {
                    setPhoneNumber(event.target.value.trim());
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {birthdateRequest && (
          <Form.Item
            name="birthdate"
            validateStatus={errors.birthdate?.status}
            help={errors.birthdate?.help}
          >
            <DatePicker
              style={{ width: '100%' }}
              size="large"
              placeholder={msg('placeholder.birthdate')}
              format="DD/MM/YYYY"
              onChange={e => setBirthdate(formateDateYYYYMMDD(e))}
            />
          </Form.Item>
        )}
        <Form.Item
          name="email"
          validateStatus={errors.email?.status}
          help={errors.email?.help}
        >
          <Input
            size="large"
            disabled={emailDisabled}
            placeholder={msg('email')}
            prefix={<MailOutlined style={{ marginRight: '12px' }} />}
            type="text"
            onChange={event => {
              setEmail(event.target.value.trim());
            }}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              validator: validatePassword,
            },
          ]}
        >
          <Input.Password
            size="large"
            placeholder={msg('password')}
            prefix={<KeyOutlined style={{ marginRight: '12px' }} />}
            visibilityToggle
            onChange={event => {
              setPassword(event.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          name="repeatedPassword"
          validateStatus={errors.repeatedPassword?.status}
          help={errors.repeatedPassword?.help}
        >
          <Input.Password
            size="large"
            placeholder={msg('repeatpassword')}
            prefix={<KeyOutlined style={{ marginRight: '12px' }} />}
            visibilityToggle
            onChange={event => {
              setRepeatedPassword(event.target.value);
            }}
          />
        </Form.Item>
      </Form>
    </AuthLayout>
  );
}
