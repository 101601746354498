import * as Sentry from '@sentry/react';

const getEnviroment = () => {
  const { hostname } = window.location;
  if (
    [
      'backmarket.bolttech.eu',
      'backmarket-uk.bolttech.eu',
      'galaxus.bolttech.eu',
      'refurbed.bolttech.eu',
      'backmarket.bolttech.jp',
      'mobile.monacocare.mc',
      'www.mobile.monacocare.mc',
      'caresupport.ch',
      'www.caresupport.ch',
      'fnaccare.ch',
      'yalloprotect360.ch',
      'microspot-careplus.ch',
    ].includes(hostname)
  ) {
    return 'production';
  } else if (
    [
      'my-customer-space-preproduction-bm.i-con.app',
      'my-customer-space-preproduction-bmuk.i-con.app',
      'my-customer-space-preproduction-fa.i-con.app',
      'my-customer-space-preproduction-mi.i-con.app',
      'my-customer-space-preproduction-rf.i-con.app',
      'my-customer-space-preproduction-mo.i-con.app',
      'my-customer-space-preproduction-ya.i-con.app',
      'my-customer-space-preproduction-di.i-con.app',
      'my-customer-space-preproduction-dich.i-con.app',
      'my-customer-space-preproduction-sa.i-con.app',
    ].includes(hostname)
  ) {
    return 'preproduction';
  } else {
    return 'staging';
  }
};

Sentry.init({
  dsn: 'https://97f61ac65e42813d6eced552e6710997@o1271757.ingest.us.sentry.io/4507656696365056',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: getEnviroment(),
  // Performance Monitoring
  tracesSampleRate: 0.3, //  Capture 30% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/backmarket\.bolttech\.eu/,
    /^https:\/\/backmarket-uk\.bolttech\.eu/,
    /^https:\/\/galaxus\.bolttech\.eu/,
    /^https:\/\/refurbed\.bolttech\.eu/,
    /^https:\/\/backmarket\.bolttech\.jp/,
    /^https:\/\/mobile\.monacocare\.mc/,
    /^https:\/\/www\.mobile\.monacocare\.mc/,
    /^https:\/\/caresupport\.ch/,
    /^https:\/\/www\.caresupport\.ch/,
    /^https:\/\/fnaccare\.ch/,
    /^https:\/\/yalloprotect360\.ch/,
    /^https:\/\/microspot-careplus\.ch/,
    /^https:\/\/my-customer-space-preproduction-bm\.i-con\.app/,
    /^https:\/\/my-customer-space-preproduction-bmuk\.i-con\.app/,
    /^https:\/\/my-customer-space-preproduction-fa\.i-con\.app/,
    /^https:\/\/my-customer-space-preproduction-mi\.i-con\.app/,
    /^https:\/\/my-customer-space-preproduction-mo\.i-con\.app/,
    /^https:\/\/my-customer-space-preproduction-ya\.i-con\.app/,
    /^https:\/\/my-customer-space-preproduction-di\.i-con\.app/,
    /^https:\/\/my-customer-space-preproduction-dich\.i-con\.app/,
    /^https:\/\/my-customer-space-preproduction-rf\.i-con\.app/,
    /^https:\/\/my-customer-space-preproduction-sa\.i-con\.app/,
    /^https:\/\/my-customer-space-staging-bm\.i-con\.app/,
    /^https:\/\/my-customer-space-staging-bmuk\.i-con\.app/,
    /^https:\/\/my-customer-space-staging-fa\.i-con\.app/,
    /^https:\/\/my-customer-space-staging-mi\.i-con\.app/,
    /^https:\/\/my-customer-space-staging-mo\.i-con\.app/,
    /^https:\/\/my-customer-space-staging-ya\.i-con\.app/,
    /^https:\/\/my-customer-space-staging-di\.i-con\.app/,
    /^https:\/\/my-customer-space-staging-dich\.i-con\.app/,
    /^https:\/\/my-customer-space-staging-rf\.i-con\.app/,
    /^https:\/\/my-customer-space-staging-sa\.i-con\.app/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 0% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.3, // If you're not already sampling the entire session, change the sample rate to 30% when sampling sessions where errors occur.
});
