import useTrackingInfo from 'services/hooks/useTrackingInfo';
import { Button, Modal, Col, Row } from 'antd';
import useModal from 'services/hooks/useModal';
import useFormatMsg from 'hooks/translations/useFormatMsg';

export default function TrackingWO({ woId }) {
  const { showModal, isModalVisible } = useModal();
  const { trackingInfo } = useTrackingInfo(woId);
  const msg = useFormatMsg('claims.claim_view.tracking_view');

  if (!trackingInfo || trackingInfo.length === 0) {
    return null;
  }
  return (
    <>
      <Button size="small" onClick={showModal} style={{ marginLeft: '16px' }}>
        {msg('delivery')}
      </Button>
      <Modal
        open={isModalVisible}
        footer={null}
        title={woId}
        onCancel={showModal}
      >
        {trackingInfo.map(ti => (
          <Row
            style={{ marginBottom: '16px' }}
            key={`${ti.id}_${ti.is_return}`}
          >
            <Col>
              <Row>
                <b>{msg(`status_${ti.is_return ? 'return' : 'not_return'}`)}</b>
              </Row>
              <Row>{ti.status}</Row>
              <Row>
                <b>{msg(`link_${ti.is_return ? 'return' : 'not_return'}`)}</b>
              </Row>
              <Row>
                <a href={ti.tracking_link} target="_blank" rel="noopener">
                  {msg('click')}
                </a>
              </Row>
            </Col>
          </Row>
        ))}
      </Modal>
    </>
  );
}
