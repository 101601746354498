import { Divider, Typography } from 'antd';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { Fragment } from 'react';
import VoucherInfo from './VoucherInfo';

const { Title } = Typography;

export default function VoucherDetails({ vouchers }) {
  if (!vouchers.length) return null;
  const msg = useFormatMsg('claims.claim_view.voucher_details');
  return (
    <>
      <Title level={5}>{msg('title')}</Title>
      {vouchers.map((voucher, i) => (
        <Fragment key={voucher.id}>
          <VoucherInfo voucher={voucher} />
          {vouchers.length > i && <Divider />}
        </Fragment>
      ))}
    </>
  );
}
