import { Modal } from 'antd';

function modalMsg({ error, msg, onOk }) {
  const modalMsgPath = error ? `onError.${error.status}` : `onSuccess`;
  Modal[error ? 'error' : 'success']({
    title: msg(`${modalMsgPath}.title`),
    content: msg(`${modalMsgPath}.description`),
    okText: msg(`${modalMsgPath}.btn.label`),
    onOk,
  });
}
export default modalMsg;
