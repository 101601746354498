export class CustomError extends Error {
  constructor(error) {
    super(error.message)
    this.detail = error.response?.data?.error?.detail
    this.source = error.response?.data?.error?.source
    this.title = error.response?.data?.error?.title
    this.description = this.source?.trace?.error_description
    this.status = error.response?.status
    this.request = error.request
    this.config = error.config
  }
}
