import { Modal } from 'antd';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import React from 'react';
import { useApi } from 'services/providers/api/Api';
import { ModalContext } from './ModalContext';
import UploadModalContent from './UploadModalContent';

export default function useUploadModal(context) {
  const msg = useFormatMsg('claims.upload_documents.upload');
  const api = useApi();

  const content = (
    <ModalContext.Provider value={{ api, context, msg }}>
      <UploadModalContent />
    </ModalContext.Provider>
  );

  return () => {
    Modal.info({
      // closable: true,
      //closeIcon: <CloseOutlined />,
      content,
      width: 'max-content',
      centered: true,
      maskClosable: true,
      okButtonProps: { ghost: true },
      okText: msg('ok_btn.label'),
    });
  };
}
