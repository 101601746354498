import appConfig from 'app.config';
import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import { useUser } from 'providers/user/User';
import { useAppState } from 'providers/AppState';
import Spinner from 'components/shared/Spinner';

export default function PrivateRoute({ children, ...props }) {
  const { user } = useUser();
  const { isLoading, partner } = useAppState();
  const { keyLoginConfig } = appConfig[partner];
  const hasActivationKey = !!user[keyLoginConfig];
  const isVerifiedUser = !!user.emailVerified;
  const render = ({ location }) => {
    if (isLoading) {
      return <Spinner />;
    } else {
      if (user.isLogged) {
        if (!isVerifiedUser) {
          return <Redirect to="/verify" />;
        } else if (!hasActivationKey) {
          return <Redirect to="/birthdate" />;
        } else {
          return children;
        }
      } else {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }
    }
  };

  return <Route {...props} render={render} />;
}
