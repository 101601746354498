import React from 'react';
import { Typography } from 'antd';
const { Text } = Typography;

export default function FormHelp({ children }) {
  return (
    <Text type="secondary" style={{ lineHeight: '1em', fontSize: '80%' }}>
      {children}
    </Text>
  );
}
