import appConfig from 'app.config';
import { openNotification } from 'helpers/Notifications';
import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useApi } from 'services/providers/api/Api';
import { useAppState } from 'services/providers/AppState';
import { useUser } from 'services/providers/user/User';
import BirthdateForm from './BirthdateForm';

function Birthdate() {
  const { partner } = useAppState();
  const { user, addUserData } = useUser();
  const history = useHistory();
  const { putEditCustomer } = useApi();
  const keyConfig = appConfig[partner].keyLoginConfig;
  const query = putEditCustomer(user.customerId);

  const sendBirthdate = birthdate => {
    query(birthdate)
      .then(resp => {
        addUserData({
          [keyConfig]:
            resp.data[keyConfig] || resp.data.data.attributes[keyConfig],
        });
        history.push('/dashboard');
      })
      .catch(error => {
        openNotification({
          message: `${error.title}: Unable to login`,
          description: error.description,
          type: 'error',
        });
      });
  };

  return user[keyConfig] ? (
    <Redirect to="/dashboard" />
  ) : (
    <BirthdateForm onSubmit={sendBirthdate} />
  );
}

export default Birthdate;
