import { Tag } from 'antd';
import React from 'react';

export default function StatusTag({ color, children, style, ...props }) {
  const customStyle = {
    ...style,
    //color: '#52C41A',
  };
  return (
    <Tag color={color} style={customStyle} {...props}>
      {children}
    </Tag>
  );
}
