import {
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  TreeSelect,
  Typography,
} from 'antd';
import FormHelp from 'components/shared/FormHelp';
import { dateIsAfterUTC } from 'helpers/dates/dates';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import React, { Fragment, useState } from 'react';
import { useAppState } from 'services/providers/AppState';

const { Title } = Typography;

function DamageDetails({
  isPolicyHolder,
  setIsPolicyHolder,
  alreadyRepaired,
  setAlreadyRepaired,
}) {
  const { messages } = useAppState();
  const [damageDateKnow, setDamageDateKnow] = useState(true);
  const [otherCategory, setOtherCategory] = useState(false);
  const onChangeCategory = category =>
    category === 'DAMAGED_OTHER'
      ? setOtherCategory(true)
      : setOtherCategory(false);
  const detailsMsg = useFormatMsg('claims.declaration.details');
  const msg = useFormatMsg('generics');
  const { incidentCategories, incidentTypes } =
    messages.claims.declaration.details.DAMAGE;

  const requiredRules = [
    {
      required: true,
      message: detailsMsg('required'),
    },
  ];

  return (
    <Fragment>
      <Title level={5}>{detailsMsg('claim_details')}</Title>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            label={detailsMsg('DAMAGE.damageDateKnow.label')}
            name="DAMAGE_DATE_KNOW"
            rules={requiredRules}
            labelCol={{ span: 24 }}
            onChange={() => {
              setDamageDateKnow(!damageDateKnow);
            }}
          >
            <Radio.Group className="flex-col cap-all">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="incidentCategory"
            label={detailsMsg('DAMAGE.incident_category.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <TreeSelect
              placeholder={detailsMsg('DAMAGE.incident_category.placeholder')}
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={incidentCategories}
              treeDefaultExpandAll
              onChange={onChangeCategory}
            />
          </Form.Item>
          {otherCategory && (
            <Form.Item
              name="specify"
              label={detailsMsg('DAMAGE.other_category.label')}
              rules={requiredRules}
              labelCol={{ span: 24 }}
            >
              <Input
                placeholder={detailsMsg('DAMAGE.other_category.placeholder')}
              />
            </Form.Item>
          )}
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="incidentDate"
            label={
              !damageDateKnow
                ? detailsMsg('incident_date.label.1')
                : detailsMsg('incident_date.label.2')
            }
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <DatePicker
              placeholder={detailsMsg('incident_date.placeholder')}
              format="DD/MM/YYYY"
              disabledDate={dateIsAfterUTC}
            />
          </Form.Item>
          <Form.Item
            name="incidentType"
            label={detailsMsg('incident_type.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <TreeSelect
              placeholder={detailsMsg('incident_type.placeholder')}
              treeCheckable
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={incidentTypes}
              showArrow
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="incidentDescription"
            label={detailsMsg('DAMAGE.incident_description.label')}
            help={
              <FormHelp>
                ({detailsMsg('DAMAGE.incident_description.help')})
              </FormHelp>
            }
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input.TextArea
              placeholder={detailsMsg(
                'DAMAGE.incident_description.placeholder',
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row
        justify="space-between"
        style={{ marginTop: '1rem' }}
        gutter={[16, 16]}
      >
        <Col xs={24} md={12}>
          <Form.Item
            name="responsible"
            label={detailsMsg('responsibility.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
            onChange={({ target }) => {
              setIsPolicyHolder(target.value === 'INSURED');
            }}
          >
            <Radio.Group className="flex-col">
              <Radio value="INSURED">
                {detailsMsg('responsibility.policy_holder')}
              </Radio>
              <Radio value="FAMILY">
                {detailsMsg('responsibility.family_member')}
              </Radio>
              <Radio value="OTHER">{detailsMsg('responsibility.other')}</Radio>
            </Radio.Group>
          </Form.Item>
          {!isPolicyHolder && (
            <Form.Item
              name="THIRD_PARTY"
              label={detailsMsg('third_party.label')}
              rules={requiredRules}
              labelCol={{ span: 24 }}
            >
              <Input.TextArea
                placeholder={detailsMsg('third_party.placeholder')}
              />
            </Form.Item>
          )}
          {!isPolicyHolder && (
            <Form.Item
              name="OWNERRELATIONSHIP"
              label={detailsMsg('owner_relationship')}
              rules={requiredRules}
              labelCol={{ span: 24 }}
            >
              <Input.TextArea />
            </Form.Item>
          )}
          {!isPolicyHolder && (
            <Form.Item
              name="THIRD_CLAIM_RAISED"
              label={detailsMsg('third_claim_raised.label')}
              rules={requiredRules}
              labelCol={{ span: 24 }}
            >
              <Radio.Group className="flex-col">
                <Radio value={true}>{msg('yes')}</Radio>
                <Radio value={false}>{msg('no')}</Radio>
              </Radio.Group>
            </Form.Item>
          )}
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="ALL_DAMAGES"
            label={detailsMsg('DAMAGE.incident_only.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Title style={{ marginTop: '2rem' }} level={5}>
        {detailsMsg('DAMAGE.section')}
      </Title>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="WATER_EXPOSED"
            label={detailsMsg('DAMAGE.water_exposed.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="HAVE_DEVICE"
            label={detailsMsg('DAMAGE.have_device.label')}
            labelCol={{ span: 24 }}
            rules={[{ required: false }]}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="VISIBLE"
            label={detailsMsg('DAMAGE.visible.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="ALREADY_REPAIRED"
            label={detailsMsg('DAMAGE.already_repaired.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
            onChange={() => {
              setAlreadyRepaired(!alreadyRepaired);
            }}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Form.Item>
          {alreadyRepaired && (
            <Form.Item
              name="DEVICE_REPAIRED_AT"
              label={detailsMsg('DAMAGE.device_repaired_at.label')}
              labelCol={{ span: 24 }}
              rules={requiredRules}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name="USABLE"
            label={detailsMsg('DAMAGE.usable.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="BACKSIDE"
            label={detailsMsg('DAMAGE.backSide.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  );
}
export default DamageDetails;
