import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, Typography } from 'antd';
import { useClaimContext } from 'providers/ClaimDeclaration';
import React from 'react';
import useFormatMsg from 'hooks/translations/useFormatMsg';

const { Paragraph } = Typography;
export default function Uploader(props) {
  const msg = useFormatMsg('claims.declaration.device');
  const { setPolicyFiles } = useClaimContext();

  const onChangeFileList = file => {
    setPolicyFiles(file.fileList);
  };
  const itemRender = (originNode, file, currFileList) => {
    return <Paragraph>{file.name}</Paragraph>;
  };
  return (
    <Upload
      {...props}
      beforeUpload={() => false}
      onChange={onChangeFileList}
      //itemRender={itemRender}
    >
      <Button icon={<UploadOutlined />}>
        {props.msg || msg('uploadinvoice')}
      </Button>
    </Upload>
  );
}
