import { useEffect, useState } from 'react';
import useFormatMsg from 'hooks/translations/useFormatMsg';

function useFormValidation(
  onSubmit,
  { formDataErrors, langPath, phoneRequest, birthdateRequest },
) {
  const [errors, setErrors] = useState({});
  const msg = useFormatMsg(langPath);

  const handleSubmit = data => {
    const errors = formDataErrors(data, msg, phoneRequest, birthdateRequest);
    errors.any ? setErrors(errors) : onSubmit(data);
  };
  const clean = () => setErrors({});
  useEffect(() => {
    return clean;
  }, []);

  return { setErrors, clean, errors, handleSubmit };
}

export default useFormValidation;
