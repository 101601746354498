import styled from 'styled-components'

export const CardWrapper = styled('div')`
  margin-bottom: 2rem;
  & > .ant-typography {
    margin-bottom: 1rem;
  }
`
export const cssIcon = {
  marginRight: '1rem',
  fontSize: '22px',
  color: 'var(--primary)',
}
export const Card = styled('div')`
  max-width: 20rem;
`
export const Space = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`
