const theft = [
  'incidentDate',
  'DEVICE_LOCATION',
  'THEFT_TIME',
  'incidentDescription',
  'incidentLocation',
  'incidentCountry',
  'ANOTHER_EXISTING_INSURANCE',
  'THEFT_RECOGNISED',
  'incidentCategory',
  'LOCKED_SIM',
  'POLICE_REPORT_FILLED',
  'POLICE_REPORT_DATE',
  'POLICE_REPORT_STATION',
  'POLICE_REPORT_REFERENCE',
  'POLICE_REPORT_IMEI',
  'POTENTIALLY_COVERED',
  'responsible',
  'THEFT_ANOTHER_INSURANCE_NAME',
  'THEFT_ANOTHER_INSURANCE_CLAIMED',
  'THIRD_PARTY',
  'THIRD_CLAIM_RAISED',
  'OWNERRELATIONSHIP',
  'SIM_CARD_BLOCKED',
  'MENTION_DATE_SIM_BLOCKED',
  'FRAUDULENT_USE',
];

const loss = [
  'incidentDate',
  'DEVICE_LOCATION',
  'THEFT_TIME',
  'incidentDescription',
  'incidentLocation',
  'incidentCountry',
  'ANOTHER_EXISTING_INSURANCE',
  'THEFT_RECOGNISED',
  'LOCKED_SIM',
  'POLICE_REPORT_FILLED',
  'POLICE_REPORT_DATE',
  'POLICE_REPORT_STATION',
  'POLICE_REPORT_REFERENCE',
  'POLICE_REPORT_IMEI',
  'POTENTIALLY_COVERED',
  'responsible',
  'THEFT_ANOTHER_INSURANCE_NAME',
  'THEFT_ANOTHER_INSURANCE_CLAIMED',
  'THIRD_PARTY',
  'THIRD_CLAIM_RAISED',
  'OWNERRELATIONSHIP',
  'SIM_CARD_BLOCKED',
  'MENTION_DATE_SIM_BLOCKED',
  'FRAUDULENT_USE',
];

const damage = [
  'DAMAGE_DATE_KNOW',
  'incidentDate',
  'incidentLocation',
  'incidentCountry',
  'incidentDescription',
  'responsible',
  'THIRD_PARTY',
  'ALREADY_REPAIRED',
  'DEVICE_REPAIRED_AT',
  'HAVE_DEVICE',
  'THIRD_CLAIM_RAISED',
  'OWNERRELATIONSHIP',
  'ALL_DAMAGES',
  'USABLE',
  'WATER_EXPOSED',
  'BACKSIDE',
  'VISIBLE',
  'damageDescription',
  'incidentCategory',
  'damages',
  'CASHLESS_REPAIR',
  'REPAIR_COST',
];

const dataRecovery = ['incidentDate', 'INCIDENT_TIME'];

const warrantyExtension = [
  'DAMAGE_DATE_KNOW',
  'incidentDate',
  'incidentLocation',
  'incidentDescription',
  'responsible',
  'damageDescription',
  'FRAUDULENT_USE',
  'WATER_EXPOSED',
  'OTHER_CLAIM',
  'TOUCHSCREEN_REACT',
  'USED',
  'SWITCH_ON',
  'ALL_DAMAGES',
];

export const listOfDetails = {
  DAMAGE: damage,
  THEFT: theft,
  LOSS: loss,
  DATA_RECOVERY: dataRecovery,
  WARRANTY_EXTENSION: warrantyExtension,
};

export const listOfInsuredItemDetails = [
  'category',
  'subcategory',
  'manufacturer',
  'model',
  'capacity',
  'color',
  'purchaseDate',
  'serialNumber',
];
