import flatObject from 'helpers/flatObject';

export const defaultTranslations = flatObject({
  app: {
    landing: {
      BACKMARKET: {
        how_works_title1: 'Register Mail',
        caption: 'Manage your device insurance requests',
        how_works_title2: 'Login',
        how_works_title3: 'Policy features',
        how_works_title4: 'Claim handling',
        verificated: 'Verificated',
        how_works: 'How it works',
        processing: 'Processing',
        claim_status: 'Claim status',
        how_works_text1:
          'Registration enables you to have access to our platform, which is a very simple and fast solution to benefit from your coverage, you can follow your claim declaration status and have a summary of your coverage uses.',
        customer_portal_advantage1:
          'Declare your claim in case of incident in a few easy steps and we will take care of the rest!',
        resolved: 'Resolved',
        how_works_text2:
          'Logging to your account allows you to fully benefit of your membership and enjoy your coverage features.',
        customer_portal_advantage2:
          'Always stay up-to-date and have an overview over your requests.',
        how_works_text3:
          '<ul><li>It covers refurbished mobile devices against everyday risks such as breakage, rust or theft.</li><li>It also covers accidental damage or damage due to negligence.</li><li>It is part of a broad social and environmental responsibility program.</li></ul>',
        declared: 'Declared',
        customer_portal_advantage3:
          'Get access to extra features included in your policy.',
        customer_portal_advantages: 'Advantages of the customer portal',
        how_works_text4:
          '<ul><li>Claims can be submitted 24/7 via our customer portal, a dedicated team will assist you to resolve the claim.</li><li>A certified local repair partner is available to make the process easy for you and you will have the option to choose a specialized technical center.</li><li>A voucher for a replacement device on the Back Market site can be offered upon to diagnosis.</li></ul>',
        btn: {
          login: 'Login',
          register: 'Register',
        },
        description:
          'Acces to your policy features and declare claims fast and easy 24 / 7 online in our customer portal.',
        customer: 'Back Market',
      },
    },
    footer: {
      faq: 'FAQ',
      email: 'Email',
      contact: 'Contact',
      emailto: 'insurance.bm@i-surance.eu',
      imprint: 'Imprint',
      contact_label: 'You can contact our customer care team via email:',
      cookie_policy: 'Cookie policy',
      privacy_policy: 'Privacy policy',
      imprintbolttech:
        'i-surance is now part of the bolttech Group, a fast-growing international insurtech. This means your personal data will be shared with parts of bolttech that are located outside the EU (notably in Hong Kong and Malaysia). Rest assured that: will only ever share personal data with third parties where necessary, all our third-party service providers are required to demonstrate that they have appropriate security measures in place to protect your personal information in line with our existing policies and measures and that they will respect the security of your data and treat it in accordance with the law. We only permit third parties to process your personal data for specified purposes and in accordance with our instructions – they are not allowed to use it for their own purposes. Your personal data is protected even when it needs to be shared outside of the EU because we have put measures in place to safeguard its treatment in a way that is consistent with EU laws on data protection such as the General Data Protection Regulation (GDPR). If you have any queries or concerns, please feel free to reach out to <a href="mailto:privacy@bolttech.eu">privacy@bolttech.eu</a>',
    },
  },
});
