import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const compareDelay = date =>
  dayjs(date).isSameOrBefore(dayjs().subtract(3, 'day'));

export const returnDayUTC = () =>
  dayjs()
    .utc()
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

export const returnUTC = () => dayjs().utcOffset(0, true).format();

export const dateIsAfterUTC = date =>
  dayjs(date).isSameOrAfter(returnDayUTC(), 'day');

export const dateToDayJs = date => (date ? dayjs(date) : dayjs());

export const date18yearsBefore = () => dayjs().subtract(18, 'years');

export const dateAdd = (date, amount, unit) => dayjs(date).add(amount, unit);

export const setLocaleFn = (langs, tLocale) =>
  dayjs.locale(langs.includes(tLocale) ? tLocale : 'en');
