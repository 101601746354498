import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Layout,
  Row,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import Uploader from 'components/containers/claims/useCases/uploadDocuments/Uploader';
import { valuesToFormDataRD } from 'helpers/formValidations/deviceRegister';

import { isValidIMEI } from 'helpers/formValidations/validators';
import modalMsg from 'helpers/messages/modalMsg';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import { useApi } from 'services/providers/api/Api';

const { Title } = Typography;
const { Item, useForm } = Form;

export default function FullDeviceRegistration({}) {
  const [loading, setLoading] = useState(false);
  const { policyId } = useParams();
  const msg = useFormatMsg('policies.register_device');
  const [form] = useForm();

  const history = useHistory();

  const { createInsuredItem } = useApi();

  const validateIMEIorSerialNumber = (e, value) => {
    if (value === '') return Promise.reject(msg('mandatory'));
    return form.getFieldValue('category') === 'MOBILE' && !isValidIMEI(value)
      ? Promise.reject(msg('helpimei'))
      : Promise.resolve();
  };

  const requiredLabel = [{ required: true, message: msg('required') }];
  const requiredImei = [
    {
      required: true,
      validator: validateIMEIorSerialNumber,
    },
  ];

  const onSubmitRequestCreate = payload => {
    setLoading(true);
    createInsuredItem(policyId, valuesToFormDataRD(payload))
      .then(resp => {
        modalMsg({ onOk: () => history.push('/dashboard'), msg });
      })
      .catch(() =>
        modalMsg({
          error: { status: 400 },
          onOk: () => history.push('/dashboard'),
          msg,
        }),
      )
      .finally(() => setLoading(false));
  };

  const checkUpload = (e, value) => {
    return value?.length > 0
      ? Promise.resolve()
      : Promise.reject(msg('required'));
  };

  return (
    <Form
      onFinish={onSubmitRequestCreate}
      initialValues={{ category: 'MOBILE' }}
      form={form}
      style={{ width: '100%' }}
    >
      <Layout>
        <Title level={2} style={{ textAlign: 'center', marginBottom: '2rem' }}>
          {msg('title')}
        </Title>
        <Row>
          <Title level={5}>{msg('subtitle')}</Title>
        </Row>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Item
              name="subcategory"
              label={msg('inputs.subcategory')}
              rules={requiredLabel}
              labelCol={{ span: 24 }}
            >
              <Select
                options={[
                  { label: msg('TABLET'), value: 'TABLET' },
                  { label: msg('SMARTPHONE'), value: 'SMARTPHONE' },
                ]}
              />
            </Item>
          </Col>
          <Col xs={24} md={6}>
            <Item
              name="purchaseDate"
              label={msg('inputs.purchaseDate')}
              rules={requiredLabel}
              labelCol={{ span: 24 }}
            >
              <DatePicker />
            </Item>
          </Col>
          <Col xs={24} md={6}>
            <Item
              name="purchasePrice"
              label={msg('inputs.purchasePrice')}
              rules={requiredLabel}
              labelCol={{ span: 24 }}
            >
              <InputNumber min={0} />
            </Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Item
              name="manufacturer"
              label={msg('inputs.manufacturer')}
              rules={requiredLabel}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Item>
          </Col>
          <Col xs={24} md={12}>
            <Item
              name="model"
              label={msg('inputs.model')}
              rules={requiredLabel}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Item
              name="serialNumber"
              label={
                <div>
                  <Tooltip title={<small>{msg(`tooltip`)}</small>}>
                    {msg('inputs.serialNumber')}
                    <QuestionCircleOutlined style={{ marginLeft: '8px' }} />
                  </Tooltip>
                </div>
              }
              rules={requiredImei}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Item>
          </Col>
          <Col xs={24} md={12}>
            <Item
              label={msg('inputs.proof_of_purchase')}
              name="proof_of_purchase"
              rules={[
                {
                  required: true,
                  validator: checkUpload,
                },
              ]}
              getValueFromEvent={e => {
                if (Array.isArray(e)) {
                  return e;
                }
                return e && e.fileList;
              }}
              labelCol={{ span: 24 }}
              valuePropName="fileList"
            >
              <Uploader
                name="proof_of_purchase"
                listType="text"
                accept="application/pdf,image/png,image/jpg,image/jpeg"
                maxFiles={1}
                beforeUpload={() => false}
                msg={msg('upload_proof_of_purchase')}
              />
            </Item>
          </Col>
        </Row>
        <Layout.Footer style={{ backgroundColor: 'transparent' }}>
          <Button.Group style={{ width: '100%' }}>
            <Button
              data-cy="deviceRegistrationBack"
              type="primary"
              ghost
              onClick={() => history.push('/dashboard')}
            >
              {msg('actions.cancel')}
            </Button>
            <Button
              data-cy="deviceRegistrationSubmit"
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 'auto' }}
              loading={loading}
            >
              {msg('actions.submit')}
            </Button>
          </Button.Group>
        </Layout.Footer>
      </Layout>
    </Form>
  );
}
