import { useState } from 'react';
import { useUser } from 'services/providers/user/User';
import countries from 'data/countries';

function useInitialFormData() {
  const { user } = useUser();
  const initialData = {
    city: user.addressLocality,
    country: countries.includes(user.addressCountry)
      ? user.addressCountry
      : undefined,
    firstName: user.firstName,
    lastName: user.lastName,
    postCode: user.postalCode,
    contactEmail: user.email,
    contactPhone: user.phone,
    streetAddress: user.address,
    salutation: user.salutation,
  };
  const [formData, setFormData] = useState(initialData);
  return { formData, setFormData };
}

export default useInitialFormData;
