const reduceFormToContextData = (data, contextKeys) => {
  const initial = {
    claimContext: [],
  };
  const reducedData = Object.keys(data).reduce((acc, current) => {
    const isContextData = contextKeys.includes(current);

    const rest =
      current === 'incidentType'
        ? { damages: data.incidentType.map(type => ({ category: type })) }
        : { [current]: data[current] };

    let newData = isContextData
      ? {
          ...acc,
          claimContext: [
            ...acc.claimContext,
            {
              question: current,
              value: data[current],
              varType:
                typeof data[current] === 'string'
                  ? 'text'
                  : typeof data[current],
            },
          ],
        }
      : {
          ...acc,
          ...rest,
        };

    return newData;
  }, initial);

  return reducedData;
};
export default reduceFormToContextData;
