import React from 'react';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { Modal } from 'antd';
import PhonecaseModalContent from 'components/containers/policies/useCases/phoneCaseOrder/PhonecaseModalContent';
import { CloseOutlined } from '@ant-design/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';

export default function usePhonecaseModal(policyId) {
  const msg = useFormatMsg('policies.free_case_request.modal');
  const history = useHistory();
  const { path } = useRouteMatch();

  const onOk = () => {
    history.push(`${path}/phone_case/${policyId}/device`);
  };

  return () => {
    Modal.info({
      closable: true,
      closeIcon: <CloseOutlined />,
      content: <PhonecaseModalContent msg={msg} />,
      width: 'max-content',
      centered: true,
      footer: null,
      maskClosable: true,
      onOk: onOk,
      okText: msg('button'),
    });
  };
}
