import { useState, useEffect } from 'react';
import { useApi } from 'services/providers/api/Api';

export default function useTrackingInfo(workOrderId) {
  const [trackingInfo, setTrackingInfo] = useState([]);
  const [loadingTrackingInfo, setLoadingTrackingInfo] = useState(true);
  const { getWOTrackingInfo } = useApi();

  const fetchTrackingInfo = id => {
    getWOTrackingInfo(id)
      .then(res => setTrackingInfo(res.data))
      .finally(() => setLoadingTrackingInfo(false));
  };

  useEffect(() => {
    if (workOrderId) {
      fetchTrackingInfo(workOrderId);
    }
  }, [workOrderId]);

  return {
    trackingInfo,
    loadingTrackingInfo,
  };
}
