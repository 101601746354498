import { Typography, Row, Space } from 'antd';
import { formateDateDDMMYYYYandAdd } from 'helpers/dates/formatDate';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import styled from 'styled-components';

const { Text } = Typography;

export default function VoucherInfo({ voucher }) {
  const msg = useFormatMsg('claims.claim_view.voucher_details');

  return (
    <Space direction="vertical" size="small">
      <Row>
        <MarginText>{msg('number')}</MarginText>
        <Text>{voucher.id}</Text>
      </Row>
      <Row>
        <MarginText>{msg('amount')}</MarginText>
        <Text>{voucher.amount}</Text>
      </Row>
      <Row>
        <MarginText>{msg('expiration_date')}</MarginText>
        <Text>
          {formateDateDDMMYYYYandAdd(voucher.updated, {
            number: '6',
            unit: 'M',
          })}
        </Text>
      </Row>
    </Space>
  );
}

const MarginText = styled(Text)`
  margin-right: 4px;
`;
