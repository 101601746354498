import { LogoutOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import appConfig from 'app.config';
import { useAppState } from 'providers/AppState';
import { useUser } from 'providers/user/User';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Select } from 'antd';
import styled from 'styled-components';
import { reloadOTBanner } from 'helpers/seo';

const { Option } = Select;

function Extra({ langs, logout }) {
  const { setLocale, locale } = useAppState();
  const { user } = useUser();

  const onChangeLange = lang => {
    if (lang !== locale) {
      setLocale(lang);
      localStorage.setItem('currLang', lang);
      document.documentElement.lang = lang;
      reloadOTBanner();
    }
  };

  useEffect(() => {
    const currLang = localStorage.getItem('currLang');
    onChangeLange(currLang || locale);
  }, []);

  return (
    <Space>
      <Select
        className={'lang-selector'}
        defaultValue={locale.toUpperCase()}
        style={{ width: 120 }}
        onChange={onChangeLange}
        value={locale}
      >
        {langs.map(lang => (
          <Option key={lang} value={lang} id={`option_language_${lang}`}>
            {lang.toUpperCase()}
          </Option>
        ))}
      </Select>
      {user.isLogged && (
        <LogoutOutlined
          onClick={logout}
          style={{
            color: 'var(--primary)',
            marginLeft: '.3rem',
            fontSize: '1.2rem',
          }}
        />
      )}
    </Space>
  );
}
function Header({ logo }) {
  const { logout, user } = useUser();
  const { langs } = useAppState();
  const history = useHistory();
  const location = useLocation();

  const onClickLogout = () => {
    logout(() => history.push('/'));
  };
  const onClickLogo = () => {
    if (location.pathname === '/verify') {
      return logout(() => history.push('/'));
    }
    user.isLogged ? history.push('/dashboard') : history.push('/');
  };
  return (
    <PageHeader
      style={{ zIndex: 10, padding: '16px' }}
      title={
        <Logo
          src={logo.src}
          alt={logo.alt}
          style={{ width: logo.width, height: logo.height }}
          onClick={onClickLogo}
        />
      }
      extra={<Extra logout={onClickLogout} langs={langs} />}
    />
  );
}
const Logo = styled('img')`
  height: 25px;
  cursor: pointer;
`;
export default Header;
