import { policy } from 'data/statuses';

export default (status) => {
  if (/^2/.test(status)) return 'active';
  if (/^3/.test(status)) return 'pending';
  if (/^4/.test(status)) return 'closed';
  if (status === policy.STATUS_CANCELLED_POLICY) return 'cancelled';
  if (status === policy.STATUS_INVALIDATED_POLICY) return 'invalidated';
  return 'active';
};
