import appConfig from 'app.config';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { PASS_KEY, TOKEN_NAME } from 'config';
import { TokenStorage } from 'modules/TokenStorage';
// import rollbarFactory from 'services/rollbarFactory';
import { getPartner } from 'helpers/partner';
import axios from 'axios';
import { API_URL } from 'config';
import { partnersAvailable } from 'data/partners';
import { setLocaleFn } from 'helpers/dates/dates';

export const AppStateContext = createContext();
export const useAppState = () => useContext(AppStateContext);

const jwtStore = new TokenStorage(TOKEN_NAME, PASS_KEY);

export const AppStateProvider = ({ children }) => {
  const partner = getPartner();
  const defaultLang = appConfig[partner].defaultLang;
  const [locale, setLocaleLang] = useState(defaultLang);
  const [messages, setMessages] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingLanguage, setIsLoadingLanguage] = useState(false);
  const [configuration, setConfiguration] = useState({});
  const [customerPortalConfig, setCustomerPortalConfig] = useState();
  // const [rollbar] = useState(rollbarFactory());

  const [langs, setLangs] = useState(appConfig[partner].langs);

  const setLocale = locale => {
    const tLocale = locale?.toLowerCase() === 'gb' ? 'en' : locale;
    setLocaleLang(langs.includes(tLocale) ? tLocale : 'en');
    setLocaleFn(langs, tLocale);
  };

  useEffect(() => {
    const lang =
      langs.find(lang => lang === navigator?.language?.split('-')[0]) ||
      defaultLang;
    lang !== locale && setLocale(lang);
    axios
      .get(
        `${API_URL}/v2/configuration/customer-portal/${partnersAvailable[partner]}`,
      )
      .then(res => {
        setCustomerPortalConfig(res.data);
        if (res.data.languages?.length > 0)
          setLangs(res.data.languages.map(l => l.toLowerCase()));
      });
  }, []);
  const initialState = {
    isLoading,
    setIsLoading,
    isLoadingLanguage,
    setIsLoadingLanguage,
    locale,
    setLocale,
    messages,
    setMessages,
    jwtStore,
    // rollbar,
    partner,
    setConfiguration,
    configuration,
    langs,
    customerPortalConfig,
  };

  return (
    <AppStateContext.Provider value={initialState}>
      {children}
    </AppStateContext.Provider>
  );
};
