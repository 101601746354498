import { useState } from 'react'
import { useApi } from 'services/providers/api/Api'

const useQuery = (queryName, { map, action } = {}) => {
  const [data, setData] = useState({})
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const api = useApi()

  const fetcher = api[queryName]

  if (!fetcher) return { error: `no query ${queryName} found` }

  const request = action ? fetcher(action) : fetcher

  const query = async (payload) => {
    let error, data
    setIsLoading(true)
    try {
      const resp = await request(payload)
      data = map ? map(resp) : resp
      setData(data)
    } catch (err) {
      error = err
      setError(err)
    }
    setIsLoading(false)
    return { error, data }
  }

  return {
    data,
    error,
    isLoading,
    query,
  }
}
export default useQuery
