import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useQuery from 'hooks/fetch/useQuery'
import useFormatMsg from 'hooks/translations/useFormatMsg'
import useQueryString from 'hooks/useQueryString'
import modalMsg from './modalMsg'
import AuthForm from './AuthForm'

export default function Reset() {
  const history = useHistory()
  const { email } = useParams()
  const { token } = useQueryString()
  const msg = useFormatMsg('auth.reset')
  const { isLoading, query } = useQuery('resetPassword')

  const onSubmit = async ({ email, password, partnerId }) => {
    const payload = { email, password, partnerId, token }
    const { error } = await query(payload)
    const onOkModal = () => {
      switch (error?.status) {
        case 400:
          const location = {
            pathname: '/forgot_password',
            state: { expired: true },
          }
          history.push(location)
        case 409:
        default:
          history.push('/login')
          return
      }
    }
    modalMsg(
      { error, msg, onOk: onOkModal },
      'insurance.bm@i-surance.eu' /* supportEmailJsx */,
    )
  }
  return (
    <React.Fragment>
      <AuthForm
        msg={msg}
        redirectedUser={email}
        onSubmit={onSubmit}
        isLoading={isLoading}
        email={email}
        emailDisabled={!!email}
      />
    </React.Fragment>
  )
}
