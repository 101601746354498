import IBAN from 'iban';

export const isValidPassword = password => {
  const regExp =
    /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[/.;,:"·()=¿¡^`\][>\\<+!@#$%^&?*{|}~_-])(.+){8,}/;
  return regExp.test(password);
};
export const isValidEmail = email => {
  return /(.+)@(.+)\..+/.test(email);
};
export const isValidRepeatedPassword = (pass, repeated) => {
  return pass === repeated;
};
export const isValidIMEI = imei => {
  const regExp = /^[0-9]{15}$/;
  return imei && regExp.test(imei);
};
export const isValidIBAN = iban => {
  return iban && IBAN.isValid(iban);
};

export const isValidPhone = phone => {
  const regex = /^[0-9]{1,}$/;
  return phone && regex.test(phone);
};
