import { Card, Row, Col, Button, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import imgSrc from 'public/img/case_request/case_request_3';
import styled from 'styled-components';

const { Title, Paragraph, Text } = Typography;

export default function SuccessOrderPage({ data }) {
  const history = useHistory();
  const msg = useFormatMsg('policies.free_case_request');
  return (
    <Row style={{ height: '100%' }} align="middle" gutter={[32, 32]}>
      <Col xs={24} md={12}>
        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          <Title level={5}>{msg('success.title')}</Title>
          <Paragraph>{msg('success.text')}</Paragraph>
          <Paragraph>
            <Text>
              {data.firstName} {data.lastName}
            </Text>
            <br />
            <Text>
              {data.streetAddress} {data.streetNumber}
            </Text>
            <br />
            <Text>
              {data.postCode} {data.city} {msg(`countries.${data.country}`)}
            </Text>
          </Paragraph>
          <Button
            style={{ marginTop: '1rem' }}
            type="primary"
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            {msg('success.back_to_portal.btn')}
          </Button>
        </Card>
      </Col>
      <Col xs={24} md={12} align="middle">
        <Img src={imgSrc} />
      </Col>
    </Row>
  );
}

const Img = styled('img')`
  width: 100%;
  //height: 300px;
  object-fit: cover;
  object-position: center;
`;
