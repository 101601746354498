import { Layout, Tabs, Row, Col } from 'antd';
import { useUser } from 'providers/user/User';
import React, { useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import CustomerOverview from 'components/containers/customer/CustomerOverview';
import { getPathFromLocation } from 'helpers/strings';
import useTabs from './useTabs';

const MainContent = () => {
  const { user } = useUser();
  const history = useHistory();
  const { tabItems, keys } = useTabs();
  const pathName = getPathFromLocation(history.location.pathname);
  const initialTab = keys.includes(pathName) ? pathName : 'policies';
  const [currentTab, setCurrentTab] = useState(initialTab);

  const { path } = useRouteMatch();
  useEffect(() => {
    const pathTo = `${path}/${currentTab}`;
    history.location.pathname !== pathTo && history.push(pathTo);
  }, [currentTab]);

  return (
    <Layout>
      <Layout.Content>
        <Row
          gutter={(16, { xs: 0, sm: 0, md: 0, lg: 16 })}
          justify="space-between"
        >
          <Col lg={16} md={24} sm={24} xs={24}>
            <Tabs
              type="card"
              activeKey={currentTab}
              onTabClick={setCurrentTab}
              items={tabItems.map(tab => ({
                children: tab.Comp,
                label: (
                  <span>
                    <tab.Icon />
                    {tab.msg}
                  </span>
                ),
                id: tab.id,
                key: tab.key,
              }))}
            ></Tabs>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24}>
            <CustomerOverview data={user} />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default MainContent;
