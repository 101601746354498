import React from 'react';
import { Row, Col } from 'antd';
import { Typography } from 'antd';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { formateDateDDMMYYYY } from 'helpers/dates/formatDate';
import TrackingWO from './TrackingWO';

const { Text } = Typography;

export default function WorkOrdersView({ workOrders }) {
  if (!workOrders.length) return null;
  const msg = useFormatMsg('claims.claim_view.workorders');

  const workOrderView = workOrder => (
    <Row justify="space-between" key={workOrder.id}>
      <Col sm={9}>
        <Row>
          <Text>{workOrder.id}</Text>
          <TrackingWO woId={workOrder.id} />
        </Row>
      </Col>
      <Col sm={6}>
        <Row justify="center">
          <Text>{formateDateDDMMYYYY(workOrder.updated)}</Text>
        </Row>
      </Col>
      <Col sm={9}>
        <Row justify="end">
          <Text>{msg(`status.${workOrder.status}`)}</Text>
        </Row>
      </Col>
    </Row>
  );

  return (
    <>
      <Row>
        <Col sm={9}>
          <Text strong>{msg('number')}</Text>
        </Col>
        <Col sm={6}>
          <Row justify="center">
            <Text strong>{msg('declaration_date')}</Text>
          </Row>
        </Col>
        <Col sm={9}>
          <Row justify="end">
            <Text strong>{msg('statustext')}</Text>
          </Row>
        </Col>
      </Row>
      {workOrders.map(workOrder => workOrderView(workOrder))}
    </>
  );
}
