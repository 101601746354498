import { Col, DatePicker, Form, Input, Radio, Row, Typography } from 'antd';
import FormHelp from 'components/shared/FormHelp';
import { dateIsAfterUTC } from 'helpers/dates/dates';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import React, { Fragment, useState } from 'react';

const { Title } = Typography;

const { Item } = Form;

export default function WarrantyExtensionDetails({
  setIsPolicyHolder,
  isPolicyHolder,
}) {
  const [damageDateKnow, setDamageDateKnow] = useState(true);

  const detailsMsg = useFormatMsg('claims.declaration.details');
  const msg = useFormatMsg('generics');

  const requiredRules = [
    {
      required: true,
      message: detailsMsg('required'),
    },
  ];

  return (
    <Fragment>
      <Title level={5}>{detailsMsg('claim_details')}</Title>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Item
            label={detailsMsg('DAMAGE.damageDateKnow.label')}
            name="DAMAGE_DATE_KNOW"
            rules={requiredRules}
            labelCol={{ span: 24 }}
            onChange={() => {
              setDamageDateKnow(!damageDateKnow);
            }}
          >
            <Radio.Group className="flex-col cap-all">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Item>
        </Col>
        <Col xs={24} md={12}>
          <Item
            name="incidentDate"
            label={
              !damageDateKnow
                ? detailsMsg('incident_date.label.1')
                : detailsMsg('incident_date.label.2')
            }
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <DatePicker
              placeholder={detailsMsg('incident_date.placeholder')}
              format="DD/MM/YYYY"
              disabledDate={date => dateIsAfterUTC(date)}
            />
          </Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={24}>
          <Item
            name="incidentLocation"
            label={detailsMsg('DAMAGE.incident_location.label')}
            help={
              <FormHelp>
                ({detailsMsg('DAMAGE.incident_location.help')})
              </FormHelp>
            }
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input.TextArea
              placeholder={detailsMsg('DAMAGE.incident_location.placeholder')}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Item
            name="incidentDescription"
            label={detailsMsg('DAMAGE.incident_description.label')}
            help={
              <FormHelp>
                ({detailsMsg('DAMAGE.incident_description.help')})
              </FormHelp>
            }
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input.TextArea
              placeholder={detailsMsg(
                'DAMAGE.incident_description.placeholder',
              )}
            />
          </Item>
        </Col>
      </Row>
      <Row
        justify="space-between"
        style={{ marginTop: '1rem' }}
        gutter={[16, 16]}
      >
        <Col xs={24} md={12}>
          <Item
            name="responsible"
            label={detailsMsg('responsibility.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
            onChange={({ target }) => {
              setIsPolicyHolder(target.value === 'INSURED');
            }}
          >
            <Radio.Group className="flex-col">
              <Radio value="INSURED">
                {detailsMsg('responsibility.policy_holder')}
              </Radio>
              <Radio value="FAMILY">
                {detailsMsg('responsibility.family_member')}
              </Radio>
              <Radio value="OTHER">{detailsMsg('responsibility.other')}</Radio>
            </Radio.Group>
          </Item>
          {!isPolicyHolder && (
            <>
              <Item
                name="THIRD_PARTY"
                label={detailsMsg('third_party.label')}
                rules={requiredRules}
                labelCol={{ span: 24 }}
              >
                <Input.TextArea
                  placeholder={detailsMsg('third_party.placeholder')}
                />
              </Item>
              <Item
                name="OWNERRELATIONSHIP"
                label={detailsMsg('owner_relationship')}
                rules={requiredRules}
                labelCol={{ span: 24 }}
              >
                <Input.TextArea />
              </Item>

              <Item
                name="THIRD_CLAIM_RAISED"
                label={detailsMsg('third_claim_raised.label')}
                rules={requiredRules}
                labelCol={{ span: 24 }}
              >
                <Radio.Group className="flex-col">
                  <Radio value={true}>{msg('yes')}</Radio>
                  <Radio value={false}>{msg('no')}</Radio>
                </Radio.Group>
              </Item>
            </>
          )}
        </Col>
        <Col xs={24} md={12}>
          <Item
            name="damageDescription"
            label={detailsMsg('DAMAGE.damage_description.label')}
            help={
              <FormHelp>
                ({detailsMsg('DAMAGE.damage_description.help')})
              </FormHelp>
            }
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input.TextArea
              placeholder={detailsMsg('DAMAGE.damage_description.placeholder')}
            />
          </Item>
        </Col>
      </Row>
      {/* <Title style={{ marginTop: '2rem' }} level={5}>
        {detailsMsg('DAMAGE.section')}
      </Title> */}
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Item
            name="ALL_DAMAGES"
            label={detailsMsg('DAMAGE.all_damages.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Item>
          <Item
            name="SWITCH_ON"
            label={detailsMsg('DAMAGE.switch_on.label')}
            labelCol={{ span: 24 }}
            rules={[{ required: false }]}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Item>
          <Item
            name="USED"
            label={detailsMsg('DAMAGE.used.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Item>
        </Col>
        <Col xs={24} md={12}>
          <Item
            name="WATER_EXPOSED"
            label={detailsMsg('DAMAGE.water_exposed.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Item>
          <Item
            name="TOUCHSCREEN_REACT"
            label={detailsMsg('DAMAGE.touchscreen_react.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Item>
          <Item
            name="OTHER_CLAIM"
            label={detailsMsg('DAMAGE.other_claim.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Item>
        </Col>
      </Row>
    </Fragment>
  );
}
