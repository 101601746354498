import { List, Empty } from 'antd';
import useInitialQuery from 'hooks/fetch/useInitialQuery';
import { useUser } from 'providers/user/User';
import React from 'react';
import {
  policyListMapper,
  claimPolicyListMapper,
} from 'services/providers/api/mappers';
import PolicyWrapper from 'components/containers/policies/entity/PolicyWrapper';
import Spinner from 'components/shared/Spinner';
import useFormatMsg from 'hooks/translations/useFormatMsg';

export default function PolicyList() {
  const { user } = useUser();
  const msg = useFormatMsg('policies.policy_card');
  const { data, isLoading, error } = useInitialQuery(
    'getPoliciesByCustomerId',
    { map: policyListMapper, payload: user.customerId },
  );
  const { data: claims } = useInitialQuery('getClaimsByCustomerIdWOIncludes', {
    map: claimPolicyListMapper,
    payload: user.customerId,
  });
  const emptyText = <Empty description={msg('empty_data')} />;
  if (isLoading) return <Spinner />;
  if (error || Object.keys(data).length === 0) return null;

  const render = item => (
    <List.Item key={`item${item.id}`}>
      <PolicyWrapper policy={item} claims={claims[item.id]} />
    </List.Item>
  );
  return (
    <List
      locale={{ emptyText }}
      dataSource={data?.data || [{}]}
      renderItem={render}
    />
  );
}
