import { useEffect, useState } from 'react';
import * as Mapper from 'services/providers/api/mappers';
import { useApi } from 'services/providers/api/Api';

const useInitialQuery = (queryName, opts) => {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const api = useApi();
  const { map, payload } = opts;
  const request = api[queryName];
  const mapper = map || Mapper[`${queryName}Mapper`];
  const query = payload ? request(payload) : request;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await query();
        setData(mapper ? mapper(data) : data);
      } catch (err) {
        setError(err);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return {
    data,
    error,
    isLoading,
  };
};
export default useInitialQuery;
