import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Card, Col, Row, Skeleton, Typography, Button, Tooltip } from 'antd';
import { formateDateDDMMYYYY } from 'helpers/dates/formatDate';
import StatusViewer from './StatusViewer';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { statusAddOns } from 'helpers/statuses/addOn/status';
import usePhonecaseModal from 'components/containers/policies/useCases/phoneCaseOrder/usePhonecaseModal';
import usePolicyAddons from 'services/hooks/usePolicyAddons';
import { capitalize as cap } from 'helpers/strings';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

const PHONE_CASE_SCREEN_PROTECTOR = 'PHONE_CASE_SCREEN_PROTECTOR';
const BATTERY_REPLACEMENT = 'BATTERY_REPLACEMENT';

export default function PolicyOrder({ isLoading, data }) {
  if (!data) return null;
  const history = useHistory();
  const { availableAddOns } = usePolicyAddons(data);
  const { addons, insuredItems } = data;
  const modal = usePhonecaseModal(data.id);
  const msg = useFormatMsg('policies.policy_requests');
  const msgPC = useFormatMsg('policies.policy_card');

  const isAddOnDisable = addOnId =>
    !availableAddOns.find(addOn => addOn.addOnId === addOnId) &&
    addons?.status !== 30201;

  const showPhonecaseRequestModal = () => {
    if (!isAddOnDisable(PHONE_CASE_SCREEN_PROTECTOR)) {
      if (
        availableAddOns.find(
          addOn => addOn.addOnId === PHONE_CASE_SCREEN_PROTECTOR,
        )
      ) {
        modal();
      } else {
        history.push(
          `phone_case/${data.id}/device/not_available/${addons?.id}`,
        );
      }
    }
  };

  const onClickBatteryExchange = () => {
    history.push(`battery_exchange/${data.id}`);
  };

  const renderPCorBR = (addon, field) => {
    if (addon[field] === BATTERY_REPLACEMENT) return renderBatteryReplacement();
    if (addon[field] === PHONE_CASE_SCREEN_PROTECTOR)
      return renderPhoneCase(addon);
  };

  const renderPhoneCase = addon => {
    return (
      <Button
        type="text"
        style={{ padding: 0, height: 'fit-content' }}
        data-cy="phonecase"
        key={`PC-${addon.id}`}
      >
        <ILink
          data-cy="btn_phonecase_request"
          onClick={showPhonecaseRequestModal}
          disabled={isAddOnDisable(PHONE_CASE_SCREEN_PROTECTOR)}
        >
          {msgPC('phonecase.text')}
        </ILink>
      </Button>
    );
  };

  const renderBatteryReplacement = () => {
    return (
      <Button
        type="text"
        style={{ padding: 0, height: 'fit-content' }}
        key="BRP"
      >
        <ILink
          onClick={onClickBatteryExchange}
          data-cy="battery"
          disabled={isAddOnDisable(BATTERY_REPLACEMENT)}
        >
          {msgPC('battery.text')}
        </ILink>
      </Button>
    );
  };

  return (
    <Card style={{ width: '100%' }} actions={[]}>
      <Skeleton loading={isLoading} active>
        <Row gutter={16}>
          <Col md={8}>
            <Paragraph strong>{cap(msg('insured_device'))}</Paragraph>
            <Paragraph style={{ margin: '0px' }}>
              {msg('make_and_model', {
                model: `${insuredItems?.[0]?.item?.manufacturer}, ${insuredItems?.[0]?.item?.model}`,
              })}
            </Paragraph>
            <Tooltip title={msg('tooltipimei')} placement="right">
              <Paragraph style={{ width: 'fit-content' }}>
                {msg('imei', { imei: insuredItems?.[0].serialNumber })}
                <InfoCircleOutlined style={{ marginLeft: '8px' }} />
              </Paragraph>
            </Tooltip>
            {(addons || availableAddOns.length > 0) && (
              <Paragraph strong>{cap(msgPC('extras.title'))}</Paragraph>
            )}
            <Paragraph>
              {addons && renderPCorBR(addons, 'addOnType')}
              {availableAddOns.length > 0 &&
                availableAddOns.map(a => {
                  return renderPCorBR(a, 'addOnId');
                })}
            </Paragraph>
          </Col>
          {addons && (
            <Col md={16}>
              <Paragraph strong>{msg(`title.${addons.addOnType}`)}</Paragraph>
              <Row xs={24} md={16} gutter={[8, 8]}>
                <Col sm={14}>
                  <Paragraph>
                    {`${msg('status')} ${msg(
                      `statuses.${statusAddOns[addons.status]}`,
                    )}`}
                  </Paragraph>
                </Col>
                <Col sm={10}>
                  <Paragraph>{`${msg('request_date')} ${formateDateDDMMYYYY(
                    addons.createdAt,
                  )}`}</Paragraph>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={24}>
                  <StatusViewer status={addons.status} />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Skeleton>
    </Card>
  );
}

const ILink = styled('span')`
  text-decoration: underline;
  color: initial;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => props.disabled && 0.5};
  text-decoration: ${props => props.disabled && 'none'};
`;
