import jwtDecode from 'jwt-decode'

export class TokenStorage {
  constructor(token_name, pass_key) {
    this.token_name = token_name
    this.pass_key = pass_key
  }

  getToken = () => localStorage[this.token_name]
  setToken(token) {
    localStorage.setItem(this.token_name, token)
  }
  getCredentials() {
    const token = this.getToken()
    return jwtDecode(token)
  }
  getPayload() {
    const credentials = this.getCredentials()
    return credentials[this.pass_key]
  }
  removeToken() {
    localStorage.removeItem(this.token_name)
  }
  isValid = () => {
    const token = this.getToken()
    if (!token) return false
    const credentials = jwtDecode(token)
    return !(credentials?.exp < Date.now() / 1000)
  }
}
