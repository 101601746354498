import { formateDateDDMMYYYY, processTime } from 'helpers/dates/formatDate';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';

export const processClaimContext = context => {
  const msg = useFormatMsg('generics');
  const messageNo = msg('no');
  const messageYes = msg('yes');
  return context.reduce((p, n) => {
    const { question, value, varType } = n || {};
    let valueData = '';
    if (varType && value !== undefined) {
      switch (varType) {
        case 'boolean':
          valueData = ['true', '1'].includes(value) ? messageYes : messageNo;
          break;
        case 'number':
          valueData = parseInt(value, 10);
          break;
        case 'text':
        case 'string':
          if (value === '1' || value === '0') {
            valueData = value === '1' ? messageYes : messageNo;
            break;
          }
        case 'object':
          if (['THEFT_TIME', 'INCIDENT_TIME'].includes(question)) {
            valueData = processTime(value);
          } else if (
            ['POLICE_REPORT_DATE', 'MENTION_DATE_SIM_BLOCKED'].includes(
              question,
            )
          ) {
            valueData = formateDateDDMMYYYY(value);
          } else {
            valueData = value;
          }
          break;
        default:
          valueData = value;
          break;
      }
    } else {
      valueData = messageNo;
    }
    return {
      ...p,
      [camelToSnakeCaseUpper(question)]: valueData,
    };
  }, {});
};

const camelToSnakeCaseUpper = str =>
  !/[A-Z]/.test(str.charAt(0))
    ? str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`).toUpperCase()
    : str;

export const processClaim = claim => {
  const msg = useFormatMsg('claims.claim_view.claim_details.incident_category');
  return {
    incidentDate: formateDateDDMMYYYY(claim.incidentDate),
    incidentLocation: claim.incidentLocation,
    incidentCountry: claim.incidentCountry,
    incidentDescription: claim.incidentDescription,
    responsible: claim.responsible,
    incidentDescription: claim.incidentDescription,
    incidentCategory: msg(claim.incidentCategory),
    damageDescription: claim.damageDescription,
    damages: claim.damages,
  };
};

export const processInsuredItem = iI => ({
  ...iI,
  ...iI.item,
  purchaseDate: formateDateDDMMYYYY(iI.purchaseDate),
});
