import { Col, Form, Input, InputNumber, Row, Select, Tooltip } from 'antd';
import { nanoid } from 'nanoid';
import React, { Fragment } from 'react';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { isValidIMEI } from 'helpers/formValidations/validators';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function MobileDevice({
  onChangeDevice,
  insuredItems,
  selectedDevice,
  onModifyDevice,
  children,
}) {
  const msg = useFormatMsg('claims.declaration.device');

  const validateIMEI = (e, value) => {
    if (value === '') return Promise.reject(msg('required'));
    return !isValidIMEI(value)
      ? Promise.reject(msg('serialNumber.validation_msg'))
      : Promise.resolve();
  };

  return (
    <Fragment>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="device"
            label={msg('device_damaged')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Select placeholder="Select" onChange={onChangeDevice}>
              {insuredItems.map((device, i) => (
                <Select.Option key={nanoid()} value={i}>
                  {device.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="serialNumber"
            label={msg('imei')}
            rules={[
              {
                required: true,
                validator: validateIMEI,
              },
            ]}
            labelCol={{ span: 24 }}
            tooltip={{
              title: msg(`serialNumber.tooltip`),
              icon: <InfoCircleOutlined />,
            }}
          >
            <Input
              name="serialNumber"
              onChange={onModifyDevice}
              value={selectedDevice.serialNumber}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="manufacturer"
            label={msg('manufacturer')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input
              onChange={onModifyDevice}
              value={selectedDevice.manufacturer}
            />
          </Form.Item>
          <Form.Item
            name="color"
            label={msg('colour')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input value={selectedDevice.color} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="model"
            label={msg('model')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Input name="model" onChange={onModifyDevice} />
          </Form.Item>
          <Form.Item
            name="capacity"
            label={msg('internal_memory')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <InputNumber
              min={1}
              max={100000}
              value={selectedDevice.capacity}
              formatter={value => `${value} GB`}
            />
          </Form.Item>
        </Col>
      </Row>
      {children}
    </Fragment>
  );
}
