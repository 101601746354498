import React from 'react';
import appConfig from 'app.config';
import { useHistory } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import isuranceLogo from 'public/img/isurance.svg';
import bolttechLogo from 'public/img/bolttech.svg';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { useAppState } from 'services/providers/AppState';
import { reloadOTBanner } from 'helpers/seo';

const { Paragraph, Title } = Typography;

export default function Footer() {
  const msg = useFormatMsg('app.footer');
  const history = useHistory();
  const { partner } = useAppState();

  const onClickFooterLink = link => {
    if (link === '/terms-of-use') {
      history.push(link);
      reloadOTBanner();
    } else {
      history.push(link);
    }
  };

  const getCompanyLogo = () => {
    switch (appConfig[partner].company) {
      case 'bolttech':
        return <BolttechLogo src={bolttechLogo} />;
      default:
        return <IsuranceLogo src={isuranceLogo} />;
    }
  };

  return (
    <React.Fragment>
      <FooterContainer>
        <Col className="app-layout">
          <Row>
            <Col md={24} lg={12} style={{ width: '100%' }}>
              <LogoContainer>
                <BMLogo src={appConfig[partner].footerlogo.src} />
                <CloseOutlined />
                {getCompanyLogo()}
              </LogoContainer>
              <Row className="links-row">
                <Paragraph>
                  {msg('caption', { year: new Date().getFullYear() })}
                </Paragraph>
                {appConfig[partner].footer.map(({ label, link }) => (
                  <Paragraph key={`p${label}`}>
                    <a
                      className="cap"
                      onClick={() => onClickFooterLink(link)}
                      id={`footer_${label}`}
                    >
                      {msg(label)}
                    </a>
                  </Paragraph>
                ))}
              </Row>
            </Col>
            <Col md={24} lg={12}>
              <Title level={3}>{msg('contact')}</Title>
              <Paragraph>{msg('contact_label')}</Paragraph>
              <Paragraph>
                <Linkin
                  dangerouslySetInnerHTML={{
                    __html: msg(`email`),
                  }}
                />
                <a className="email" href={`mailto:${msg('emailto')}`}>
                  {msg('emailto')}
                </a>
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </FooterContainer>
    </React.Fragment>
  );
}
const LogoContainer = styled('div')`
  margin-bottom: 3rem;
  display: flex;
  align-items: center;

  @media (max-width: 576px) {
    margin-bottom: 1rem;
  }
`;
const BMLogo = styled('img')`
  max-height: 25px;
  max-width: 40%;
  margin-right: 30px;
`;
const IsuranceLogo = styled('img')`
  max-height: 40px;
  max-width: 40%;
  margin-left: 25px;
`;
const BolttechLogo = styled('img')`
  max-height: 40px;
  max-width: 40%;
  margin-left: 25px;
`;
const FooterContainer = styled(Row)`
  background-color: black;
  padding: 2rem 1rem;
  border-radius: 1rem 1rem 0px 0px;
  color: white;

  .ant-typography,
  a {
    color: white;
    margin-bottom: 1rem;
  }

  a:hover {
    color: white;
  }

  .email {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  .links-row {
    justify-content: space-between;
    padding-right: 4rem;

    a {
      margin-right: 5px;
    }

    @media (max-width: 576px) {
      flex-direction: column-reverse;
      align-items: center;
      padding-right: 0;
    }
  }
`;

const Linkin = styled('div')`
  float: left;
  margin-right: 2px;
`;
