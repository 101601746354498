import { notification } from 'antd';

export const openNotification = ({
  message,
  description,
  type = 'success',
}) => {
  notification[type]({
    message,
    description,
    placement: 'topLeft',
    className: `${type}-alertbox-test`,
  });
};
export const notifyError = (message, description) => {
  notification.error({
    message,
    description,
    placement: 'topLeft',
    className: 'error-alertbox-test',
  });
};
