import axios from 'axios';

class Request {
  constructor(url) {
    this.config = {};
    this.fetch = axios.create({
      baseURL: url,
    });
    this.CancelToken = axios.CancelToken;
  }

  request = (method, url, data, options) => {
    const config = options || this.config;
    if (method === 'get')
      return this.fetch.request({ method, url, params: data, ...config });
    return this.fetch.request({ method, url, data, ...config });
  };

  getData = (endpoint, data) => this.request('get', endpoint, data);
  postData = (endpoint, data) => this.request('post', endpoint, data);
  putData = (endpoint, data) => this.request('put', endpoint, data);
  getBlobData = endpoint =>
    this.request('get', endpoint, null, {
      ...this.config,
      responseType: 'blob',
    });
  postBlobData = (endpoint, data) =>
    this.request('post', endpoint, data, {
      ...this.config,
      responseType: 'blob',
    });
  setToken = token => {
    this.config = {
      ...this.config,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };
  setConfig = config => {
    this.config = config;
  };
  removeToken = () => {
    this.config = {};
  };

  requestInterceptor = callback => {
    this.fetch.interceptors.request.use(callback);
  };
  responseErrorInterceptor = errCb => {
    this.fetch.interceptors.response.use(response => response, errCb);
  };
  createCancelToken = () => {
    return this.CancelToken.source();
  };
}

export { Request };
