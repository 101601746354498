import React from 'react';
import { Row } from 'antd';
import styled from 'styled-components';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { renderGenericSectionElement } from 'components/utils/GenericRender';
import appConfig from '../../../src/app.config';
import { getPartner } from 'helpers/partner';

const { cookies } = appConfig[getPartner()] || [];

const CookiePolicy = () => {
  const msg = useFormatMsg('app.cookies');

  return (
    <StyledRow>
      {cookies.map((element, index) => (
        <Row key={index} style={element.style}>
          {renderGenericSectionElement(element, msg)}
        </Row>
      ))}
    </StyledRow>
  );
};

export default CookiePolicy;

const StyledRow = styled(Row)`
  margin-bottom: 1rem;
  width: 100%;

  div {
    width: 100%;
  }

  table {
    width: 100%;
    margin-top: 20px;

    thead {
      text-align: center;
      background-color: transparent;
      color: black;
      font-weight: bold;
      border-top: solid 1px var(--gray-4);
    }

    td {
      padding: 5px;
      border: solid 1px var(--gray-4);
    }

    tr {
      border-bottom: solid 1px var(--gray-4);
    }
  }
`;
