import { Button, Row, Steps, Typography } from 'antd';
import React from 'react';
import './style';
import useFormatMsg from 'hooks/translations/useFormatMsg';

const { Title } = Typography;
const { Step } = Steps;

export default function Stepper({
  title,
  steps,
  current,
  isPrevDisabled = false,
  isNextDisabled = false,
}) {
  const msg = useFormatMsg('claims.declaration.main');
  return (
    <Row style={{ width: '100%' }} justify="center">
      <Title level={2} style={{ textAlign: 'center', marginBottom: '2rem' }}>
        {title}
      </Title>
      <Steps
        progressDot
        size="small"
        responsive="532px"
        current={current}
        items={steps.map(s => ({
          title: msg(s.title),
          key: s.label,
        }))}
      ></Steps>
    </Row>
  );
}

export const Actions = ({
  next,
  prev,
  isPrevDisabled = false,
  isNextDisabled = false,
}) => {
  const msg = useFormatMsg('claims.declaration.main');
  return (
    <Button.Group style={{ width: '100%' }}>
      <Button
        type="primary"
        ghost
        onClick={prev}
        disabled={isPrevDisabled}
        data-cy="btn_prev_step"
      >
        {msg('back')}
      </Button>
      <Button
        id="button_continue"
        type="primary"
        onClick={next}
        disabled={isNextDisabled}
        style={{ marginLeft: 'auto' }}
        data-cy="btn_next_step"
      >
        {msg('continue')}
      </Button>
    </Button.Group>
  );
};
