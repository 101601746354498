import Birthdate from 'pages/birthdate/Birthdate';
import Verify from 'pages/verify/Verify';
import Dashboard from 'pages/dashboard/Dashboard';
import Faq from 'pages/faq/Faq';
import ForgotPass from 'pages/forgotPass/ForgotPass';
import Landing from 'pages/Landing';
import Login from 'pages/login/Login';
import Register from 'pages/register/Register';
import Reset from 'pages/register/Reset';
import PrivacyPolicy from 'pages/privacyPolicy/PrivacyPolicy';
import TermsOfUse from 'pages/termsofuse/TermsOfUse';
import CookiePolicy from 'pages/cookiePolicy/CookiePolicy';
import Imprint from 'pages/imprint/Imprint';
import { useApiInterceptors } from 'services/providers/api/Api';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Cancellation from 'pages/cancellation/Cancellation';

function AppRouting() {
  useApiInterceptors();
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/faq/:id?" component={Faq} />
      <Route exact path="/login/:email?" component={Login} />
      <Route exact path="/register/:email/reset" component={Reset} />
      <Route path="/register" component={Register} />
      <Route path="/forgot_password" component={ForgotPass} />
      <Route exact path="/birthdate" component={Birthdate} />
      <Route exact path="/verify" component={Verify} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/cookie-policy" component={CookiePolicy} />
      <Route exact path="/terms-of-use" component={TermsOfUse} />
      <Route exact path="/imprint" component={Imprint} />
      <Route exact path="/salt_cancellation" component={Cancellation} />
      <PrivateRoute path="/dashboard">
        <Dashboard />
      </PrivateRoute>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}

export default AppRouting;
