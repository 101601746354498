import { policy } from 'data/statuses';
export default (status) => {
  switch (status) {
    case policy.STATUS_ACTIVE_POLICY:
      return 'active';
    case policy.STATUS_PENDING_POLICY_PAYMENT:
    case policy.STATUS_AWAITING_POLICY_DOCUMENTS:
    case policy.STATUS_PENDING_CANCELLATION:
    case policy.STATUS_PENDING_ISURANCE_VALIDATION:
    case policy.STATUS_PENDING_BANK_VALIDATION:
    case policy.STATUS_RESEND_POLICY_DOCUMENTS_TO_CUSTOMER:
    case policy.STATUS_ISURANCE_VALIDATED:
      return 'pending';
    case policy.STATUS_EXPIRED_POLICY:
    case policy.STATUS_POLICY_WITH_REPLACEMENT:
      return 'closed';
    case policy.STATUS_CANCELLED_POLICY:
      return 'cancelled';
    case policy.STATUS_INVALIDATED_POLICY:
      return 'invalidated';
  }
};
