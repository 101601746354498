export default (error, history, email) => {
  switch (error?.status) {
    case 404:
    case 403:
    case 400:
      return;
    case 409:
      history.push(`/register/${email}/reset`);
      return;
    default:
      history.push('/login');
      return;
  }
};
