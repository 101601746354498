const { useState, createContext, useContext, useEffect } = require('react');

export const StepperContext = createContext();
export const useStepperContext = () => useContext(StepperContext);

export const useStepper = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    setCurrentStep(state => ++state);
  };
  const prevStep = () => {
    if (currentStep === 0) return;
    setCurrentStep(state => --state);
  };
  const getStatus = index => {
    if (index === currentStep) return 'process';
    if (index < currentStep) return 'finish';
    if (index > currentStep) return 'wait';
  };

  return {
    indexStep: currentStep,
    setCurrentStep,
    nextStep,
    prevStep,
    getStatus,
  };
};
