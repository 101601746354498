import { MailOutlined } from '@ant-design/icons';
import { Form, Input, Typography } from 'antd';
import AuthLayout from 'components/base/AuthLayout';
import { isValidEmail } from 'helpers/formValidations/validators';
import useQuery from 'hooks/fetch/useQuery';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import modalMsg from './modalMsg';
import { useAppState } from 'providers/AppState';
import { partnersAvailable } from 'data/partners';

const { Paragraph } = Typography;

function ForgotPass() {
  const { partner } = useAppState();
  const { isLoading, query } = useQuery('resetPasswordRequest');
  const [email, setEmail] = useState();
  const [validEmail, setValidEmail] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const translationsPath = location.state?.expired
    ? 'auth.forgot.expired'
    : `auth.forgot`;
  const msg = useFormatMsg(translationsPath);
  const commonMsg = useFormatMsg(`auth.forgot`);

  const onSubmit = async () => {
    const onOkModal = () => {
      switch (error?.status) {
        case 409:
        case 400:
        case 500:
          return;
        default:
          history.push('/');
          return;
      }
    };
    const isValid = isValidEmail(email);
    if (!isValid) {
      setValidEmail(false);
      return;
    }
    setValidEmail(true);
    const { error } = await query({
      email,
      partnerId: partnersAvailable[partner],
    });
    modalMsg(error, commonMsg, msg, onOkModal, 'isurance@email.com');
  };
  const actions = (
    <Paragraph type="secondary" style={{ textAlign: 'center' }}>
      <Link to="/login">Login</Link>
    </Paragraph>
  );

  return (
    <AuthLayout
      title={msg('title')}
      caption={msg('caption')}
      actions={actions}
      btn={{
        label: msg('button'),
        onClick: onSubmit,
        loading: isLoading,
      }}
    >
      <Form.Item
        validateStatus={validEmail ? 'success' : 'error'}
        help={!validEmail && msg('email_error')}
      >
        <Input
          name="email"
          size="large"
          placeholder={msg('email_placeholder')}
          prefix={<MailOutlined style={{ marginRight: '12px' }} />}
          onChange={({ target }) => {
            setEmail(target.value.trim());
          }}
        />
      </Form.Item>
    </AuthLayout>
  );
}

export default ForgotPass;
