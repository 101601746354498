import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const formatDate = date => date.split(' ')[0];

export const formateDateDDMMYYYY = date =>
  date && dayjs(date).format('DD/MM/YYYY');

export const formateDateDDMMYYYYHHMM = date =>
  date && dayjs(date).format('DD/MM/YYYY HH:mm');

export const formateDateDDMMYYYYandAdd = (date, { number, unit }) =>
  date && dayjs(date).add(number, unit).format('DD/MM/YYYY');

export const processTime = date => date && dayjs(date).format('HH:mm');

export const formateDateYYYYMMDD = date =>
  date && dayjs(date).format('YYYY-MM-DD');
