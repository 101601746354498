import { openNotification } from 'helpers/Notifications';

async function onSubmitFormData(
  declareClaim,
  data,
  callback,
  uploadPolicyFile,
  uploadClaimFile,
) {
  try {
    const resp = await declareClaim(data);
    data.policyFiles['proof-of-purchase']?.length > 0 &&
      sendFiles(
        uploadPolicyFile(data.policy, 'proof-of-purchase'),
        data.policyFiles['proof-of-purchase'],
      );
    data.claimFiles['police-report']?.length > 0 &&
      sendFiles(
        uploadClaimFile(resp.data.data.id, 'police-report'),
        data.claimFiles['police-report'],
      );
    data.claimFiles['proof-of-family']?.length > 0 &&
      data.responsible !== 'INSURED' &&
      sendFiles(
        uploadClaimFile(resp.data.data.id, 'proof-of-family'),
        data.claimFiles['proof-of-family'],
      );
    data.claimFiles['repair-invoice']?.length > 0 &&
      data.claimContext?.find(cc => cc.question === 'CASHLESS_REPAIR')
        ?.value === false &&
      sendFiles(
        uploadClaimFile(resp.data.data.id, 'repair-invoice'),
        data.claimFiles['repair-invoice'],
      );
    data.claimFiles['damage-picture']?.length > 0 &&
      data.claimContext?.find(cc => cc.question === 'CASHLESS_REPAIR')
        ?.value === false &&
      sendFiles(
        uploadClaimFile(resp.data.data.id, 'damages'),
        data.claimFiles['damage-picture'],
      );

    callback?.(resp);
  } catch (error) {
    openNotification({
      message: error.title,
      description: error.details,
      type: 'error',
    });
  }
}

const sendFiles = async (query, uploads) => {
  const formData = new FormData();
  uploads.forEach(file => {
    formData.append('files[]', file.originFileObj);
  });
  try {
    await query(formData);
  } catch (error) {}
};

export default onSubmitFormData;
