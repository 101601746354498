import { Typography, Row, Tag, Space } from 'antd';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import usePaymentLink from 'services/hooks/usePaymentLink';

const { Text } = Typography;

const checkColorStatus = status => {
  switch (status) {
    case 21201:
    case 41201:
      return 'green';
    case 31201:
    case 31202:
    case 31203:
      return 'gold';
    case 51202:
    case 51203:
    case 51201:
    case 51205:
    case 51204:
      return '#ff4d4f';
    default:
      return '#C3CBCD';
  }
};

export default function PaymentLink({ claimId }) {
  const msg = useFormatMsg('claims.claim_view.payment_details');
  const { link, isLoading } = usePaymentLink({ claimId });

  if (isLoading && !link) return null;

  return (
    <Space direction="vertical" size="small">
      <Row>
        <Text>{msg('title')}</Text>
      </Row>

      <>
        <Row>
          <Text>{msg('link')}</Text>
        </Row>
        <Row>
          <Tag>
            <a
              href={link?.url}
              target="_blank"
              rel="noopener"
              id="payment_link"
            >
              <Text>{msg('link_message')}</Text>
            </a>
          </Tag>
        </Row>
        <Row style={{ marginTop: '8px' }}>
          <Text style={{ marginRight: '16px' }}>{msg('statustext')}</Text>
          {link?.status && (
            <Tag color={checkColorStatus(link?.status)}>
              {msg(`status.${link?.status}`)}
            </Tag>
          )}
        </Row>
      </>
    </Space>
  );
}
