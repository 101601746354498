import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  TimePicker,
  TreeSelect,
  Typography,
  Tooltip,
} from 'antd';
import Uploader from 'components/containers/claims/useCases/uploadDocuments/Uploader';
import FormHelp from 'components/shared/FormHelp';
import { dateIsAfterUTC } from 'helpers/dates/dates';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import React, { useState } from 'react';
import { useAppState } from 'services/providers/AppState';
import { useClaimContext } from 'services/providers/ClaimDeclaration';

const { Title } = Typography;
const { Item } = Form;

export default function BMJPDamageDetails({}) {
  const { messages } = useAppState();
  const [thirdParty, setThirdParty] = useState(false);
  const [responsible, setResponsible] = useState('INSURED');
  const [cashlessRepair, setCashlessRepair] = useState(false);

  const detailsMsg = useFormatMsg('claims.declaration.details');
  const msg = useFormatMsg('generics');
  const { setClaimFiles, claimFiles } = useClaimContext();

  const requiredRules = [
    {
      required: true,
      message: detailsMsg('required'),
    },
  ];
  const { incidentCategories, incidentTypes, responsibleTypes } =
    messages.claims.declaration.details.DAMAGE;

  const onChange =
    key =>
    ({ fileList }) => {
      setClaimFiles({ ...claimFiles, [key]: fileList });
    };

  return (
    <>
      <Title level={5}>{detailsMsg('claim_details')}</Title>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Item
            name="incidentLocation"
            label={detailsMsg('DAMAGE.incidentLocation.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Input
              placeholder={detailsMsg('DAMAGE.incident_location.placeholder')}
            />
          </Item>
          <Item
            name="INCIDENT_TIME"
            label={detailsMsg('DAMAGE.incident_time.label')}
            // rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <TimePicker
              format="HH:mm"
              placeholder={detailsMsg('DAMAGE.incident_time.placeholder')}
            />
          </Item>
          <Item
            name="incidentCategory"
            label={detailsMsg('DAMAGE.incident_category.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <TreeSelect
              placeholder={detailsMsg('DAMAGE.incidentCategory.placeholder')}
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={incidentCategories}
            />
          </Item>
          <Item
            name="incidentType"
            label={detailsMsg('incident_type.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <TreeSelect
              placeholder={detailsMsg('incident_type.placeholder')}
              treeCheckable
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={incidentTypes}
              showArrow
            />
          </Item>
          <Item
            name="INCIDENT_WITTNESS"
            label={detailsMsg('DAMAGE.incident_witness.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input.TextArea
              placeholder={detailsMsg('DAMAGE.incident_witness.placeholder')}
            />
          </Item>
        </Col>
        <Col xs={24} md={12}>
          <Item
            name="incidentDate"
            label={detailsMsg('incident_date.label.1')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <DatePicker
              placeholder={detailsMsg('incident_date.placeholder')}
              format="DD/MM/YYYY"
              disabledDate={date => dateIsAfterUTC(date)}
            />
          </Item>
          <Item
            name="INCIDENT_VERIFICATION"
            label={detailsMsg('DAMAGE.incident_verification.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input.TextArea
              placeholder={detailsMsg(
                'DAMAGE.incident_verification.placeholder',
              )}
            />
          </Item>
          <Item
            name="incidentDescription"
            label={detailsMsg('DAMAGE.incident_description.label')}
            help={
              <FormHelp>
                ({detailsMsg('DAMAGE.incident_description.help')})
              </FormHelp>
            }
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Input.TextArea
              placeholder={detailsMsg(
                'DAMAGE.incident_description.placeholder',
              )}
            />
          </Item>
          <Item
            name="responsible"
            label={detailsMsg('DAMAGE.responsible.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
            style={{ marginTop: '56px' }}
          >
            <TreeSelect
              placeholder={detailsMsg('DAMAGE.responsible.placeholder')}
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={responsibleTypes}
              onChange={setResponsible}
            />
          </Item>
          {responsible !== 'INSURED' && (
            <Tooltip
              title={detailsMsg('tooltip_proof_of_family')}
              placement="left"
            >
              <Item
                name="proof-of-family"
                getValueFromEvent={e => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  return e && e.fileList;
                }}
                rules={requiredRules}
                valuePropName="fileList"
              >
                <Uploader
                  name="proof-of-family"
                  maxFiles={1}
                  beforeUpload={() => false}
                  listType={detailsMsg('DAMAGE.upload_proof_of_family')}
                  msg={detailsMsg('DAMAGE.upload_proof_of_family')}
                  onChange={onChange('proof-of-family')}
                  accept="application/pdf,image/png,image/jpg,image/jpeg"
                />
              </Item>
            </Tooltip>
          )}
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Item
            name="THIRD_CLAIM_RAISED"
            label={detailsMsg('third_claim_raised.label')}
            rules={requiredRules}
            labelCol={{ span: 24 }}
          >
            <Radio.Group
              className="flex-col"
              onChange={e => setThirdParty(e.target.value)}
            >
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Item>
          {thirdParty && (
            <>
              <Item
                name="THIRD_PARTY_NAME"
                label={detailsMsg('DAMAGE.nameThirdParty.label')}
                labelCol={{ span: 24 }}
                rules={requiredRules}
              >
                <Input />
              </Item>
              <Item
                name="THIRD_PARTY_POLICY_NUMBER"
                label={detailsMsg('DAMAGE.policyNumberThirdParty.label')}
                labelCol={{ span: 24 }}
                rules={requiredRules}
              >
                <Input />
              </Item>
            </>
          )}
        </Col>
        <Col xs={24} md={12}>
          {responsible !== 'INSURED' && (
            <Item
              name="THIRD_PARTY"
              label={detailsMsg('third_party.label')}
              rules={requiredRules}
              labelCol={{ span: 24 }}
            >
              <Input.TextArea
                placeholder={detailsMsg('third_party.placeholder')}
              />
            </Item>
          )}
          {thirdParty && (
            <>
              <Item
                name="THIRD_PARTY_TYPE"
                label={detailsMsg('DAMAGE.typeThirdParty.label')}
                labelCol={{ span: 24 }}
                rules={requiredRules}
              >
                <Input />
              </Item>
              <Item
                name="OTHER_CLAIM"
                label={detailsMsg('DAMAGE.other_claim.label')}
                rules={requiredRules}
                labelCol={{ span: 24 }}
              >
                <Radio.Group className="flex-col">
                  <Radio value={true}>{msg('yes')}</Radio>
                  <Radio value={false}>{msg('no')}</Radio>
                </Radio.Group>
              </Item>
            </>
          )}
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Item
            name="HAVE_DEVICE"
            label={detailsMsg('DAMAGE.have_device.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Item>
          <Item
            name="BATTERY_LEAKAGE"
            label={detailsMsg('DAMAGE.battery_leakage.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
          >
            <Radio.Group className="flex-col">
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Item>
        </Col>
        <Col xs={24} md={12}>
          <Item
            name="CASHLESS_REPAIR"
            label={detailsMsg('DAMAGE.cashless_repair.label')}
            labelCol={{ span: 24 }}
            rules={requiredRules}
            tooltip={detailsMsg('DAMAGE.cashless_repair.tooltip')}
          >
            <Radio.Group
              className="flex-col"
              onChange={e => setCashlessRepair(e.target.value)}
            >
              <Radio value={true}>{msg('yes')}</Radio>
              <Radio value={false}>{msg('no')}</Radio>
            </Radio.Group>
          </Item>
          {!cashlessRepair && (
            <>
              <Item
                name="REPAIR_COST"
                label={detailsMsg('DAMAGE.repair_cost.label')}
                labelCol={{ span: 24 }}
              >
                <InputNumber min={0} />
              </Item>
              <Tooltip
                title={detailsMsg('tooltip_damage_picture')}
                placement="left"
              >
                <Item
                  name="damage-picture"
                  getValueFromEvent={e => {
                    if (Array.isArray(e)) {
                      return e;
                    }
                    return e && e.fileList;
                  }}
                  rules={requiredRules}
                  valuePropName="fileList"
                >
                  <Uploader
                    name="damage-picture"
                    maxFiles={1}
                    beforeUpload={() => false}
                    listType={detailsMsg('DAMAGE.upload_damage_picture')}
                    msg={detailsMsg('DAMAGE.upload_damage_picture')}
                    onChange={onChange('damage-picture')}
                    accept="application/pdf,image/png,image/jpg,image/jpeg"
                  />
                </Item>
              </Tooltip>
              <Tooltip
                title={detailsMsg('tooltip_proof_of_repair')}
                placement="left"
              >
                <Item
                  name="repair-invoice"
                  getValueFromEvent={e => {
                    if (Array.isArray(e)) {
                      return e;
                    }
                    return e && e.fileList;
                  }}
                  rules={requiredRules}
                  valuePropName="fileList"
                >
                  <Uploader
                    name="repair-invoice"
                    maxFiles={1}
                    beforeUpload={() => false}
                    listType={detailsMsg('DAMAGE.upload_repair_invoice')}
                    msg={detailsMsg('DAMAGE.upload_repair_invoice')}
                    onChange={onChange('repair-invoice')}
                    accept="application/pdf,image/png,image/jpg,image/jpeg"
                  />
                </Item>
              </Tooltip>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
