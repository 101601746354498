import { ConfigProvider, DatePicker, Row } from 'antd';
// import en_GB from 'antd/lib/locale-provider/en_GB';
import en_GB from 'antd/es/locale/en_GB';
import AuthLayout from 'components/base/AuthLayout';
import { date18yearsBefore } from 'helpers/dates/dates';
import { formateDateYYYYMMDD } from 'helpers/dates/formatDate';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import React, { useState } from 'react';

function BirthdateForm({ onSubmit }) {
  const [birthDate, setBirthDate] = useState();
  const msg = useFormatMsg('auth.birthdate');

  return (
    <AuthLayout
      title={msg('title')}
      caption={msg('caption')}
      btn={{
        id: 'buttonSubmit',
        label: msg('button'),
        onClick: () => onSubmit({ birthDate }),
        disabled: !birthDate,
      }}
    >
      <Row gutter={[0, 16]} justify="center">
        <ConfigProvider locale={en_GB}>
          <DatePicker
            defaultPickerValue={date18yearsBefore()}
            placeholder={'DD/MM/YYYY'}
            autoFocus
            className="fullWidth"
            showToday={false}
            disabledDate={date => date > date18yearsBefore()}
            onChange={e => {
              setBirthDate(formateDateYYYYMMDD(e));
            }}
            format="DD/MM/YYYY"
          />
        </ConfigProvider>
      </Row>
    </AuthLayout>
  );
}

export default BirthdateForm;
