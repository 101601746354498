import { useState } from 'react';

const useFetch = (query, callback) => {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const request = async (payload) => {
    setIsLoading(true);
    try {
      const data = await query(payload);
      setData(data);
      callback?.success?.(data);
    } catch (err) {
      setError(err);
      callback?.error?.(err);
    }
    setIsLoading(false);
  };
  return {
    data,
    setData,
    error,
    setError,
    isLoading,
    setIsLoading,
    request,
  };
};
export default useFetch;
