import { ApiService } from 'modules/ApiService'
import { API_URL } from 'config'
import { Request } from 'modules/Request'
import { CustomError } from 'modules/CustomError'

const apiFactory = () => {
  const api = new ApiService(API_URL, Request)

  const respErrIntercept = (err) => {
    throw new CustomError(err)
  }
  api.query.responseErrorInterceptor(respErrIntercept)
  api.auth.responseErrorInterceptor(respErrIntercept)
  return api
}

export default apiFactory
