import imprintDI from './imprint';
import privacyDI from './privacy';
import cookiesDI from './cookies';
import termsDI from './termsofuse';
import otConfig from './otConfig';
import ga from './ga';

export default {
  ...ga,
  title: 'Galaxus AG',
  landingImg:
    'https://i-con-public-static-content.s3.eu-central-1.amazonaws.com/my-customer-space/Landing_DI.png',
  favicon:
    'https://i-con-public-static-content.s3.eu-central-1.amazonaws.com/my-customer-space/DI_favicon.ico',
  keyLoginConfig: 'birthDate',
  langs: ['de'],
  defaultLang: 'de',
  faq: [{ from: 1, to: 1 }],
  cookies: cookiesDI,
  imprint: imprintDI,
  privacy: privacyDI,
  terms: termsDI,
  logo: {
    src: 'https://i-con-public-static-content.s3.eu-central-1.amazonaws.com/my-customer-space/Digitec_Logo.svg',
    alt: 'Galaxus AG',
    width: '300px',
    height: '100%',
  },
  footerlogo: {
    src: 'https://i-con-public-static-content.s3.eu-central-1.amazonaws.com/my-customer-space/Digitec_Logo_White.svg',
    alt: 'Footer Galaxus AG',
  },
  company: 'bolttech',
  otScript: otConfig,
  footer: [
    {
      label: 'terms_of_use',
      link: '/terms-of-use',
    },
    {
      label: 'imprint',
      link: '/imprint',
    },
    {
      label: 'faq',
      link: '/faq',
    },
  ],
};
