import { Button, Modal, Space, Tabs, Col, Empty, Row } from 'antd';
import React from 'react';
import useModal from 'services/hooks/useModal';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import ClaimDetails from './ClaimDetails';
import InsuredItemDetails from './InsuredItemDetails';
import WorkOrderDetails from './WorkOrderDetails';
import styled from 'styled-components';
import VoucherDetails from './VoucherDetails';

export default function ClaimDetailsModal({ claim, workOrders, vouchers }) {
  const { showModal, isModalVisible } = useModal();
  const msg = useFormatMsg('claims.claim_view');

  const emptyState = () => {
    if (!vouchers.length && !workOrders.length)
      return (
        <>
          <Row justify="center">{msg('ooppps')}</Row>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={msg('workorders.no_data')}
          />
        </>
      );
    return null;
  };

  return (
    <>
      <Button size="small" onClick={showModal} id="claim_view_details">
        {msg('view_details')}
      </Button>
      <Modal
        open={isModalVisible}
        footer={null}
        title={claim?.id}
        onCancel={showModal}
      >
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              id: 'tab_fulfillment_details',
              label: msg('tabs.fulfillmentdetails'),
              children: (
                <BorderedCol>
                  <Space
                    direction="vertical"
                    size="large"
                    style={{ width: '100%' }}
                  >
                    <VoucherDetails vouchers={vouchers} />
                    <WorkOrderDetails
                      workOrders={workOrders}
                      claimId={claim.id}
                    />
                    {emptyState()}
                  </Space>
                </BorderedCol>
              ),
            },
            {
              key: '2',
              id: 'tab_claim_details',
              label: msg('tabs.claimdetails'),
              children: (
                <Space direction="vertical" size="large">
                  <ClaimDetails claim={claim} />
                  <InsuredItemDetails insuredItem={claim?.insuredItem} />
                </Space>
              ),
            },
          ]}
        />
      </Modal>
    </>
  );
}

const BorderedCol = styled(Col)`
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 16px;
`;
