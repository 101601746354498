import useFormatMsg from 'hooks/translations/useFormatMsg';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useCustomerData from 'services/hooks/useCustomerData';
import { useApi } from 'services/providers/api/Api';
import { useAppState } from 'services/providers/AppState';
import getCallback from './getCallback';
import modalMsg from './modalMsg';
import appConfig from 'app.config';

export const UserContext = createContext({
  username: undefined,
  isLogged: false,
});

export const useUser = () => useContext(UserContext);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ isLogged: false });
  const { isLoading, setIsLoading } = useAppState();
  const { getUserToken } = useApi();
  const { jwtStore, setLocale, langs } = useAppState();
  const history = useHistory();
  const msg = useFormatMsg('auth.login');
  const queryCustomer = useCustomerData();

  useEffect(() => {
    jwtStore.isValid() ? fetchCustomer() : setIsLoading(false);
  }, []);

  const login = async ({ email, password }) => {
    try {
      const { data } = await getUserToken({
        username: email,
        password,
      });
      jwtStore.setToken(data.access_token);
      fetchCustomer();
    } catch (error) {
      const onOk = getCallback(error, history, email);
      modalMsg({ error, msg, onOk }, 'isruance@mail.com');
    }
  };

  const logout = callback => {
    setUser({ isLogged: false });
    jwtStore.removeToken();
    callback?.();
  };

  const fetchCustomer = async () => {
    !isLoading && setIsLoading(true);
    const { customerId } = jwtStore.getPayload();
    const { customerData, error } = await queryCustomer(customerId);
    if (customerData) {
      if (langs.includes(customerData?.addressCountry?.toLowerCase())) {
        setLocale(customerData?.addressCountry?.toLowerCase());
      }
      setUser({
        ...jwtStore.getPayload(),
        ...customerData,
        isLogged: true,
      });
    } else if (error) {
      jwtStore.removeToken();
      const onOk = getCallback(error, history);
      modalMsg({ error, msg, onOk });
    }
    setIsLoading(false);
  };

  const addUserData = data => {
    setUser({
      ...user,
      ...data,
    });
  };

  return (
    <UserContext.Provider value={{ user, login, logout, addUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
