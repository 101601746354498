import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Tooltip,
  Typography,
} from 'antd';
import { isValidIMEI } from 'helpers/formValidations/validators';
import modalMsg from 'helpers/messages/modalMsg';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import { useApi } from 'services/providers/api/Api';

const { Title } = Typography;
const { Item, useForm } = Form;

export default function IMEIRegistration({ data }) {
  const { policyId } = useParams();
  const msg = useFormatMsg('policies.register_device');
  const [form] = useForm();

  const history = useHistory();

  const { putInsuredItem } = useApi();
  const insuredItem = data?.[0] || {};
  const queryUpdateInsuredItem = putInsuredItem(insuredItem.id, policyId);
  const validateIMEIorSerialNumber = (e, value) => {
    if (value === '') return Promise.reject(msg('mandatory'));
    return insuredItem?.category === 'MOBILE' && !isValidIMEI(value)
      ? Promise.reject(msg('helpimei'))
      : Promise.resolve();
  };

  const requiredLabel = [{ required: true, message: msg('required') }];
  const requiredImei = [
    {
      required: true,
      validator: validateIMEIorSerialNumber,
    },
  ];

  const onSubmitRequest = async payload => {
    queryUpdateInsuredItem({ serialNumber: payload.serialNumber })
      .then(resp => {
        modalMsg({ onOk: () => history.push('/dashboard'), msg });
      })
      .catch(() =>
        modalMsg({
          error: { status: 400 },
          onOk: () => history.push('/dashboard'),
          msg,
        }),
      );
  };

  return (
    <Form
      onFinish={onSubmitRequest}
      form={form}
      initialValues={insuredItem}
      style={{ width: '100%' }}
    >
      <Layout>
        <Title level={2} style={{ textAlign: 'center', marginBottom: '2rem' }}>
          {msg('title')}
        </Title>
        <Row>
          <Title level={5}>{msg('subtitle')}</Title>
        </Row>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Item
              name="manufacturer"
              label={msg('inputs.manufacturer')}
              rules={requiredLabel}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Item>
          </Col>
          <Col xs={24} md={12}>
            <Item
              name="model"
              label={msg('inputs.model')}
              rules={requiredLabel}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Item
              name="serialNumber"
              label={
                <div>
                  <Tooltip title={<small>{msg(`tooltip`)}</small>}>
                    {msg('inputs.serialNumber')}
                    <QuestionCircleOutlined style={{ marginLeft: '8px' }} />
                  </Tooltip>
                </div>
              }
              rules={requiredImei}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Layout.Footer style={{ backgroundColor: 'transparent' }}>
          <Button.Group style={{ width: '100%' }}>
            <Button
              data-cy="deviceRegistrationBack"
              type="primary"
              ghost
              onClick={() => history.push('/dashboard')}
            >
              {msg('actions.cancel')}
            </Button>
            <Button
              data-cy="deviceRegistrationSubmit"
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 'auto' }}
            >
              {msg('actions.submit')}
            </Button>
          </Button.Group>
        </Layout.Footer>
      </Layout>
    </Form>
  );
}
