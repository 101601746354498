import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import ClaimDeclaration from 'components/containers/claims/useCases/declaration/ClaimDeclaration'
import MainContent from 'components/containers/main/MainContent'
import BatteryExchange from 'components/containers/policies/useCases/batteryExchange/BatteryExchange'
import PhoneCaseOrder from 'components/containers/policies/useCases/phoneCaseOrder/PhoneCaseOrder'
import DeviceRegistration from 'components/containers/policies/useCases/deviceRegistration/DeviceRegistration'
import withIdleTimer from 'services/providers/withIdleTimer'

function Dashboard() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${path}/claims/declaration/:policyId`}>
        <ClaimDeclaration />
      </Route>
      <Route path={`${path}/phone_case/:policyId`}>
        <PhoneCaseOrder />
      </Route>
      <Route exact path={`${path}/battery_exchange/:policyId`}>
        <BatteryExchange />
      </Route>
      <Route exact path={`${path}/register-device/:policyId`}>
        <DeviceRegistration />
      </Route>
      <Route path={path}>
        <MainContent />
      </Route>
    </Switch>
  )
}
export default withIdleTimer(Dashboard)
