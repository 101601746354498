import React from 'react';
import { Card, Col, Row, Skeleton, Space } from 'antd';
import { Typography } from 'antd';
import StatusViewer from 'components/containers/claims/repositories/StatusViewer';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import WorkOrdersView from 'components/containers/claims/repositories/WorkOrdersView';
import useClaimWorkorders from 'services/hooks/useClaimWorkorders';
import useClaimVouchers from 'services/hooks/useClaimVouchers';
import VouchersView from 'components/containers/claims/repositories/VouchersView';
import ClaimDetailsModal from 'components/containers/claims/entity/ClaimDetailsModal';

const { Title, Text } = Typography;

export default function ClaimCard({ data }) {
  const msg = useFormatMsg('claims.claim_view');
  const msg2 = useFormatMsg('claims.claim_view.workorders');

  const { workOrders, loadingWO } = useClaimWorkorders(data?.id);
  const { vouchers, loadingVouchers } = useClaimVouchers(data?.id);

  const emptyState = () => {
    if (!workOrders.length && !vouchers.length) {
      return (
        <>
          <Row>
            <Col sm={9}>
              <Text strong>{msg2('number')}</Text>
            </Col>
            <Col sm={6}>
              <Row justify="center">
                <Text strong>{msg2('declaration_date')}</Text>
              </Row>
            </Col>
            <Col sm={9}>
              <Row justify="end">
                <Text strong>{msg2('statustext')}</Text>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={9}>
              <Text>{msg2('no_data')}</Text>
            </Col>
            <Col sm={6}>
              <Row justify="center">
                <Text>{msg2('no_data')}</Text>
              </Row>
            </Col>
            <Col sm={9}>
              <Row justify="end">
                <Text>{msg2('awaiting')}</Text>
              </Row>
            </Col>
          </Row>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <Card style={{ width: '100%' }} actions={[]}>
      <Row justify="space-between" style={{ marginBottom: '1rem' }}>
        <Col md={13}>
          <Space direction="horizontal">
            <Title level={4} style={{ color: 'var(--primary)', margin: '0px' }}>
              {msg('title')} {data.label}
            </Title>
            <ClaimDetailsModal
              claim={data}
              workOrders={workOrders}
              vouchers={vouchers}
            />
          </Space>
        </Col>
        <Col>
          <StatusViewer data={data} />
        </Col>
      </Row>
      <Row style={{ marginBottom: '1rem' }}>
        <Title level={5}>{data.policy?.data.id}</Title>
      </Row>
      <Skeleton loading={loadingWO || loadingVouchers} active>
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <WorkOrdersView workOrders={workOrders} />
          <VouchersView vouchers={vouchers} />
        </Space>
        {emptyState()}
      </Skeleton>
    </Card>
  );
}
