import usePolicyDocuments from 'services/hooks/usePolicyDocuments';
import PolicyCard from 'components/containers/policies/entity/PolicyCard';
import usePolicyCoverages from 'services/hooks/usePolicyCoverages';

export default function PolicyWrapper({ policy, claims }) {
  const { policyDocuments } = usePolicyDocuments(policy);
  const { coverages, exclusionPeriod } = usePolicyCoverages(policy);

  return (
    <PolicyCard
      data={policy}
      claims={claims}
      policyDocuments={policyDocuments}
      coverages={coverages}
      exclusionPeriod={exclusionPeriod}
    />
  );
}
