import { Button, Form, Typography, Modal } from 'antd';
import Spinner from 'components/shared/Spinner';
import Notify from 'helpers/messages/Notify';
import useInitialQuery from 'hooks/fetch/useInitialQuery';
import React, { useState } from 'react';
import {
  useHistory,
  useParams,
  useRouteMatch,
  Switch,
  Route,
} from 'react-router-dom';
import useQuery from 'services/hooks/fetch/useQuery';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';
import styled from 'styled-components';
import NotAvailableOrderPage from './availability/NotAvailableOrderPage';
import SuccessOrderPage from './availability/SuccessOrderPage';
import PhoneCaseForm from './PhoneCaseForm';
import DeliveryAddressModalAccept from './DeliveryAddressModalAccept';
import useInitialFormData from './useInitialFormData';

const { Title } = Typography;

export default function PhoneCaseOrder() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { formData, setFormData } = useInitialFormData();
  const { url } = useRouteMatch();
  const [form] = Form.useForm();
  const msg = useFormatMsg('policies.free_case_request');
  const { policyId } = useParams();
  const history = useHistory();
  const { query, isLoading: checkingStock } = useQuery('postAddOn', {
    action: policyId,
  });
  const onCancel = () => history.push('/dashboard');
  const parseObjectToString = () => {
    const formValues = form.getFieldsValue([
      'streetAddress',
      'postCode',
      'city',
      'country',
    ]);
    formValues.country = msg(
      `countries.${formValues.country ?? formData.country}`,
    );
    return Object.values(formValues).join(', ');
  };

  const onSubmitRequest = async payload => {
    const { error, data } = await query(payload);
    if (!error) {
      setFormData(form.getFieldsValue());
      if (data.data.status === 20201) {
        history.push(`${url}/device/success`);
      } else {
        history.push(`${url}/device/not_available/${data.data.id}`);
      }
    } else {
      Notify.error({ msg, error }, true);
      history.push(`/dashboard`);
    }
  };
  const { data: device, isLoading } = useInitialQuery('getInsuredItems', {
    payload: policyId,
  });
  if (isLoading) return <Spinner />;

  const jsxForm = (
    <Form form={form} initialValues={formData} onFinish={onSubmitRequest}>
      <PhoneCaseForm device={device?.[0]} form={form} />
      <BtnGroup>
        <Button type="primary" ghost onClick={onCancel}>
          {msg('on_cancel.btn')}
        </Button>
        <Button
          type="primary"
          data-cy="acceptPhoneCaseRequest"
          onClick={() =>
            form
              .validateFields()
              .then(() => setIsModalVisible(true))
              .catch(() => {})
          }
          disabled={checkingStock}
        >
          {msg('on_submit.btn')}
        </Button>
      </BtnGroup>
      <DeliveryAddressModalAccept
        msg={msg}
        handleAccept={() => {
          setIsModalVisible(false);
          form.submit();
        }}
        isModalVisible={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
        address={parseObjectToString()}
      />
    </Form>
  );

  return (
    <React.Fragment>
      <Title level={3} style={{ textAlign: 'center' }}>
        {msg('page_title')}
      </Title>
      <Switch>
        <Route exact path={`${url}/device`}>
          {jsxForm}
        </Route>
        <Route path={`${url}/device/success`}>
          <SuccessOrderPage data={formData} />
        </Route>
        <Route path={`${url}/device/not_available/:addOnId`}>
          <NotAvailableOrderPage policyId={policyId} />
        </Route>
      </Switch>
    </React.Fragment>
  );
}
const BtnGroup = styled('div')`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`;
