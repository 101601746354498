import React, { Fragment, useEffect, useState } from 'react';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { Collapse, Empty, Typography } from 'antd';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import appConfig from 'app.config';
import { useAppState } from 'services/providers/AppState';

const { Panel } = Collapse;
const { Paragraph, Title } = Typography;

export default function Faq() {
  const { customerPortalConfig, locale } = useAppState();
  const msg = useFormatMsg('app.faq.PARTNER');
  const [activeKey, setActiveKey] = useState();
  const { id } = useParams();
  const faq = customerPortalConfig?.faq?.[locale?.toUpperCase()];

  useEffect(() => {
    if (id) {
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
      setActiveKey(id);
    }
  }, [id]);

  const changeActiveKey = key => {
    setActiveKey(key);
  };

  if (!customerPortalConfig?.faq?.[locale.toUpperCase()]) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>{msg('emptyState')}</span>}
      />
    );
  }
  return (
    <>
      <Title className="text-center">{msg('title')}</Title>
      <StyledCollapse
        accordion
        activeKey={activeKey}
        onChange={changeActiveKey}
      >
        {faq.map(({ section, key: keyS, faqs }) => (
          <Fragment key={`section-${keyS}`}>
            <Panel
              className="title-panel"
              collapsible="disabled"
              showArrow={false}
              header={
                <Title level={3} className="text-center">
                  {section}
                </Title>
              }
            />
            {faqs?.map(({ key: keyF, answer, question }) => (
              <Panel
                id={`question-${keyS}-${keyF}`}
                header={question}
                key={`question-${keyF}`}
              >
                <Paragraph>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: answer,
                    }}
                  />
                </Paragraph>
              </Panel>
            ))}
          </Fragment>
        ))}
      </StyledCollapse>
    </>
  );
}

const StyledCollapse = styled(Collapse)`
  margin-bottom: 1.5rem;

  li {
    margin: 5px 0;
  }

  table {
    width: 100%;
    margin-top: 10px;

    thead {
      text-align: center;
      background-color: var(--primary);
      color: white;
    }

    td {
      padding: 5px;
    }

    tr {
      border-bottom: solid 1px var(--gray-4);
    }
  }

  .title-panel {
    background-color: white;
    cursor: auto;

    h3 {
      margin: 0.5rem 0;
    }

    .ant-collapse-header {
      cursor: auto;
    }

    .ant-collapse-content {
      display: none;
    }
  }
`;
