import React from 'react';
import { Modal, Typography, Button, Row } from 'antd';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { useClaimDeclaration } from 'services/providers/ClaimDeclaration';

export default function ModalConsent({
  handleCancel,
  handleAccept,
  open,
  claimType,
}) {
  const { setInsuredItems, ...claimContext } = useClaimDeclaration();

  const msg = useFormatMsg();

  return (
    <Modal
      open={open}
      footer={null}
      header={null}
      closable={false}
      onCancel={handleCancel}
    >
      <Typography style={{ fontSize: '16px', lineHeight: '34px' }}>
        {msg(`claims.declaration.details.modal_consent.important`)}
      </Typography>
      <Typography>
        {msg(`claims.declaration.details.modal_consent.${claimType}`)}
      </Typography>

      <Row
        justify="end"
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          marginTop: '2px',
        }}
      >
        <Button
          // wrapperCol={{ span: 24 }}
          onClick={handleAccept}
          type="primary"
          style={{ marginTop: '2px' }}
          id="button_modal_accept_consent"
        >
          {msg('claims.declaration.details.modal_consent.button')}
        </Button>
      </Row>
    </Modal>
  );
}
