import React from 'react';
import { Row } from 'antd';
import { useAppState } from 'services/providers/AppState';

export default function Imprint() {
  const { customerPortalConfig, locale } = useAppState();

  const imprint = customerPortalConfig?.imprint?.[locale?.toUpperCase()];
  return (
    <Row>
      <div
        style={{ width: '100%' }}
        dangerouslySetInnerHTML={{
          __html: imprint,
        }}
      />
    </Row>
  );
}
