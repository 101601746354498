import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Col, Row, Modal } from 'antd';
import downloadFile from 'helpers/downloadFile';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import useQuery from 'services/hooks/fetch/useQuery';
import modalMsg from 'helpers/messages/modalMsg';
import { claimsStatusOnlyOneClaim } from 'data/claims';
import PolicyInformation from './PolicyInformation';
import { DownloadOutlined } from '@ant-design/icons';
import theftCover from 'public/img/product/coverage-theft.svg';
import damageCover from 'public/img/product/coverage-damage.svg';
import { useAppState } from 'services/providers/AppState';
import { dateAdd, dateToDayJs } from 'helpers/dates/dates';

const transformKeys = {
  CONTRACT_START_DATE: 'startDate',
};

const dateTransformKeys = {
  YEAR: 'y',
  YEARS: 'y',
  MONTH: 'm',
  MONTHS: 'm',
  WEEK: 'w',
  WEEKS: 'w',
  DAY: '	d',
  DAYS: '	d',
};

export default function PolicyCard({
  data,
  claims,
  policyDocuments,
  coverages,
  exclusionPeriod,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { customerPortalConfig } = useAppState();

  const { query, isLoading: isLoadingBtn } = useQuery('downloadPolicyPdf', {
    action: data.id,
  });

  const msg = useFormatMsg('policies.policy_card');
  const history = useHistory();

  const downloadPdf = async () => {
    const { error, data: pdf } = await query();
    !error && downloadFile(pdf.data, data.id, 'pdf');
  };

  const onClickClaimDeclaration = () => {
    if (checkClaimsStatus()) {
      modalMsg({ onOk: () => {}, msg });
    } else {
      history.push(`/dashboard/claims/declaration/${data.id}`);
    }
  };

  const checkClaimsStatus = () => {
    return (
      claims &&
      claims.filter(c => claimsStatusOnlyOneClaim.includes(c.status))?.length >
        0
    );
  };

  //CALCULATE EXCLUSION PERIOD
  const checkWEandEP = () => {
    if (exclusionPeriod) {
      const today = dateToDayJs();
      const dateReferenceExclusion =
        data?.[
          transformKeys[exclusionPeriod.coverage_exclusion_reference_date]
        ];

      const dateFrom = dateAdd(
        dateReferenceExclusion,
        exclusionPeriod.coverage_exclusion_period_from,
        dateTransformKeys[exclusionPeriod.coverage_exclusion_period_unit],
      );
      const dateTo = dateAdd(
        dateReferenceExclusion,
        exclusionPeriod.coverage_exclusion_period_to,
        dateTransformKeys[exclusionPeriod.coverage_exclusion_period_unit],
      );
      if (today > dateFrom && today < dateTo) {
        return false;
      }
    }
    return true;
  };

  return (
    <Card className="policy-card" style={{ width: '100%' }} actions={[]}>
      <Row>
        <Col md={7}>
          <img
            src={coverages.includes('THEFT') ? theftCover : damageCover}
            width="100%"
          ></img>
        </Col>

        <Col md={17} style={{ paddingLeft: '8px' }}>
          <PolicyInformation data={data} />
        </Col>
      </Row>

      <Button.Group
        style={{ marginTop: '1rem', width: '100%' }}
        className="cap-all"
      >
        <Row style={{ width: '100%' }}>
          <Col>
            {(!coverages.includes('WARRANTY_EXTENSION') ||
              (coverages.includes('WARRANTY_EXTENSION') && checkWEandEP())) && (
              <Button
                onClick={onClickClaimDeclaration}
                type="primary"
                key="declare_claim"
                data-cy="btn_declare_claim"
                style={{ marginRight: '1rem', textTransform: 'none' }}
                id="button_declare_claim"
              >
                {msg('actions.declare')}
              </Button>
            )}
          </Col>
          {!customerPortalConfig?.configuration?.policyCard
            ?.hide_download_policy_pdf && (
            <Col>
              <Button
                id="download_policy_pdf"
                loading={isLoadingBtn}
                type="primary"
                ghost
                key="view_pdf"
                data-cy="btn_download_policy_pdf"
                onClick={downloadPdf}
                style={{ marginRight: '1rem', textTransform: 'none' }}
                icon={<DownloadOutlined />}
              >
                {msg('actions.viewPdf')}
              </Button>
            </Col>
          )}
          {!customerPortalConfig?.configuration?.policyCard
            ?.hide_download_customer_documents && (
            <Col>
              <Button
                id="download_policy_documents"
                disabled={!policyDocuments.length}
                type="primary"
                ghost
                key="policy_documents"
                data-cy="btn_download_policy_documents"
                onClick={() => setIsModalVisible(true)}
                style={{ textTransform: 'none' }}
                icon={<DownloadOutlined />}
              >
                {msg('actions.downloadDocuments')}
              </Button>
            </Col>
          )}
        </Row>
      </Button.Group>
      <Modal
        open={isModalVisible}
        title={msg('actions.downloadDocuments')}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        {policyDocuments.map(pd => (
          <p key={`pd_${pd.type}`}>
            <a href={pd.url} target="_blank">
              {msg(`types.${pd.type}`)}
            </a>
          </p>
        ))}
      </Modal>
    </Card>
  );
}
