import { Empty, List } from 'antd';
import useInitialQuery from 'hooks/fetch/useInitialQuery';
import React from 'react';
import { claimListMapper } from 'services/providers/api/mappers';
import useNotificationError from 'services/hooks/useNotificationError';
import { useUser } from 'services/providers/user/User';
import ClaimWrapper from 'components/containers/claims/entity/ClaimWrapper';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import Spinner from 'components/shared/Spinner';

export default function ClaimList() {
  const { user } = useUser();
  const msg = useFormatMsg('app.main_content');

  const { data, isLoading, error } = useInitialQuery('getClaimsByCustomerId', {
    map: claimListMapper,
    payload: user.customerId,
  });
  useNotificationError(error);

  if (isLoading) return <Spinner />;
  if (error) return null;
  const emptyText = <Empty description={msg('without_claims')} />;

  const getJsxEl = item => (
    <List.Item key={item.id}>
      <ClaimWrapper data={item} />
    </List.Item>
  );
  return (
    <List
      locale={{ emptyText }}
      dataSource={data?.data || [{}]}
      renderItem={getJsxEl}
    />
  );
}
