const getDeviceValues = (device) => {
  return {
    ...device,
    id: device.id,
    device: 0,
    manufacturer: device.manufacturer || '',
    color: device.color || '',
    serialNumber: device.serialNumber || '',
    model: device.model || '',
    capacity: device.capacity || 32,
  };
};
export default getDeviceValues;
