import React from 'react';
import { Row, Col } from 'antd';
import { Typography } from 'antd';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { formateDateDDMMYYYYandAdd } from 'helpers/dates/formatDate';
const { Text } = Typography;

export default function VouchersView({ vouchers }) {
  if (!vouchers.length) return null;
  const msg = useFormatMsg('claims.claim_view.vouchers');

  const voucherView = voucher => (
    <Row justify="space-between" key={voucher.id}>
      <Col sm={8}>
        <Text>{voucher.id}</Text>
      </Col>
      <Col sm={8}>
        <Row justify="center">
          <Text>
            {formateDateDDMMYYYYandAdd(voucher.issuanceDate, {
              number: '6',
              unit: 'M',
            })}
          </Text>
        </Row>
      </Col>
      <Col sm={8}>
        <Row justify="end">
          <Text>{voucher.amount}</Text>
        </Row>
      </Col>
    </Row>
  );

  return (
    <>
      <Row>
        <Col sm={8}>
          <Text strong>{msg('number')}</Text>
        </Col>
        <Col sm={8}>
          <Row justify="center">
            <Text strong>{msg('expiration_date')}</Text>
          </Row>
        </Col>
        <Col sm={8}>
          <Row justify="end">
            <Text strong>{msg('amount')}</Text>
          </Row>
        </Col>
      </Row>
      {vouchers.map(voucher => voucherView(voucher))}
    </>
  );
}
