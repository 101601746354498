import { Col, Typography, Row, Divider } from 'antd';
import { listOfDetails } from 'data/claimDetails';
import {
  processClaimContext,
  processClaim,
} from 'helpers/statuses/claim/claimContext';
import useFormatMsg from 'services/hooks/translations/useFormatMsg';

const needTranslation = ['responsible'];

const { Text, Title } = Typography;
export default function ClaimDetails({ claim }) {
  const msg = useFormatMsg('claims.claim_view.claim_details');
  const claimContext = {
    ...processClaimContext(claim.claimContext),
    ...processClaim(claim),
  };

  function TextOrList({ values, field }) {
    return Array.isArray(values) ? (
      <ul style={{ marginLeft: '32px' }}>
        {values.map(v => (
          <li key={v}>{msg(`types_damages.${v}`)}</li>
        ))}
      </ul>
    ) : (
      <Text>
        {needTranslation.includes(field) ? msg(`${field}.${values}`) : values}
      </Text>
    );
  }

  function Details({ field }) {
    if (!claimContext[field]) {
      return null;
    }
    return (
      <Row>
        <Text strong style={{ marginRight: '4px' }}>
          {msg(`question.${field}`)}
        </Text>
        <TextOrList values={claimContext[field]} field={field}></TextOrList>
      </Row>
    );
  }

  return (
    <Row>
      <Col>
        <Title level={5}>{msg('title')}</Title>
        <Divider style={{ margin: '8px 0px 12px 0px' }} />
        {listOfDetails[claim.claimType].map(d => (
          <Details field={d} key={d} />
        ))}
      </Col>
    </Row>
  );
}
