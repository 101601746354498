import { Button, Col, Row, Typography, Form } from 'antd';
import React from 'react';

const { Title, Paragraph } = Typography;

export default function AuthLayout({ title, caption, btn, actions, children }) {
  return (
    <Row align="middle" justify="center" style={{ marginTop: '2rem' }}>
      <Col xs={20} sm={12} md={10} lg={8}>
        <Title level={2} style={{ textAlign: 'center' }}>
          {title}
        </Title>
        <Paragraph type="secondary" style={{ textAlign: 'center' }}>
          {caption}
        </Paragraph>
        <Row style={{ height: '24px' }} />
        {children}
        <Row style={{ height: '24px' }} />
        <Col span={24}>
          <Row justify="center">
            <Button
              id={btn.id || 'buttonLogin'}
              type="primary"
              onClick={btn.onClick}
              disabled={btn.disabled}
              data-cy="submit"
              loading={btn.loading}
            >
              {btn.label}
            </Button>
          </Row>
        </Col>
        <Row style={{ height: '18px' }} />
        {actions}
      </Col>
    </Row>
  );
}
